.znd-cutter-tile {
    display: block;
    position: relative;
    border-radius: 0.375rem;
    background-color: $white;
    padding: 1.25rem 1.25rem 0 1.25rem;
    height: 100%;

    @include media-breakpoint-up(sm) {
        padding: 1.875rem;
    }

    &__title {
        margin-bottom: 1.25rem;
        color: $secondary;
    }

    &__image {
        margin-bottom: 1.25rem;
        padding: 0 1rem;
        width: 100%;
        max-height: 18.75rem;
        object-fit: contain;
    }

    &__button {
        z-index: 2;
        margin: 0 0.25rem;

        @include media-breakpoint-only(xs) {
            margin-bottom: 1.75rem;
        }
    }

    &__link-stretched {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}
