.znd-table-tile {
    display: inline-block;
    margin: 1.25rem;
    border-radius: 0.375rem;
    background-color: $white;
    padding: 1.25rem 0;
    width: calc(100% - 2.5rem);
    min-width: min-content;

    @include media-breakpoint-up(sm) {
        margin: 2.5rem;
        padding: 1.875rem 0;
        width: calc(100% - 5rem);
    }

    &__header {
        padding: 0 1.875rem 1.25rem;

        @include media-breakpoint-up(sm) {
            padding: 0.625rem 2.5rem 1.875rem;
        }

        &-title {
            color: $secondary;
        }
    }

    &__icon-caret,
    & &__icon-caret.icon-caret-down {
        display: block;
        margin-left: auto;
        color: $primary;
    }

    &__info {
        display: flex;
        color: $primary;

        &--dark {
            color: $dark;
        }

        .znd-tooltip__label {
            width: max-content;
            text-align: left;
        }
    }

    &__icon-info {
        display: inline-block;
        border-radius: 50%;
        background-color: $primary;
        width: 1.375rem;
        height: 1.375rem;
        color: $white;
        text-align: center;

        &--dark {
            display: flex;
            color: $dark;
            text-align: center;

            &-icon {
                font-size: 1.25rem;
            }
        }
    }

    &__name {
        pointer-events: none;

        &--with-icon {
            margin-right: 0.25rem;
        }
    }

    &--inner-scroll &__scroll-wrapper {
        width: calc(100vw - 2.5rem);
        overflow-x: auto;
        overflow-y: hidden;

        @include media-breakpoint-up(sm) {
            width: calc(100vw - 5rem);
        }

        @include media-breakpoint-up(xl) {
            width: calc(100vw - 11.5rem);
        }
    }

    &--inner-scroll &__header {
        position: sticky;
        top: 3.875rem;

        z-index: $zindex-sticky + 1;
        margin-top: -1.875rem;

        border-radius: 0.375rem;

        background-color: #ffffff;
        padding-top: 1.675rem;

        @include navbarOnDesktop {
            top: 0;
        }
    }

    &__footer {
        border-top: 1px solid $gray_200;
        height: 4.5rem;

        &__content {
            display: flex;
            justify-content: space-between;
            margin: auto;
            padding-top: 20px;
            width: 97%;
        }

        &__item {
            display: flex;
            flex-flow: column;
            justify-content: center;
            color: $secondary;
            font-weight: $font-weight-bold;

            div,
            button {
                margin: 0;
            }
        }

        &__icon {
            padding-top: 0.15rem;
            color: $secondary;
            font-size: 1.25rem;
        }
    }
}
