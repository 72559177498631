.znd-main-navigation {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    box-shadow: 0 1px 0 $gray-200;

    background-color: $white;

    width: 100vw;

    @include navbarOnDesktop {
        right: auto;
        bottom: 0;

        box-shadow: 1px 0 0 $gray-200;

        width: $navbarWidth;
    }

    &__header {
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;
        z-index: 2;

        background-color: $white;
        padding-right: 1.375rem;
        padding-left: 1.375rem;

        height: $mobileNavbarHeight;

        @include navbarOnDesktop {
            display: none;
            justify-content: center;
            padding-right: 0;

            padding-left: 0;

            height: auto;
        }
    }

    &__logo {
        height: 2.625rem;

        @include navbarOnDesktop {
            visibility: hidden;
        }
    }

    &__buttons {
        margin: 0 0 0 auto;
    }

    &__hamburger {
        margin: 0 0 0 1rem;

        @media (min-width: 375px) {
            margin-left: 1.5rem;
        }
    }

    &__search-button {
        margin: 0 0 0 1rem;

        @media (min-width: 375px) {
            margin-left: 1.5rem;
        }

        &.transition-fade-exit-done {
            visibility: hidden;
            opacity: 0;
        }
    }

    &__help-button {
        z-index: 1050;
        margin: 0 0 0 1rem;
        border-radius: 50%;
        background-color: $white;

        @media (min-width: 375px) {
            margin-left: 1.5rem;
        }

        &.transition-fade-exit-done {
            visibility: hidden;
            opacity: 0;
        }
    }

    &__notification-button {
        z-index: 1050;
        margin: 0 0 0 1rem;
        border-radius: 50%;
        background-color: $white;

        @media (min-width: 375px) {
            margin-left: 1.5rem;
        }

        &.transition-fade-exit-done {
            visibility: hidden;
            opacity: 0;
        }

        &__icon-disabled {
            color: $gray-500;
        }

        &__badge {
            display: flex;
            position: absolute;
            top: -0.25rem;
            right: -0.125rem;
            justify-content: center;
            align-items: center;
            z-index: 110;
            box-shadow: $elevation-shadow-200;
            border-radius: 50%;
            background-color: $gray-500;
            min-width: 1.125rem;
            color: $white;
            font-weight: $font-weight-bold;
            font-size: 0.875rem;
            line-height: 1.125rem;

            &__content {
                transform: translateY(1px);
            }
        }
    }

    &__hamburger,
    &__search-button,
    &__notification-button,
    &__help-button {
        outline: none;
        border: none;
        padding: 0;
        font-size: 1.125rem;
        line-height: 0;

        &,
        &:hover,
        &:focus,
        &:active {
            color: $primary;
        }

        @include navbarOnDesktop {
            display: none;
        }
    }

    &__backdrop-nav {
        display: none;
        visibility: hidden;
        pointer-events: none;

        @include media-breakpoint-up(md) {
            display: block;
            position: absolute;
            top: 0;
            right: 18.75rem;
            left: -1.875rem;
            z-index: 3;
            background-color: rgba($black, 0.4);
            height: 100vh;
        }

        @include navbarOnDesktop {
            display: none;
        }
    }

    &__backdrop-notification {
        display: none;
        visibility: hidden;
        pointer-events: none;

        @include media-breakpoint-up(sm) {
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            z-index: $zindex-modal-backdrop;
            background-color: rgba($black, 0.4);
            width: 100%;
            height: 100vh;
        }
    }

    &__backdrop-search {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 3;
        background-color: rgba($black, 0.4);
        height: 100vh;

        &--transition {
            &-enter {
                display: block;
                opacity: 0;
            }
            &-enter-active {
                opacity: 1;
                transition: opacity 0.2s ease-out;
            }
            &-enter-done {
                display: block;
            }
            &-exit {
                display: block;
                opacity: 1;
            }
            &-exit-active {
                opacity: 0;
                transition: opacity 0.2s ease-out;
            }
        }

        @include navbarOnDesktop {
            display: none;
        }
    }

    &__content {
        display: flex;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        flex-direction: column;
        z-index: 1;

        height: calc(100vh - #{$mobileNavbarHeight});
        overflow: auto;
        @include smoothScroll;
        visibility: hidden;

        background-color: $white;

        @include media-breakpoint-up(md) {
            left: auto;
            width: 18.75rem;
        }

        @include navbarOnDesktop {
            position: relative;
            top: auto;
            right: auto;
            left: auto;
            visibility: visible;
            width: auto;
            overflow: visible;
        }

        &--transition {
            // Start of enter animation
            &-enter {
                transform: translateY(-1.875rem);
                opacity: 0;
                @include media-breakpoint-up(md) {
                    transform: translateX(1.875rem);
                }
            }

            // Start of exit animation
            &-exit {
                transform: translate(0, 0);
                opacity: 1;
            }

            // Target of enter animation
            &-enter-active {
                transform: translate(0, 0);
                visibility: visible;
                opacity: 1;
            }

            // Target of exit animation
            &-exit-active {
                transform: translateY(-1.875rem);
                visibility: visible;
                opacity: 0;
                @include media-breakpoint-up(md) {
                    transform: translateX(1.875rem);
                }
            }

            &-enter-active,
            &-exit-active {
                transition:
                    opacity 0.2s ease-out,
                    transform 0.2s ease-out;
            }

            &-enter-done {
                visibility: visible;
                pointer-events: auto;
            }

            &-exit-done {
                visibility: hidden;
            }
        }
    }

    // Overwrite visibility to fix issue with icons of main navigation. (see #608)
    &__content#{&}__content--transition-exit-done {
        @include media-breakpoint-up(xl) {
            visibility: visible;
        }
    }

    &__nav {
        padding-top: 0.375rem;
    }

    &__user {
        margin-top: auto;

        // Spacing because of the stupid iOS viewport behaviour.
        margin-bottom: 3.75rem;
        padding-right: 1.25rem;
        padding-bottom: 0.875rem;
        padding-left: 1.25rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        @include navbarOnDesktop {
            display: none;
        }
    }

    & &__loading {
        right: 3rem;
    }

    &__search {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        flex-direction: row;
        align-items: stretch;
        z-index: 5;

        $searchComponent: &;

        &#{&} {
            @include navbarOnDesktop {
                display: none;
            }
        }

        &-back {
            display: flex;
            position: relative;
            flex: 0 0 $mobileNavbarHeight;
            justify-content: center;
            align-items: center;
            margin: 0;
            border-radius: 0;
            background-color: $white;
            padding: 0;

            width: $mobileNavbarHeight;

            font-size: 1.125rem;

            &,
            &:hover,
            &:focus,
            &:active {
                color: $primary;
            }

            &::after {
                display: block;
                position: absolute;
                top: 1rem;
                right: 0;
                bottom: 1rem;
                border-right: 1px solid $gray-200;
                content: '';
            }
        }

        &-input-wrapper {
            display: flex;
            position: relative;
            flex: 1 1 100%;
            flex-direction: row;
            align-items: stretch;
            background-color: $white;
        }

        &-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
        }

        &-clear,
        &-icon {
            display: flex;
            flex: 0 0 $mobileNavbarHeight;
            justify-content: center;
            align-items: center;
            margin: 0;
            border-radius: 0;
            background-color: $white;
            padding: 0;

            width: $mobileNavbarHeight;

            font-size: 1.125rem;

            &,
            &:hover,
            &:focus,
            &:active {
                color: $secondary;
            }
        }

        &-input {
            flex: 1 1 100%;
            font-family: $font-family-base;
            @include t-body;

            border: none;
            background: none;
            padding-left: $mobileNavbarHeight;
            color: $gray-500;

            &,
            &:hover,
            &:focus,
            &:active {
                outline: none;
            }

            &::placeholder {
                color: $gray-400;
            }
        }

        &--transition-enter {
            display: flex;

            #{$searchComponent}-input-wrapper {
                transform: translateX(1.875rem);
                opacity: 0;
            }
            #{$searchComponent}-back {
                transform: translateX(-1.875rem);
                opacity: 0;

                &::after {
                    opacity: 0;
                }
            }
        }
        &--transition-enter-active {
            #{$searchComponent}-back,
            #{$searchComponent}-input-wrapper {
                transform: translateX(0);
                opacity: 1;
                transition:
                    opacity 0.2s ease-out,
                    transform 0.2s ease-out;

                &::after {
                    opacity: 1;
                    transition: opacity 0.2s ease-out;
                    transition-delay: 0.2s;
                }
            }
        }
        &--transition-enter-done {
            display: flex;
        }
        &--transition-exit {
            display: flex;

            #{$searchComponent}-back,
            #{$searchComponent}-input-wrapper {
                transform: translateX(0);
                opacity: 1;

                &::after {
                    opacity: 1;
                }
            }
        }
        &--transition-exit-active {
            #{$searchComponent}-input-wrapper {
                transform: translateX(1.875rem);
                opacity: 0;
                transition:
                    opacity 0.2s ease-out,
                    transform 0.2s ease-out;
            }
            #{$searchComponent}-back {
                transform: translateX(-1.875rem);
                opacity: 0;
                transition:
                    opacity 0.2s ease-out,
                    transform 0.2s ease-out;
            }
        }
    }
}
