.znd-anchored-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: $zindex-dropdown;

    background-color: $white;
    min-width: 15rem;
    @include elevate(200);

    @supports (filter: drop-shadow($elevation-shadow-200)) {
        filter: drop-shadow($elevation-shadow-200);
        box-shadow: none;
    }

    transform: translateY(0.5rem);
    visibility: hidden;
    opacity: 0;

    transition:
        visibility 0.2s step-end,
        transform 0.2s ease-out,
        opacity 0.2s ease-out;

    border-radius: $border-radius;

    &::before {
        display: block;

        position: absolute;
        right: (3.125rem / 2);
        bottom: 100%;

        transform: translateX(50%) translateY(2px);
        border: 0.625rem solid transparent;
        border-bottom: 0.75rem solid $white;

        width: 0;
        content: '';
    }

    &__visible {
        transform: translateY(1rem);
        visibility: visible;
        opacity: 1;

        transition:
            visibility 0.2s step-start,
            transform 0.2s ease-out,
            opacity 0.2s ease-out;
    }
}
