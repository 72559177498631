@use 'sass:color';

.znd-select-box-button {
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    border-radius: 1.25rem;

    background-color: $white;

    padding: 0.625rem 1rem;

    color: $secondary;
    font-weight: $font-weight-bold;

    &:hover {
        background-color: color.mix($gray-100, $white, 75%);
    }

    &__icon {
        margin-top: -0.1rem;
        margin-right: 0.375rem;
        margin-bottom: -0.1rem;
        font-size: 1.2rem;
    }
}
