.znd-icon-info {
    flex: 0 0 1.375rem;
    margin-right: 0.75rem;
    border-radius: 50%;
    background-color: $gray-400;
    height: 1.375rem;
    color: $white;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
}
