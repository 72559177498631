@mixin custom-button-variant(
    $color,
    $background,
    $hover-background,
    $hover-color,
    $active-background,
    $active-color,
    $disabled-background,
    $disabled-color,
    $border-color: $background,
    $hover-border-color: $hover-background,
    $active-border-color: $active-background,
    $disabled-border-color: $disabled-background
) {
    color: $color;
    @include gradient-bg($background);
    border-color: $border-color;
    @include box-shadow($btn-box-shadow);

    @include hover() {
        color: $hover-color;
        @include gradient-bg($hover-background);
        border-color: $hover-border-color;
    }

    &:focus,
    &.focus {
        color: $active-color;
        @include gradient-bg($active-background);
        outline: 0;
        box-shadow: none;
        border-color: $active-border-color;
    }

    // Disabled is first, so active can properly restyle.
    &.disabled,
    &:disabled {
        border-color: $disabled-border-color;
        background-color: $disabled-background;
        color: $disabled-color;
        // Remove CSS gradients, if they're enabled.
        @if $enable-gradients {
            background-image: none;
        }
    }

    &:not(:disabled):active,
    &:not(:disabled).active,
    .show > &.dropdown-toggle {
        background-color: $active-background;
        color: $active-color;
        @if $enable-gradients {
            background-image: none; // Remove the gradient for the pressed/active state.
        }
        border-color: $active-border-color;
    }
}
