.znd-region-modal {
    &__select {
        max-width: 100%;
    }

    &__button {
        justify-content: center;
        margin-bottom: 1.5rem;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            width: auto;
        }
    }
}
