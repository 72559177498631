.znd-product-suggestion-dropdown {
    -webkit-overflow-scrolling: touch;
    position: absolute;
    top: 100%;
    left: -3.375rem;

    z-index: $zindex-dropdown;

    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: $elevation-shadow-200;
    border-radius: 0.375rem;

    background-color: $white;

    width: calc(100vw - 1rem);
    max-height: 15.75rem;

    overflow-y: scroll;

    @include media-breakpoint-up(sm) {
        left: 0;
        width: calc(100% - 3.875rem);
    }

    @include media-breakpoint-up(xl) {
        width: 100%;
    }

    &.--full-width {
        left: 0;
        width: 100%;
    }

    &__item {
        display: block;
        cursor: pointer;
        margin: 0.5rem;
        padding: 0.375rem 0.75rem;

        &:hover {
            border-radius: 0.375rem;
            background-color: $gray-100;
        }

        &__product-number {
            padding-bottom: 0.25rem;
            color: $gray-400;
        }

        &__product-name {
            color: $gray-500;
        }
    }

    &__no-results {
        margin: 0.5rem;
        padding: 0.375rem 0.75rem;
        color: $gray-500;
    }
}
