.znd-product-list {
    @include media-breakpoint-between(sm, md) {
        flex-wrap: wrap;
    }

    &__headline-price {
        margin-bottom: 0.5rem;
        padding-right: 9.125rem;

        color: $gray-400;

        text-align: right;
    }

    &--cards {
        margin-left: -0.625rem;
        width: calc(100% + 1.25rem);
    }
}
