.znd-shop-confirmation-page {
    &__content {
        display: block;
        position: relative;
        border-radius: $border-radius;

        background-color: $white;

        padding-bottom: 6rem;
        overflow: hidden;
    }

    &__title {
        @include t-headline-3;

        margin-top: 1.25rem;
        margin-right: auto;
        margin-left: auto;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        width: 100%;

        max-width: 43rem;
        color: $gray-500;
        text-align: center;

        @include media-breakpoint-up(md) {
            margin-top: 3.75rem;
            width: 80%;
        }
    }

    &__description {
        @include t-subhead-2;

        margin-top: 1.25rem;
        margin-right: auto;
        margin-left: auto;
        padding-right: 2.5rem;
        padding-left: 2.5rem;
        width: 100%;

        max-width: 43rem;
        color: $gray-500;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 80%;
        }
    }

    &__back {
        display: block;
        margin-top: 2.5rem;
        margin-right: auto;
        margin-left: auto;
        padding-right: 2.5rem;
        padding-left: 2.5rem;
        width: 100%;

        @include media-breakpoint-up(md) {
            padding: 0;
            width: 12.5rem;
        }
    }

    &__title,
    &__description,
    &__back {
        position: relative;
        z-index: 1;
    }

    &__animation {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        overflow: hidden;

        &-city {
            flex: 0 0 1296px;

            opacity: 0.4;
            animation-duration: 60s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;

            animation-name: city;

            background-image: url(../../img/city.svg);
            background-position: left bottom;
            background-repeat: repeat-x;
            width: 1296px;
            height: 192px;
        }

        &-car {
            position: absolute;
            right: 100%;
            bottom: 0;
            animation-duration: 55s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;

            animation-name: carSpeed;
        }
    }
}

@keyframes carSpeed {
    0% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
    49% {
        transform: translateX(37.5vw);
        animation-timing-function: linear;
    }
    50% {
        transform: translateX(37.5vw);
        animation-timing-function: ease-in;
    }
    95%,
    100% {
        transform: translateX(calc(100vw + 100%));
        animation-timing-function: ease-in;
    }
}

@keyframes city {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}
