.znd-dashboard-news {
    margin-top: 2.5rem;

    &__subtitle {
        color: $gray-500;
    }

    &__list-container {
        position: relative;
        margin: 0.625rem 0;
    }

    &__list {
        flex-wrap: nowrap;
        overflow: hidden;
    }

    &__slide-prev {
        left: -1.5rem;
    }

    &__slide-next {
        right: -1.5rem;
    }

    & &__slide-prev,
    & &__slide-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    &-item {
        border-radius: $border-radius;
        background-color: $white;
        padding: 1.25rem;

        &__title,
        &__text {
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        &__title {
            margin-bottom: 1rem;
            color: $secondary;
            font-weight: $font-weight-bold;
        }

        &__text {
            margin: 0.25rem 0 0.5rem;
            color: $gray-500;
        }

        &__link,
        &__link:hover,
        &__link:visited {
            color: $primary;
            font-weight: $font-weight-bold;
            text-decoration: none;
        }

        &__image img {
            border-radius: $border-radius;
            width: 100%;
        }
    }
}
