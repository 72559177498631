.znd-info-box {
    margin: 1.5rem auto;
    border-radius: 0.375rem;

    background-color: $secondary;
    padding: 1.25rem 1.5rem;
    max-width: 40rem;
    color: $white;

    &__title {
        padding-bottom: 0.5rem;
    }

    &__text {
        padding-bottom: 1.25rem;
    }

    &__link {
        display: flex;
        align-items: center;
        width: fit-content;
        color: $white;

        font-weight: $font-weight-bold;

        &:hover {
            color: $white;
        }

        i {
            padding-left: 0.5rem;
            font-size: 1rem;
        }
    }
}
