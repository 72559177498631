.znd-user-notice {
    position: absolute;
    top: 4rem;
    right: 1rem;
    z-index: $zindex-fixed;
    width: calc(100vw - 2rem);
    max-width: 21.5rem;
    min-height: 9.625rem;

    .znd-user-notice-content {
        margin-top: 0.625rem;
        border-radius: 6px;
        background-color: $white;

        @include elevate(200);

        @supports (filter: drop-shadow($elevation-shadow-200)) {
            filter: drop-shadow($elevation-shadow-200);
            box-shadow: none;
        }
    }

    .znd-user-notice__body {
        margin-bottom: 1.5rem;
        min-height: 3rem;

        .znd-user-notice__message {
            font-weight: $font-weight-bold;
        }
    }

    .znd-user-notice__close {
        color: $primary;
    }

    .znd-user-notice-triangle {
        position: absolute;
        top: -1px;
        right: 1.25rem;
        -webkit-transform: rotate(360deg);
        border-width: 0 9px 11px 9px;
        border-style: solid;
        border-color: transparent transparent $white transparent;
        width: 0;
        height: 0;
    }

    @include media-breakpoint-up(sm) {
        max-width: 22.75rem;
    }
    @include media-breakpoint-up(xl) {
        top: 4.5rem;
    }
}
