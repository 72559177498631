.znd-dashboard-disrupter {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: stretch;
    padding: 2.5rem 0;
    width: 100%;

    @include media-breakpoint-up(hg) {
        flex-direction: row;
    }

    &--loading {
        min-height: 7rem;
    }
}
