@mixin t-display {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 3.25rem;
    line-height: 3.5rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-display,
.t-display {
    @include t-display();
}
@mixin t-headline-1 {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 2rem;
    line-height: 2.25rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-headline-1,
.t-headline-1 {
    @include t-headline-1();
}

@mixin t-headline-2 {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 1.625rem;
    line-height: 2rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-headline-2,
.t-headline-2 {
    @include t-headline-2();
}

@mixin t-headline-3 {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 1.375rem;
    line-height: 1.875rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-headline-3,
.t-headline-3 {
    @include t-headline-3();
}
@mixin t-subhead-1 {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-subhead-1,
.t-subhead-1 {
    @include t-subhead-1();
}

@mixin t-subhead-2 {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-subhead-2,
.t-subhead-2 {
    @include t-subhead-2();
}
@mixin t-subhead-small {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-subhead-small,
.t-subhead-small {
    @include t-subhead-small();
}

@mixin t-subhead-large-bold {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-subhead-large-bold,
.t-subhead-large-bold {
    @include t-subhead-large-bold();
}

// No class needed. Is the default body style.
@mixin t-body {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-size: $font-size-base;
    line-height: $line-height-base;
    letter-spacing: 0;
    text-transform: none;
}
%t-body,
.t-body {
    @include t-body();
}

@mixin t-body-small {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-body-small,
.t-body-small {
    @include t-body-small();
}

@mixin t-body-small-bold {
    margin-bottom: 0.25rem;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-body-small-bold,
.t-body-small-bold {
    @include t-body-small-bold();
}

@mixin t-body-tiny {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-size: 0.75rem;
    line-height: 0.875rem;
    letter-spacing: 0.08em;
    text-transform: uppercase;
}
%t-body-tiny,
.t-body-tiny {
    @include t-body-tiny();
}

@mixin t-quote {
    margin: 0;
    font-style: italic;
    font-weight: $font-weight-normal;
    font-size: 2rem;
    line-height: 2.25rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-quote,
.t-quote {
    @include t-quote();
}

@mixin t-navigation {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-normal;
    font-size: 1.125rem;
    line-height: 1.125rem;
    letter-spacing: -0.005em;
    text-transform: none;
}
%t-navigation,
.t-navigation {
    @include t-navigation();
}

@mixin t-card-title {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0;
    text-transform: none;
}
%t-card-title,
.t-card-title {
    @include t-card-title();
}

@mixin t-button {
    margin: 0;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}
%t-button,
.t-button {
    @include t-button();
}
