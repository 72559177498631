.znd-notifications-button {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;

    z-index: 1050;

    cursor: pointer;

    &__name {
        padding-left: 0.625rem;

        @include navbarOnDesktop() {
            display: none;
        }
    }

    &__dropdown-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 0.75rem 1.25rem;

        @include t-body;

        &,
        &:hover,
        &:focus,
        &:active {
            color: $gray-500;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: rgba($gray-100, 0.4);
        }

        i {
            margin-right: 0.5rem;
            color: $gray-300;
            font-size: 1.25rem;
        }
    }
}
