.znd-shop-checkout-payment-page {
    &__title {
        @include t-headline-3;

        margin-bottom: 2.5rem;
        color: $secondary;
    }

    &__button {
        justify-content: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            flex: 1 1 12.5rem;
            margin-left: auto;
            width: auto;
            min-width: 12.5rem;
        }
    }
}
