.znd-return-table-input {
    &__select-header,
    &__input-header {
        margin-bottom: 0.375rem;
        font-weight: $font-weight-bold;
    }

    &__body {
        max-height: calc(100vh - 13.25rem);

        .required {
            color: $primary;
        }
        .form-control {
            margin-bottom: 1.25rem;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__col {
        max-width: 30%;
    }

    &__select {
        margin-bottom: 1.25rem;
        max-width: 50%;
    }

    &__select-country,
    &__select-state {
        width: 100%;
        max-width: 99%;
    }

    &__checkbox-label {
        cursor: pointer;
        margin-bottom: 0;

        .custom-control-label {
            padding-left: 0.375rem;

            &::before,
            &::after {
                top: 50%;
                transform: translateY(-50%);
                width: 1.375rem;
                height: 1.375rem;
            }

            &::before {
                border-radius: 0.25rem;
            }

            &::after {
                left: -1.75rem;
            }
        }
    }

    &__button {
        width: max-content;
    }
}
