.znd-cutter-slider {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;

    &__title {
        margin-bottom: 0.625rem;
        color: $gray-500;
    }
}
