.znd-reseller-file-explorer {
    margin: 1.25rem;
    border-radius: 0.375rem;
    background: $white;
    padding: 1.875rem;
    width: calc(100vw - 2.5rem);
    max-width: 96rem;
    overflow-x: auto;
    overflow-y: hidden;

    @include media-breakpoint-up(lg) {
        margin: 2.5rem;
    }

    &__tree {
        position: relative;
        border-radius: inherit;
        width: 100%;

        &__node {
            padding: 0.05rem;
        }

        &__label {
            &-selected {
                color: $primary;
            }
        }
        &-icon {
            display: block;
            margin-left: auto;
            color: $primary !important;
        }
    }

    &__files {
        padding: 0.05rem;
    }
}
