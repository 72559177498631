.znd-product-list-item {
    margin-bottom: 0.75rem;
    border-radius: 0.375rem;

    background-color: $white;
    padding: 1.25rem;

    width: 100%;

    &-wrapper {
        flex: 0 0 auto;

        @include media-breakpoint-between(sm, md) {
            flex: 0 0 calc(50% - 0.75rem);
            margin-bottom: 0.5rem;
            width: calc(50% - 0.75rem);

            &:nth-child(2n + 1) {
                margin-left: 1.25rem;
            }
        }

        @include media-breakpoint-up(lg) {
            flex: auto;
        }
    }

    &__image {
        @include stretched();

        object-fit: contain;

        &-wrapper {
            position: relative;
            flex: 0 0 auto;

            border: 1px solid $gray-200;
            border-radius: 0.25rem;

            width: 100%;

            &::before {
                display: block;
                padding-top: 51.38%;
                content: '';
            }

            @include media-breakpoint-up(lg) {
                width: 7.75rem;
                height: 4rem;

                &::before {
                    display: none;
                }
            }
        }
    }

    &__title {
        color: $secondary;
    }

    &__description {
        flex: 1 1 auto;
        color: $gray-500;

        @include media-breakpoint-up(lg) {
            flex: 1 1 50%;
            padding: 0 1.25rem;
        }
    }

    &__text-wrapper {
        padding: 1.5rem 0 0.5rem;
        width: 100%;

        @include media-breakpoint-up(lg) {
            flex: 1 1 50%;
            padding: 0 1.25rem;
            min-width: 15rem;
        }
    }

    &__secondary-description {
        width: 100%;

        overflow: hidden;
        color: $gray-500;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include media-breakpoint-up(lg) {
            max-width: 25rem;
        }
    }

    &__price {
        flex: 0 0 auto;
        padding: 1.75rem 0 1.5rem;
        min-width: auto;
        color: $gray-500;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
            flex: 0 0 18%;
            padding-right: 2.5rem;
            min-width: 11.25rem;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 16%;
        }

        @include media-breakpoint-up(hg) {
            flex: 0 0 13%;
        }

        &-per-unit {
            margin-left: 0.25rem;
            color: $gray-400;
            line-height: 1.375rem;

            @include media-breakpoint-up(lg) {
                margin: 0;
                line-height: 1.25rem;
            }
        }

        &-amount-from {
            @include t-body-small;
            color: $gray-400;
        }
    }

    &__btn-add {
        flex: 0 0 auto;

        &-link {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: auto;
            }
        }
    }
}
