.znd-multiselect {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .form-control;
    display: flex;

    position: relative;
    flex-direction: row;
    align-items: stretch;
    max-width: 100%;

    &--open {
        border-color: $input-focus-border-color;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &__arrow {
        $width: add($input-line-height * 1em, $input-padding-y * 2);
        display: flex;

        flex: 0 0 $width;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        margin: (-$input-padding-y) (-$input-padding-x) (-$input-padding-y) auto;
        width: $width;

        color: $primary;
        font-size: 1.25rem;
    }

    &__values {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        margin-top: -($input-padding-y - 0.625rem);
        margin-bottom: -($input-padding-y - 0.625rem);
        margin-left: -($input-padding-x - 0.625rem);

        padding: 0;

        overflow: hidden;
    }

    &__value {
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;

        @include t-body-tiny;
        margin: 0;

        border: 1px solid $gray-500;
        border-radius: $border-radius;
        padding: 0 2.375rem 0 0.625rem;
        max-width: 100%;
        height: 1.75rem;
        color: $gray-500;
        font-weight: $font-weight-bold;

        list-style-type: none;

        & + & {
            margin-left: 0.25rem;
        }

        &-remove {
            display: flex;

            position: absolute;
            top: 0;
            right: 0;
            bottom: 1px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-left: 0.5rem;

            width: 1.625rem;

            font-size: 1.2em;

            &:hover {
                color: $primary;
            }
        }

        &-label {
            display: block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        right: -1px;
        left: -1px;

        z-index: $zindex-dropdown;

        margin: -1px 0 0;

        border: 1px solid $input-focus-border-color;
        border-top-color: $gray-200;
        border-bottom-right-radius: $input-border-radius;
        border-bottom-left-radius: $input-border-radius;

        background-color: $white;
        padding: 0.875rem 0;

        max-height: 12.5rem;
        overflow: auto;
    }

    &__option {
        cursor: pointer;
        margin: 0;
        padding: 0;
        list-style-type: none;

        &:hover {
            background-color: rgba($gray-100, 0.5);
        }
    }

    &__label {
        @include t-body;
        cursor: pointer;
        margin: 0;

        padding: 0.375rem 1.5rem 0.375rem 3.25rem;
        color: $gray-500;

        .custom-control-label {
            &::before,
            &::after {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__more {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        align-items: center;

        @include t-body-tiny;

        margin-top: -($input-padding-y - 0.625rem);
        margin-bottom: -($input-padding-y - 0.625rem);
        margin-left: 0.25rem;

        border: 1px solid $gray-400;
        border-radius: $border-radius;

        padding: 0 0.625rem;
        color: $gray-400;
        font-weight: $font-weight-bold;
    }

    &__placeholder {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        align-items: center;

        color: $input-placeholder-color;
    }
}
