.znd-product-filter {
    &__row {
        &--price {
            input {
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    appearance: none;
                    margin: 0;
                }
            }
        }

        & + & {
            margin-top: 1.25rem;
        }
    }

    &__label {
        @include t-body-small;
        margin-bottom: 0.375rem;
        color: $gray-500;
        font-weight: $font-weight-bold;
    }

    &__btn-clear {
        justify-content: center;
        margin-top: 1.25rem;
        width: 100%;
    }
}
