.znd-select {
    display: flex;
    position: relative;
    flex-direction: column;

    cursor: pointer;

    &__box {
        position: relative;
        align-self: flex-end;
        border-radius: 1.25rem;

        background-color: $white;

        padding: 0.625rem 2.375rem 0.625rem 1rem;
        width: fit-content;

        color: $secondary;
        font-weight: $font-weight-bold;
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
    }

    &__options-wrapper {
        position: absolute;
        top: 100%;
        right: 0;
        visibility: hidden;

        opacity: 0;

        z-index: $zindex-dropdown;
    }

    &__options {
        position: relative;
        margin-top: 1rem;
        box-shadow: $box-shadow;

        border-radius: 0.375rem;

        background-color: $white;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        white-space: nowrap;

        &-item {
            position: relative;
            padding: 0.5rem 3.125rem 0.5rem 2.75rem;

            &:hover {
                background-color: $gray-100;
            }

            &-icon {
                position: absolute;
                top: 50%;
                left: 0.825rem;
                transform: translateY(-50%);
                color: $primary;
            }
        }

        &::before {
            position: absolute;
            top: -0.5rem;
            right: 1.25rem;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid $white;
            border-left: 0.5rem solid transparent;
            width: 0;
            height: 0;
            content: '';
        }
    }

    &:hover &__options-wrapper {
        visibility: visible;
        opacity: 1;
    }
}
