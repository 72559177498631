.znd-registration-success {
    margin: 0 auto;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    width: 100%;
    max-width: 31.25rem;
    color: $secondary;

    &__container {
        position: relative;
        margin-top: 5rem;
        margin-bottom: 2.5rem;
        border-radius: 0.375rem;
        background-color: $white;
        padding-top: 3.125rem;
        padding-bottom: 3.125rem;
        max-height: 26.25rem;

        @include media-breakpoint-up(sm) {
            margin-top: 2.5rem;
            padding: 3.125rem;
        }
    }

    &__icon-container {
        display: flex;
        position: absolute;
        top: -3rem;
        left: 50%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateX(-50%);
        border-radius: 3.125rem;
        background-color: $success;
        width: 6.25rem;
        height: 6.25rem;
    }

    &__icon {
        color: $white;
        font-size: 3.625rem;
    }

    &__title {
        margin-top: 2rem;
        margin-bottom: 1.25rem;
        text-align: center;
    }

    &__text {
        margin-bottom: 3.75rem;
        font-weight: $font-weight-normal;
        text-align: center;
    }

    &__button {
        align-self: flex-end;
        margin: 0 auto;
    }
}
