.znd-top-page {
    margin: 0 1.5rem 1.25rem;

    @include media-breakpoint-up(md) {
        margin-right: 2.5rem;
        margin-left: 2.5rem;
    }

    &__title {
        color: $secondary;
    }
}
