.zkp-voucher-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-radius: 0.375rem;
    background-color: #fff;
    padding: 1.25rem;
    min-width: 26rem;

    @include cartLineItemOnDesktop {
        border: 1px solid $gray-200;
    }

    &__collapse-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    &__title {
        flex: 1 1 100%;

        @include t-subhead-1;
    }

    &__icon {
        flex: 0 0 auto;
        margin-left: 3rem;
        color: $primary;
        font-size: 1.25rem;
    }

    &__form {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-top: 1rem;
    }

    &__input {
        flex: 1 1 100%;
    }

    &__button {
        flex: 0 0 auto;
        margin-left: 0.75rem;
    }
}
