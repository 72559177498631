.znd-top-bar {
    visibility: hidden;
    padding: 0;
    width: 0;
    height: 0;

    @include navbarOnDesktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        visibility: visible;
        padding-right: 1.25rem;

        padding-left: 2.5rem;
        width: 100%;

        height: 5rem;
    }

    &__logo {
        height: 2.625rem;
    }

    &__search-input {
        margin-left: 2.5rem;
        width: 16.25rem;
    }

    &__menus {
        display: flex;
        margin-left: auto;
    }

    &__icon-button {
        margin-left: 0.625rem;

        &-shopping-cart,
        &-notifications {
            position: fixed;
            right: 1.75rem;
            bottom: 1.75rem;

            visibility: visible;

            z-index: 100;

            .btn {
                box-shadow: $elevation-shadow-action-button;

                @include navbarOnDesktop {
                    box-shadow: none;
                }
            }

            @include navbarOnDesktop {
                position: relative;
                right: 0;
                bottom: 0;
            }

            &__badge {
                display: flex;
                position: absolute;
                top: -0.25rem;
                right: -0.125rem;
                justify-content: center;
                align-items: center;

                z-index: 110;

                box-shadow: $elevation-shadow-200;
                border-radius: 50%;
                background-color: $white;

                min-width: 1.25rem;

                color: $primary;

                font-weight: $font-weight-bold;
            }
        }
    }

    &__company {
        margin-right: 1.375rem;

        &-selector {
            margin-right: 0.5rem;
        }

        &-title {
            color: $secondary;
        }
    }
}
