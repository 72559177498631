.znd-address {
    &__headline {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
        color: $gray-500;
    }

    &__icon {
        margin-top: -1px;
        margin-right: 0.5rem;
        color: $primary;
    }

    &__details {
        color: $gray-500;
    }

    &__button {
        font-size: 0.875rem;

        i {
            margin-left: 0.5rem;
            font-size: 1.2rem !important;
        }

        span {
            padding-top: 3px;
        }
    }
}
