.znd-shop-checkout-summary {
    &__title {
        @include t-headline-3;
        margin-bottom: 2.5rem;

        color: $secondary;

        @include media-breakpoint-up(lg) {
            @include t-subhead-1;
            margin-bottom: 1.25rem;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 2.5rem;
        }
    }

    &__loader {
        margin-right: auto;
        margin-left: auto;
    }

    &__purchase-order,
    &__tax-id {
        &__subhead {
            margin-bottom: 1.25rem;
            color: $gray-500;
        }

        &__input {
            margin-bottom: 2.5rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(xl) {
                max-width: 16.25rem;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        padding-bottom: 1rem;

        & + & {
            padding-top: 1rem;
        }

        &-info {
            display: flex;
            flex: 1 1 100%;
            flex-direction: row;
            flex-wrap: wrap;
            width: 1px;

            // Fixes weird flexbox text overflow bug
            min-width: 0;

            @include media-breakpoint-up(md) {
                padding: 0;
            }

            @include media-breakpoint-up(lg) {
                flex-wrap: wrap;
            }
        }

        &-name {
            @include t-subhead-small;

            flex: 0 0 60%;
            order: 1;
            overflow: hidden;
            color: $gray-500;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include media-breakpoint-up(md) {
                flex: 0 0 70%;
            }

            @include media-breakpoint-up(lg) {
                flex: 0 0 63%;
            }

            @include media-breakpoint-up(xl) {
                flex: 0 0 48%;
            }
        }

        &-quantity {
            @include t-body;
            order: 4;
            padding-top: 0.125rem;
            color: $gray-400;

            @include media-breakpoint-up(md) {
                flex: 0 0 10%;
                order: 2;
                padding-top: 0;
                text-align: right;
            }

            @include media-breakpoint-up(lg) {
                flex: 0 0 auto;
                order: 4;
                margin-top: 0.5rem;
            }

            @include media-breakpoint-up(xl) {
                flex: 0 0 22%;
                order: 2;
                margin: 0;
                padding-left: 1.25rem;
            }
        }

        &-payload-descriptions {
            flex: 0 0 100%;
            order: 3;
            padding-top: 0.25rem;
            overflow: hidden;

            color: $gray-500;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include media-breakpoint-up(md) {
                order: 4;
            }

            @include media-breakpoint-up(lg) {
                order: 3;
            }

            @include media-breakpoint-up(xl) {
                order: 4;
            }
        }

        &-price {
            @include t-body;

            display: flex;
            flex: 0 0 40%;
            justify-content: flex-end;
            align-items: center;
            order: 2;
            color: $gray-500;
            text-align: right;

            @include media-breakpoint-up(md) {
                flex: 0 0 20%;
                order: 3;
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                flex: 0 0 37%;
                order: 2;
            }

            @include media-breakpoint-up(xl) {
                flex: 0 0 30%;
                order: 3;
            }
        }
    }

    &__sums {
        margin-right: -1.25rem;
        margin-left: -1.25rem;

        border-top: 1px solid $gray-200;
        padding-top: 0.75rem;
        padding-right: 1.25rem;
        padding-left: 1.25rem;

        @include media-breakpoint-up(md) {
            margin-right: -2.5rem;
            margin-left: -2.5rem;
            padding-right: 2.5rem;
            padding-left: 2.5rem;
        }

        @include media-breakpoint-up(lg) {
            margin-right: -2.5rem;
            margin-left: -1.25rem;
            padding-right: 2.5rem;
            padding-left: 1.25rem;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 1.25rem;
        }
    }

    &__sum {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include t-body;
        color: $gray-400;

        & + & {
            margin-top: 0.5rem;
        }

        &-label {
            flex: 0 0 66%;
            padding-right: 1.75rem;
            text-align: right;

            @include media-breakpoint-up(md) {
                flex: 0 0 80%;
                padding-right: 0;
            }

            @include media-breakpoint-up(lg) {
                flex: 0 0 63%;
            }

            @include media-breakpoint-up(xl) {
                flex: 0 0 70%;
            }
        }
        &-price {
            flex: 0 0 33%;
            align-self: flex-end;
            text-align: right;

            @include media-breakpoint-up(md) {
                flex: 0 0 20%;
            }

            @include media-breakpoint-up(lg) {
                flex: 0 0 37%;
            }

            @include media-breakpoint-up(xl) {
                flex: 0 0 30%;
            }
        }

        &--total {
            @include t-subhead-1;
            color: $gray-500;
        }
    }

    &__submit {
        justify-content: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            flex: 1 1 12.5rem;
            margin-left: auto;
            width: auto;
            min-width: 12.5rem;
        }
    }

    &__error-information {
        opacity: 1;
        transition: opacity ease-out 1s;

        margin: 1.25rem auto 0;
        width: fit-content;
        color: $primary;
        text-align: left;

        @include media-breakpoint-up(md) {
            margin-right: 0;
            margin-left: auto;
        }

        &--hide {
            opacity: 0;
        }
    }

    &__checkbox-label {
        @include t-body;
        cursor: pointer;
        margin: 0;
        color: $gray-500;
        text-align: right;

        .custom-control-label {
            &::before,
            &::after {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__checkbox-container {
        display: flex;
        justify-content: flex-end;
        padding-top: 0.5rem;
        padding-bottom: 2.625rem;
    }

    &__promotion-remove {
        vertical-align: middle;
        border: none;
        background: none;
        padding: 0;
    }
}
