@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*
 * PROJECT ONLY VARIABLES
 */
/*
 * BOOTSTRAP VARIABLES
 */
/*
 * Grid breakpoints

 * Define the minimum dimensions at which your layout will change,
 * adapting to different screen sizes, for use in media queries.
 */
@import '~@adyen/adyen-web/dist/adyen.css';
:export {
  breakpoint_xs: 0;
  breakpoint_sm: 576px;
  breakpoint_md: 768px;
  breakpoint_lg: 992px;
  breakpoint_xl: 1200px;
  breakpoint_hg: 1440px;
  menu_breakpoint: xl;
  body_color: #212525;
  font_size_base: 1rem;
  font_family_base: "IBMPlexSans", -apple-system, "Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  success_color: #24a14b;
  danger_color: #e24c4c;
  input_placeholder_color: #212525;
  input_border_color: #d1dae1;
  input_color: #212525;
  gray_200: #d1dae1;
  gray_400: #74797c;
  gray_500: #212525;
  secondary: #4c575d;
  primary: #e4032e;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #e4032e;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #e4032e;
  --secondary: #4c575d;
  --success: #24a14b;
  --info: #f1a153;
  --warning: #f1a153;
  --danger: #e24c4c;
  --light: #ececed;
  --dark: #212525;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-hg: 1440px;
  --font-family-sans-serif: -apple-system, "Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "IBMPlexSans", -apple-system, "Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212525;
  text-align: left;
  background-color: #ececed;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #e4032e;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #98021f;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #c4c8c8;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ececed;
  border: 1px solid #c4c8c8;
  border-radius: 5px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-hg,
.col-hg-auto, .col-hg-12, .col-hg-11, .col-hg-10, .col-hg-9, .col-hg-8, .col-hg-7, .col-hg-6, .col-hg-5, .col-hg-4, .col-hg-3, .col-hg-2, .col-hg-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1440px) {
  .col-hg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-hg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-hg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-hg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-hg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-hg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-hg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-hg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-hg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-hg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-hg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-hg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-hg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-hg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-hg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-hg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-hg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-hg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-hg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-hg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-hg-first {
    order: -1;
  }
  .order-hg-last {
    order: 13;
  }
  .order-hg-0 {
    order: 0;
  }
  .order-hg-1 {
    order: 1;
  }
  .order-hg-2 {
    order: 2;
  }
  .order-hg-3 {
    order: 3;
  }
  .order-hg-4 {
    order: 4;
  }
  .order-hg-5 {
    order: 5;
  }
  .order-hg-6 {
    order: 6;
  }
  .order-hg-7 {
    order: 7;
  }
  .order-hg-8 {
    order: 8;
  }
  .order-hg-9 {
    order: 9;
  }
  .order-hg-10 {
    order: 10;
  }
  .order-hg-11 {
    order: 11;
  }
  .order-hg-12 {
    order: 12;
  }
  .offset-hg-0 {
    margin-left: 0;
  }
  .offset-hg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-hg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-hg-3 {
    margin-left: 25%;
  }
  .offset-hg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-hg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-hg-6 {
    margin-left: 50%;
  }
  .offset-hg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-hg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-hg-9 {
    margin-left: 75%;
  }
  .offset-hg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-hg-11 {
    margin-left: 91.6666666667%;
  }
}
.form-control, .znd-multiselect {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212525;
  background-color: #ececed;
  background-clip: padding-box;
  border: 1px solid #d1dae1;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .znd-multiselect {
    transition: none;
  }
}
.form-control::-ms-expand, .znd-multiselect::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring, .znd-multiselect:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212525;
}
.form-control:focus, .znd-multiselect:focus {
  color: #212525;
  background-color: #ececed;
  border-color: #74797c;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder, .znd-multiselect::placeholder {
  color: #212525;
  opacity: 1;
}
.form-control:disabled, .znd-multiselect:disabled, .form-control[readonly], [readonly].znd-multiselect {
  background-color: #ececed;
  opacity: 1;
}

input[type=date].form-control, input[type=date].znd-multiselect,
input[type=time].form-control,
input[type=time].znd-multiselect,
input[type=datetime-local].form-control,
input[type=datetime-local].znd-multiselect,
input[type=month].form-control,
input[type=month].znd-multiselect {
  appearance: none;
}

select.form-control:focus::-ms-value, select.znd-multiselect:focus::-ms-value {
  color: #212525;
  background-color: #ececed;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212525;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select[size].znd-multiselect, select.form-control[multiple], select[multiple].znd-multiselect {
  height: auto;
}

textarea.form-control, textarea.znd-multiselect {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #c4c8c8;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #24a14b;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.375rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: #24a14b;
  border-radius: 5px;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .znd-multiselect:valid, .form-control.is-valid, .is-valid.znd-multiselect {
  border-color: #24a14b;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2324a14b' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:valid:focus, .was-validated .znd-multiselect:valid:focus, .form-control.is-valid:focus, .is-valid.znd-multiselect:focus {
  border-color: #24a14b;
  box-shadow: 0 0 0 0.2rem rgba(36, 161, 75, 0.25);
}

.was-validated textarea.form-control:valid, .was-validated textarea.znd-multiselect:valid, textarea.form-control.is-valid, textarea.is-valid.znd-multiselect {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #24a14b;
  padding-right: calc(0.75em + 3.125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #ececed url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2324a14b' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #24a14b;
  box-shadow: 0 0 0 0.2rem rgba(36, 161, 75, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #24a14b;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #24a14b;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #24a14b;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #2dcb5e;
  background-color: #2dcb5e;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(36, 161, 75, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #24a14b;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #24a14b;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #24a14b;
  box-shadow: 0 0 0 0.2rem rgba(36, 161, 75, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e24c4c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.375rem 0.375rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: #e24c4c;
  border-radius: 5px;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .znd-multiselect:invalid, .form-control.is-invalid, .is-invalid.znd-multiselect {
  border-color: #e24c4c;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e24c4c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e24c4c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:invalid:focus, .was-validated .znd-multiselect:invalid:focus, .form-control.is-invalid:focus, .is-invalid.znd-multiselect:focus {
  border-color: #e24c4c;
  box-shadow: 0 0 0 0.2rem rgba(226, 76, 76, 0.25);
}

.was-validated textarea.form-control:invalid, .was-validated textarea.znd-multiselect:invalid, textarea.form-control.is-invalid, textarea.is-invalid.znd-multiselect {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e24c4c;
  padding-right: calc(0.75em + 3.125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat, #ececed url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e24c4c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e24c4c' stroke='none'/%3e%3c/svg%3e") center right 2rem/calc(0.75em + 0.75rem) calc(0.75em + 0.75rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e24c4c;
  box-shadow: 0 0 0 0.2rem rgba(226, 76, 76, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e24c4c;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e24c4c;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e24c4c;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e97878;
  background-color: #e97878;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(226, 76, 76, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e24c4c;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e24c4c;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e24c4c;
  box-shadow: 0 0 0 0.2rem rgba(226, 76, 76, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control, .form-inline .znd-multiselect {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .adyen-checkout__link.adyen-checkout__link {
  display: inline-block;
  font-weight: 700;
  color: #212525;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.875rem 3.25rem;
  font-size: 0.825rem;
  line-height: 1.5;
  border-radius: 1.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .adyen-checkout__link.adyen-checkout__link {
    transition: none;
  }
}
.btn:hover, .adyen-checkout__link:hover {
  color: #212525;
  text-decoration: none;
}
.btn:focus, .adyen-checkout__link:focus, .btn.focus, .focus.adyen-checkout__link {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(228, 3, 46, 0.25);
}
.btn.disabled, .disabled.adyen-checkout__link, .btn:disabled, .adyen-checkout__link:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled), .adyen-checkout__link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled, a.disabled.adyen-checkout__link,
fieldset:disabled a.btn,
fieldset:disabled a.adyen-checkout__link {
  pointer-events: none;
}

.btn-outline-primary {
  color: #e4032e;
  border-color: #e4032e;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #e4032e;
  border-color: #e4032e;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 3, 46, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #e4032e;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #e4032e;
  border-color: #e4032e;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 3, 46, 0.5);
}

.btn-outline-secondary {
  color: #4c575d;
  border-color: #4c575d;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #4c575d;
  border-color: #4c575d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 87, 93, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #4c575d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #4c575d;
  border-color: #4c575d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 87, 93, 0.5);
}

.btn-outline-success {
  color: #24a14b;
  border-color: #24a14b;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #24a14b;
  border-color: #24a14b;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 161, 75, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #24a14b;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #24a14b;
  border-color: #24a14b;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 161, 75, 0.5);
}

.btn-outline-info {
  color: #f1a153;
  border-color: #f1a153;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #f1a153;
  border-color: #f1a153;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 161, 83, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #f1a153;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #f1a153;
  border-color: #f1a153;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 161, 83, 0.5);
}

.btn-outline-warning {
  color: #f1a153;
  border-color: #f1a153;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #f1a153;
  border-color: #f1a153;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 161, 83, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f1a153;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f1a153;
  border-color: #f1a153;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 161, 83, 0.5);
}

.btn-outline-danger {
  color: #e24c4c;
  border-color: #e24c4c;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #e24c4c;
  border-color: #e24c4c;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 76, 76, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e24c4c;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #e24c4c;
  border-color: #e24c4c;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 76, 76, 0.5);
}

.btn-outline-light {
  color: #ececed;
  border-color: #ececed;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #ececed;
  border-color: #ececed;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 236, 237, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #ececed;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #ececed;
  border-color: #ececed;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 236, 237, 0.5);
}

.btn-outline-dark {
  color: #212525;
  border-color: #212525;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #212525;
  border-color: #212525;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 37, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #212525;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #212525;
  border-color: #212525;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 37, 37, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #e4032e;
  text-decoration: none;
}
.btn-link:hover {
  color: #98021f;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.625rem 1rem;
  font-size: 0.825rem;
  line-height: 1.5;
  border-radius: 1.5rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .input-group > .znd-multiselect,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control, .input-group > .znd-multiselect + .form-control, .input-group > .form-control + .znd-multiselect, .input-group > .znd-multiselect + .znd-multiselect,
.input-group > .form-control + .custom-select,
.input-group > .znd-multiselect + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .znd-multiselect + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .znd-multiselect,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .znd-multiselect,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .znd-multiselect,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus, .input-group > .znd-multiselect:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child), .input-group > .znd-multiselect:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child), .input-group:not(.has-validation) > .znd-multiselect:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3), .input-group.has-validation > .znd-multiselect:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn, .input-group-prepend .adyen-checkout__link.adyen-checkout__link,
.input-group-append .btn,
.input-group-append .adyen-checkout__link.adyen-checkout__link {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus, .input-group-prepend .adyen-checkout__link:focus,
.input-group-append .btn:focus,
.input-group-append .adyen-checkout__link:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn, .input-group-prepend .adyen-checkout__link.adyen-checkout__link + .btn, .input-group-prepend .btn + .adyen-checkout__link.adyen-checkout__link, .input-group-prepend .adyen-checkout__link.adyen-checkout__link + .adyen-checkout__link.adyen-checkout__link,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .adyen-checkout__link.adyen-checkout__link + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .adyen-checkout__link.adyen-checkout__link,
.input-group-append .btn + .btn,
.input-group-append .adyen-checkout__link.adyen-checkout__link + .btn,
.input-group-append .btn + .adyen-checkout__link.adyen-checkout__link,
.input-group-append .adyen-checkout__link.adyen-checkout__link + .adyen-checkout__link.adyen-checkout__link,
.input-group-append .btn + .input-group-text,
.input-group-append .adyen-checkout__link.adyen-checkout__link + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .adyen-checkout__link.adyen-checkout__link {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212525;
  text-align: center;
  white-space: nowrap;
  background-color: #d1dae1;
  border: 1px solid #d1dae1;
  border-radius: 5px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea), .input-group-lg > .znd-multiselect:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control, .input-group-lg > .znd-multiselect,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .adyen-checkout__link.adyen-checkout__link,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .adyen-checkout__link.adyen-checkout__link {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea), .input-group-sm > .znd-multiselect:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control, .input-group-sm > .znd-multiselect,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .adyen-checkout__link.adyen-checkout__link,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .adyen-checkout__link.adyen-checkout__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .adyen-checkout__link.adyen-checkout__link,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .adyen-checkout__link.adyen-checkout__link,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .adyen-checkout__link.adyen-checkout__link,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .adyen-checkout__link:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .adyen-checkout__link.adyen-checkout__link,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .adyen-checkout__link.adyen-checkout__link,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .adyen-checkout__link:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #e4032e;
  background-color: #e4032e;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #74797c;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #fe9caf;
  border-color: #fe9caf;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #ececed;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ececed;
  border: #212525 solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 5px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23ffffff' d='M6.928 11.516l5.76-5.726c.2-.178.2-.368 0-.569l-.737-.737c-.201-.2-.39-.2-.57 0L6.628 9.172 4.652 7.129c-.201-.178-.391-.178-.57 0l-.77.77c-.2.18-.2.369 0 .57l3.047 3.047c.179.2.368.2.57 0z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #e4032e;
  background-color: #e4032e;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(228, 3, 46, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(228, 3, 46, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(228, 3, 46, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #212525;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ececed;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(228, 3, 46, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 2rem 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212525;
  vertical-align: middle;
  background: #ececed url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat;
  border: 1px solid #d1dae1;
  border-radius: 5px;
  appearance: none;
}
.custom-select:focus {
  border-color: #74797c;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(228, 3, 46, 0.25);
}
.custom-select:focus::-ms-value {
  color: #212525;
  background-color: #ececed;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #d1dae1;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212525;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #74797c;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #ececed;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #212525;
  background-color: #ececed;
  border: 1px solid #d1dae1;
  border-radius: 5px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.5rem);
  padding: 0.75rem 1rem;
  line-height: 1.5;
  color: #212525;
  content: "Browse";
  background-color: #d1dae1;
  border-left: inherit;
  border-radius: 0 5px 5px 0;
}

.custom-range {
  width: 100%;
  height: 1.65rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.4375rem;
  background-color: #e4032e;
  border: 0;
  border-radius: 100%;
  transition: none;
  appearance: none;
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #e4032e;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: #d1dae1;
  border-color: transparent;
  border-radius: 4px;
}
.custom-range::-moz-range-thumb {
  width: 1.25rem;
  height: 1.25rem;
  background-color: #e4032e;
  border: 0;
  border-radius: 100%;
  transition: none;
  appearance: none;
}
.custom-range::-moz-range-thumb:active {
  background-color: #e4032e;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: #d1dae1;
  border-color: transparent;
  border-radius: 4px;
}
.custom-range::-ms-thumb {
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #e4032e;
  border: 0;
  border-radius: 100%;
  transition: none;
  appearance: none;
}
.custom-range::-ms-thumb:active {
  background-color: #e4032e;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.375rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.625rem;
}
.custom-range::-ms-fill-lower {
  background-color: #d1dae1;
  border-radius: 4px;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #d1dae1;
  border-radius: 4px;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #c4c8c8;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #c4c8c8;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #c4c8c8;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: none;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #c4c8c8;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #d1dae1 #d1dae1 #c4c8c8;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ececed;
  border-color: #c4c8c8 #c4c8c8 #ececed;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 5px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #e4032e;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 4px 4px 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 4px 4px;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 4px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: 100%;
}
.modal-dialog-scrollable .modal-content {
  max-height: 100vh;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: 100%;
}
.modal-dialog-centered::before {
  display: block;
  height: 100vh;
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #212525;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.4;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 2.5rem;
  border-bottom: 0 solid transparent;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 2.5rem;
  margin: -1rem -2.5rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 0 solid transparent;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 400px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 620px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1600px;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #e4032e !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #b20224 !important;
}

.bg-secondary {
  background-color: #4c575d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #353d41 !important;
}

.bg-success {
  background-color: #24a14b !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1b7738 !important;
}

.bg-info {
  background-color: #f1a153 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #ed8724 !important;
}

.bg-warning {
  background-color: #f1a153 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ed8724 !important;
}

.bg-danger {
  background-color: #e24c4c !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d82323 !important;
}

.bg-light {
  background-color: #ececed !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d2d2d4 !important;
}

.bg-dark {
  background-color: #212525 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #090a0a !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #c4c8c8 !important;
}

.border-top {
  border-top: 1px solid #c4c8c8 !important;
}

.border-right {
  border-right: 1px solid #c4c8c8 !important;
}

.border-bottom {
  border-bottom: 1px solid #c4c8c8 !important;
}

.border-left {
  border-left: 1px solid #c4c8c8 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #e4032e !important;
}

.border-secondary {
  border-color: #4c575d !important;
}

.border-success {
  border-color: #24a14b !important;
}

.border-info {
  border-color: #f1a153 !important;
}

.border-warning {
  border-color: #f1a153 !important;
}

.border-danger {
  border-color: #e24c4c !important;
}

.border-light {
  border-color: #ececed !important;
}

.border-dark {
  border-color: #212525 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1440px) {
  .d-hg-none {
    display: none !important;
  }
  .d-hg-inline {
    display: inline !important;
  }
  .d-hg-inline-block {
    display: inline-block !important;
  }
  .d-hg-block {
    display: block !important;
  }
  .d-hg-table {
    display: table !important;
  }
  .d-hg-table-row {
    display: table-row !important;
  }
  .d-hg-table-cell {
    display: table-cell !important;
  }
  .d-hg-flex {
    display: flex !important;
  }
  .d-hg-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1440px) {
  .flex-hg-row {
    flex-direction: row !important;
  }
  .flex-hg-column {
    flex-direction: column !important;
  }
  .flex-hg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-hg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-hg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-hg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-hg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-hg-fill {
    flex: 1 1 auto !important;
  }
  .flex-hg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-hg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-hg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-hg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-hg-start {
    justify-content: flex-start !important;
  }
  .justify-content-hg-end {
    justify-content: flex-end !important;
  }
  .justify-content-hg-center {
    justify-content: center !important;
  }
  .justify-content-hg-between {
    justify-content: space-between !important;
  }
  .justify-content-hg-around {
    justify-content: space-around !important;
  }
  .align-items-hg-start {
    align-items: flex-start !important;
  }
  .align-items-hg-end {
    align-items: flex-end !important;
  }
  .align-items-hg-center {
    align-items: center !important;
  }
  .align-items-hg-baseline {
    align-items: baseline !important;
  }
  .align-items-hg-stretch {
    align-items: stretch !important;
  }
  .align-content-hg-start {
    align-content: flex-start !important;
  }
  .align-content-hg-end {
    align-content: flex-end !important;
  }
  .align-content-hg-center {
    align-content: center !important;
  }
  .align-content-hg-between {
    align-content: space-between !important;
  }
  .align-content-hg-around {
    align-content: space-around !important;
  }
  .align-content-hg-stretch {
    align-content: stretch !important;
  }
  .align-self-hg-auto {
    align-self: auto !important;
  }
  .align-self-hg-start {
    align-self: flex-start !important;
  }
  .align-self-hg-end {
    align-self: flex-end !important;
  }
  .align-self-hg-center {
    align-self: center !important;
  }
  .align-self-hg-baseline {
    align-self: baseline !important;
  }
  .align-self-hg-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1440px) {
  .float-hg-left {
    float: left !important;
  }
  .float-hg-right {
    float: right !important;
  }
  .float-hg-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1440px) {
  .m-hg-0 {
    margin: 0 !important;
  }
  .mt-hg-0,
  .my-hg-0 {
    margin-top: 0 !important;
  }
  .mr-hg-0,
  .mx-hg-0 {
    margin-right: 0 !important;
  }
  .mb-hg-0,
  .my-hg-0 {
    margin-bottom: 0 !important;
  }
  .ml-hg-0,
  .mx-hg-0 {
    margin-left: 0 !important;
  }
  .m-hg-1 {
    margin: 0.25rem !important;
  }
  .mt-hg-1,
  .my-hg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-hg-1,
  .mx-hg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-hg-1,
  .my-hg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-hg-1,
  .mx-hg-1 {
    margin-left: 0.25rem !important;
  }
  .m-hg-2 {
    margin: 0.5rem !important;
  }
  .mt-hg-2,
  .my-hg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-hg-2,
  .mx-hg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-hg-2,
  .my-hg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-hg-2,
  .mx-hg-2 {
    margin-left: 0.5rem !important;
  }
  .m-hg-3 {
    margin: 1rem !important;
  }
  .mt-hg-3,
  .my-hg-3 {
    margin-top: 1rem !important;
  }
  .mr-hg-3,
  .mx-hg-3 {
    margin-right: 1rem !important;
  }
  .mb-hg-3,
  .my-hg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-hg-3,
  .mx-hg-3 {
    margin-left: 1rem !important;
  }
  .m-hg-4 {
    margin: 1.5rem !important;
  }
  .mt-hg-4,
  .my-hg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-hg-4,
  .mx-hg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-hg-4,
  .my-hg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-hg-4,
  .mx-hg-4 {
    margin-left: 1.5rem !important;
  }
  .m-hg-5 {
    margin: 3rem !important;
  }
  .mt-hg-5,
  .my-hg-5 {
    margin-top: 3rem !important;
  }
  .mr-hg-5,
  .mx-hg-5 {
    margin-right: 3rem !important;
  }
  .mb-hg-5,
  .my-hg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-hg-5,
  .mx-hg-5 {
    margin-left: 3rem !important;
  }
  .p-hg-0 {
    padding: 0 !important;
  }
  .pt-hg-0,
  .py-hg-0 {
    padding-top: 0 !important;
  }
  .pr-hg-0,
  .px-hg-0 {
    padding-right: 0 !important;
  }
  .pb-hg-0,
  .py-hg-0 {
    padding-bottom: 0 !important;
  }
  .pl-hg-0,
  .px-hg-0 {
    padding-left: 0 !important;
  }
  .p-hg-1 {
    padding: 0.25rem !important;
  }
  .pt-hg-1,
  .py-hg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-hg-1,
  .px-hg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-hg-1,
  .py-hg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-hg-1,
  .px-hg-1 {
    padding-left: 0.25rem !important;
  }
  .p-hg-2 {
    padding: 0.5rem !important;
  }
  .pt-hg-2,
  .py-hg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-hg-2,
  .px-hg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-hg-2,
  .py-hg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-hg-2,
  .px-hg-2 {
    padding-left: 0.5rem !important;
  }
  .p-hg-3 {
    padding: 1rem !important;
  }
  .pt-hg-3,
  .py-hg-3 {
    padding-top: 1rem !important;
  }
  .pr-hg-3,
  .px-hg-3 {
    padding-right: 1rem !important;
  }
  .pb-hg-3,
  .py-hg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-hg-3,
  .px-hg-3 {
    padding-left: 1rem !important;
  }
  .p-hg-4 {
    padding: 1.5rem !important;
  }
  .pt-hg-4,
  .py-hg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-hg-4,
  .px-hg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-hg-4,
  .py-hg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-hg-4,
  .px-hg-4 {
    padding-left: 1.5rem !important;
  }
  .p-hg-5 {
    padding: 3rem !important;
  }
  .pt-hg-5,
  .py-hg-5 {
    padding-top: 3rem !important;
  }
  .pr-hg-5,
  .px-hg-5 {
    padding-right: 3rem !important;
  }
  .pb-hg-5,
  .py-hg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-hg-5,
  .px-hg-5 {
    padding-left: 3rem !important;
  }
  .m-hg-n1 {
    margin: -0.25rem !important;
  }
  .mt-hg-n1,
  .my-hg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-hg-n1,
  .mx-hg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-hg-n1,
  .my-hg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-hg-n1,
  .mx-hg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-hg-n2 {
    margin: -0.5rem !important;
  }
  .mt-hg-n2,
  .my-hg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-hg-n2,
  .mx-hg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-hg-n2,
  .my-hg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-hg-n2,
  .mx-hg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-hg-n3 {
    margin: -1rem !important;
  }
  .mt-hg-n3,
  .my-hg-n3 {
    margin-top: -1rem !important;
  }
  .mr-hg-n3,
  .mx-hg-n3 {
    margin-right: -1rem !important;
  }
  .mb-hg-n3,
  .my-hg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-hg-n3,
  .mx-hg-n3 {
    margin-left: -1rem !important;
  }
  .m-hg-n4 {
    margin: -1.5rem !important;
  }
  .mt-hg-n4,
  .my-hg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-hg-n4,
  .mx-hg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-hg-n4,
  .my-hg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-hg-n4,
  .mx-hg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-hg-n5 {
    margin: -3rem !important;
  }
  .mt-hg-n5,
  .my-hg-n5 {
    margin-top: -3rem !important;
  }
  .mr-hg-n5,
  .mx-hg-n5 {
    margin-right: -3rem !important;
  }
  .mb-hg-n5,
  .my-hg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-hg-n5,
  .mx-hg-n5 {
    margin-left: -3rem !important;
  }
  .m-hg-auto {
    margin: auto !important;
  }
  .mt-hg-auto,
  .my-hg-auto {
    margin-top: auto !important;
  }
  .mr-hg-auto,
  .mx-hg-auto {
    margin-right: auto !important;
  }
  .mb-hg-auto,
  .my-hg-auto {
    margin-bottom: auto !important;
  }
  .ml-hg-auto,
  .mx-hg-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .text-hg-left {
    text-align: left !important;
  }
  .text-hg-right {
    text-align: right !important;
  }
  .text-hg-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #e4032e !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #98021f !important;
}

.text-secondary {
  color: #4c575d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #2a3033 !important;
}

.text-success {
  color: #24a14b !important;
}

a.text-success:hover, a.text-success:focus {
  color: #16622e !important;
}

.text-info {
  color: #f1a153 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #e57a13 !important;
}

.text-warning {
  color: #f1a153 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #e57a13 !important;
}

.text-danger {
  color: #e24c4c !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #c21f1f !important;
}

.text-light {
  color: #ececed !important;
}

a.text-light:hover, a.text-light:focus {
  color: #c5c5c8 !important;
}

.text-dark {
  color: #212525 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #212525 !important;
}

.text-muted {
  color: #c4c8c8 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.Toastify__toast-container {
  z-index: 700000;
  -webkit-transform: translate3d(0, 0, 700000px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #212525;
  color: #fff;
}
.Toastify__toast--default {
  background: #4c575d;
  color: #aaa;
}
.Toastify__toast--info {
  background: #f1a153;
}
.Toastify__toast--success {
  background: #24a14b;
}
.Toastify__toast--warning {
  background: #f1a153;
}
.Toastify__toast--error {
  background: #e24c4c;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 700000;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
  background: #bb86fc;
}
@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

.adyen-checkout__spinner.adyen-checkout__spinner {
  border-color: #e4032e;
  border-top-color: transparent;
}

.adyen-checkout__label.adyen-checkout__label .adyen-checkout__label__text {
  color: #212525;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}
.adyen-checkout__label.adyen-checkout__label--focused .adyen-checkout__label__text, .adyen-checkout__label.adyen-checkout__label--focused .adyen-checkout__label__text {
  color: #212525;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.adyen-checkout__input-wrapper.adyen-checkout__input-wrapper {
  border: 0 none transparent;
}
.adyen-checkout__input-wrapper.adyen-checkout__input-wrapper, .adyen-checkout__input-wrapper.adyen-checkout__input-wrapper:hover, .adyen-checkout__input-wrapper.adyen-checkout__input-wrapper:active, .adyen-checkout__input-wrapper.adyen-checkout__input-wrapper:active:hover, .adyen-checkout__input-wrapper.adyen-checkout__input-wrapper:focus, .adyen-checkout__input-wrapper.adyen-checkout__input-wrapper:focus:hover {
  border: 0 none transparent;
}

.adyen-checkout__input.adyen-checkout__input {
  border-color: #d1dae1;
  background-color: #ececed;
  height: calc(1.5em + 1.5rem + 2px);
  color: #212525;
  caret-color: #212525;
}
.adyen-checkout__input.adyen-checkout__input--focus, .adyen-checkout__input.adyen-checkout__input:hover, .adyen-checkout__input.adyen-checkout__input:active, .adyen-checkout__input.adyen-checkout__input:active:hover, .adyen-checkout__input.adyen-checkout__input:focus, .adyen-checkout__input.adyen-checkout__input:focus:hover {
  box-shadow: none;
  border-color: #74797c;
  color: #212525;
  caret-color: #212525;
}

.adyen-checkout__image.adyen-checkout__image {
  vertical-align: top;
}

.adyen-checkout__input-wrapper.adyen-checkout__input-wrapper img {
  vertical-align: top;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  src: local("AkkuratPro-Regular"), url(../fonts/akkurat/AkkRg_Pro.otf);
  font-family: "Akkurat Pro";
}
@font-face {
  font-style: normal;
  font-weight: 700;
  src: local("AkkuratPro-Bold"), url(../fonts/akkurat/AkkBd_Pro.otf);
  font-family: "Akkurat Pro";
}
@font-face {
  font-style: italic;
  font-weight: 400;
  src: local("AkkuratPro-Italic"), url(../fonts/akkurat/AkkIt_Pro.otf);
  font-family: "Akkurat Pro";
}
@font-face {
  font-style: italic;
  font-weight: 700;
  src: local("AkkuratPro-BoldItalic"), url(../fonts/akkurat/AkkBdIt_Pro.otf);
  font-family: "Akkurat Pro";
}
@font-face {
  font-style: normal;
  font-weight: 400;
  src: local("IBMPlexSans-Regular"), url(../fonts/ibmplexsans/IBMPlexSans-Regular.ttf);
  font-family: "IBMPlexSans";
}
@font-face {
  font-style: normal;
  font-weight: 700;
  src: local("IBMPlexSans-Bold"), url(../fonts/ibmplexsans/IBMPlexSans-Bold.ttf);
  font-family: "IBMPlexSans";
}
@font-face {
  font-style: italic;
  font-weight: 400;
  src: local("IBMPlexSans-Italic"), url(../fonts/ibmplexsans/IBMPlexSans-Italic.ttf);
  font-family: "IBMPlexSans";
}
@font-face {
  font-style: italic;
  font-weight: 700;
  src: local("IBMPlexSans-BoldItalic"), url(../fonts/ibmplexsans/IBMPlexSans-BoldItalic.ttf);
  font-family: "IBMPlexSans";
}
@font-face {
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/zupicons/zupicons.ttf?le6qgo") format("truetype"), url("../../fonts/zupicons/zupicons.woff?le6qgo") format("woff");
  font-family: "zupicons";
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "zupicons" !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-question:before {
  content: "\eb6e";
}

.icon-arrows-vertical:before {
  content: "\eb6d";
}

.icon-serial-number:before {
  content: "\eb6c";
}

.icon-union:before {
  content: "\eb69";
}

.icon-facebook:before {
  content: "\eb67";
}

.icon-flickr:before {
  content: "\eb68";
}

.icon-linkedin:before {
  content: "\eb6a";
}

.icon-pinterest:before {
  content: "\eb6b";
}

.icon-apple-pay:before {
  content: "\e900";
}

.icon-cc-amazon-pay:before {
  content: "\e901";
}

.icon-cc-amex:before {
  content: "\e902";
}

.icon-cc-apple-pay:before {
  content: "\e903";
}

.icon-cc-dc:before {
  content: "\e904";
}

.icon-cc-discover:before {
  content: "\e905";
}

.icon-cc-jbc:before {
  content: "\e906";
}

.icon-cc-mastercard:before {
  content: "\e907";
}

.icon-cc-paypal:before {
  content: "\e908";
}

.icon-cc-stripe:before {
  content: "\e909";
}

.icon-cc-visa:before {
  content: "\e90a";
}

.icon-dollar:before {
  content: "\e90b";
}

.icon-euro:before {
  content: "\e90c";
}

.icon-lira:before {
  content: "\e90d";
}

.icon-rupee:before {
  content: "\e90e";
}

.icon-yen:before {
  content: "\e90f";
}

.icon-figma:before {
  content: "\e910";
}

.icon-yahoo:before {
  content: "\e911";
}

.icon-amazon:before {
  content: "\e912";
}

.icon-app-store:before {
  content: "\e913";
}

.icon-apple:before {
  content: "\e914";
}

.icon-chrome:before {
  content: "\e915";
}

.icon-google-drive:before {
  content: "\e916";
}

.icon-google-play:before {
  content: "\e917";
}

.icon-google:before {
  content: "\e918";
}

.icon-itunes:before {
  content: "\e919";
}

.icon-microsoft:before {
  content: "\e91a";
}

.icon-paypal:before {
  content: "\e91b";
}

.icon-windows:before {
  content: "\e91c";
}

.icon-google-plus-alt:before {
  content: "\e91d";
}

.icon-blog:before {
  content: "\e91e";
}

.icon-facebook-messenger:before {
  content: "\e91f";
}

.icon-facebook-square:before {
  content: "\e920";
}

.icon-facebook-circle:before {
  content: "\e921";
}

.icon-google-plus:before {
  content: "\e922";
}

.icon-instagram:before {
  content: "\e923";
}

.icon-pinterest-circle:before {
  content: "\e924";
}

.icon-sms:before {
  content: "\e925";
}

.icon-snapchat:before {
  content: "\e926";
}

.icon-twitter:before {
  content: "\e927";
}

.icon-vimeo:before {
  content: "\e928";
}

.icon-whatsapp:before {
  content: "\e929";
}

.icon-youtube:before {
  content: "\e92a";
}

.icon-user-alt-slash:before {
  content: "\e92b";
}

.icon-user-alt:before {
  content: "\e92c";
}

.icon-user-edit:before {
  content: "\e92d";
}

.icon-user-plus:before {
  content: "\e92e";
}

.icon-user:before {
  content: "\e92f";
}

.icon-copy-alt:before {
  content: "\e930";
}

.icon-copy:before {
  content: "\e931";
}

.icon-image:before {
  content: "\e932";
}

.icon-images:before {
  content: "\e933";
}

.icon-js:before {
  content: "\e934";
}

.icon-folder-download:before {
  content: "\e935";
}

.icon-folder-minus:before {
  content: "\e936";
}

.icon-folder-open:before {
  content: "\e937";
}

.icon-folder-plus:before {
  content: "\e938";
}

.icon-folder-upload:before {
  content: "\e939";
}

.icon-folder:before {
  content: "\e93a";
}

.icon-folders:before {
  content: "\e93b";
}

.icon-file-alt:before {
  content: "\e93c";
}

.icon-file-archive:before {
  content: "\e93d";
}

.icon-file-code:before {
  content: "\e93e";
}

.icon-file-contracts:before {
  content: "\e93f";
}

.icon-file-download:before {
  content: "\e940";
}

.icon-file-edit:before {
  content: "\e941";
}

.icon-file-export:before {
  content: "\e942";
}

.icon-file-image:before {
  content: "\e943";
}

.icon-file-import:before {
  content: "\e944";
}

.icon-file-minus:before {
  content: "\e945";
}

.icon-file-music:before {
  content: "\e946";
}

.icon-file-pdf:before {
  content: "\e947";
}

.icon-file-plus:before {
  content: "\e948";
}

.icon-file-powerpoint:before {
  content: "\e949";
}

.icon-file-search:before {
  content: "\e94a";
}

.icon-file-upload:before {
  content: "\e94b";
}

.icon-file-user:before {
  content: "\e94c";
}

.icon-file-video:before {
  content: "\e94d";
}

.icon-file-word:before {
  content: "\e94e";
}

.icon-file:before {
  content: "\e94f";
}

.icon-angle-double-down:before {
  content: "\e950";
}

.icon-angle-double-left:before {
  content: "\e951";
}

.icon-angle-double-right:before {
  content: "\e952";
}

.icon-angle-double-up:before {
  content: "\e953";
}

.icon-angle-down:before {
  content: "\e954";
}

.icon-angle-left:before {
  content: "\e955";
}

.icon-angle-right:before {
  content: "\e956";
}

.icon-angle-up:before {
  content: "\e957";
}

.icon-arrow-alt-circle-down:before {
  content: "\e958";
}

.icon-arrow-alt-circle-left:before {
  content: "\e959";
}

.icon-arrow-alt-circle-right:before {
  content: "\e95a";
}

.icon-arrow-alt-circle-up:before {
  content: "\e95b";
}

.icon-arrow-alt-down:before {
  content: "\e95c";
}

.icon-arrow-alt-left:before {
  content: "\e95d";
}

.icon-arrow-alt-right:before {
  content: "\e95e";
}

.icon-arrow-alt-up:before {
  content: "\e95f";
}

.icon-arrow-alt-from-top:before {
  content: "\e960";
}

.icon-arrow-alt-from-right:before {
  content: "\e961";
}

.icon-arrow-alt-from-left:before {
  content: "\e962";
}

.icon-arrow-alt-from-bottom:before {
  content: "\e963";
}

.icon-arrow-alt-square-down:before {
  content: "\e964";
}

.icon-arrow-alt-square-left:before {
  content: "\e965";
}

.icon-arrow-alt-square-right:before {
  content: "\e966";
}

.icon-arrow-alt-square-top:before {
  content: "\e967";
}

.icon-arrow-circle-down:before {
  content: "\e968";
}

.icon-arrow-circle-left:before {
  content: "\e969";
}

.icon-arrow-circle-right:before {
  content: "\e96a";
}

.icon-arrow-circle-top:before {
  content: "\e96b";
}

.icon-arrow-down:before {
  content: "\e96c";
}

.icon-arrow-down-left:before {
  content: "\e96d";
}

.icon-arrow-down-right:before {
  content: "\e96e";
}

.icon-arrow-left:before {
  content: "\e96f";
}

.icon-arrow-right:before {
  content: "\e970";
}

.icon-arrow-up-left:before {
  content: "\e971";
}

.icon-arrow-up-right:before {
  content: "\e972";
}

.icon-arrow-up:before {
  content: "\e973";
}

.icon-caret-circle-alt-down:before {
  content: "\e974";
}

.icon-caret-circle-alt-left:before {
  content: "\e975";
}

.icon-caret-circle-alt-right:before {
  content: "\e976";
}

.icon-caret-circle-alt-up:before {
  content: "\e977";
}

.icon-caret-circle-down:before {
  content: "\e978";
}

.icon-caret-circle-left:before {
  content: "\e979";
}

.icon-caret-circle-right:before {
  content: "\e97a";
}

.icon-caret-circle-up:before {
  content: "\e97b";
}

.icon-caret-down:before {
  content: "\e97c";
}

.icon-caret-left:before {
  content: "\e97d";
}

.icon-caret-right:before {
  content: "\e97e";
}

.icon-caret-up:before {
  content: "\e97f";
}

.icon-chevron-circle-alt-down:before {
  content: "\e980";
}

.icon-chevron-circle-alt-left:before {
  content: "\e981";
}

.icon-chevron-circle-alt-right:before {
  content: "\e982";
}

.icon-chevron-circle-alt-up:before {
  content: "\e983";
}

.icon-chevron-circle-down:before {
  content: "\e984";
}

.icon-chevron-circle-left:before {
  content: "\e985";
}

.icon-chevron-circle-right:before {
  content: "\e986";
}

.icon-chevron-circle-up:before {
  content: "\e987";
}

.icon-basketball:before {
  content: "\e988";
}

.icon-accessible:before {
  content: "\e989";
}

.icon-adjust:before {
  content: "\e98a";
}

.icon-adress-book:before {
  content: "\e98b";
}

.icon-adress-card:before {
  content: "\e98c";
}

.icon-alarm-clock:before {
  content: "\e98d";
}

.icon-alarm-plus:before {
  content: "\e98e";
}

.icon-album:before {
  content: "\e98f";
}

.icon-alien:before {
  content: "\e990";
}

.icon-align-center:before {
  content: "\e991";
}

.icon-align-justify:before {
  content: "\e992";
}

.icon-align-left:before {
  content: "\e993";
}

.icon-align-right:before {
  content: "\e994";
}

.icon-allergies:before {
  content: "\e995";
}

.icon-ambulance:before {
  content: "\e996";
}

.icon-analytics-primary:before {
  content: "\e997";
}

.icon-analytics:before {
  content: "\e998";
}

.icon-anchor:before {
  content: "\e999";
}

.icon-angry:before {
  content: "\e99a";
}

.icon-archive:before {
  content: "\e99b";
}

.icon-arrows-primary:before {
  content: "\e99c";
}

.icon-arrows:before {
  content: "\e99d";
}

.icon-at-sign:before {
  content: "\e99e";
}

.icon-atlas:before {
  content: "\e99f";
}

.icon-atom:before {
  content: "\e9a0";
}

.icon-award:before {
  content: "\e9a1";
}

.icon-axe:before {
  content: "\e9a2";
}

.icon-baby:before {
  content: "\e9a3";
}

.icon-backpack:before {
  content: "\e9a4";
}

.icon-backward:before {
  content: "\e9a5";
}

.icon-badge-dollar:before {
  content: "\e9a6";
}

.icon-badge-percent:before {
  content: "\e9a7";
}

.icon-bag-shopping:before {
  content: "\e9a8";
}

.icon-ballot:before {
  content: "\e9a9";
}

.icon-ban:before {
  content: "\e9aa";
}

.icon-barcode:before {
  content: "\e9ab";
}

.icon-bars:before {
  content: "\e9ac";
}

.icon-bat:before {
  content: "\e9ad";
}

.icon-battery-empty:before {
  content: "\e9ae";
}

.icon-battery-full:before {
  content: "\e9af";
}

.icon-battery-half:before {
  content: "\e9b0";
}

.icon-battery-slash:before {
  content: "\e9b1";
}

.icon-battery-three-quarters:before {
  content: "\e9b2";
}

.icon-bed:before {
  content: "\e9b3";
}

.icon-beer:before {
  content: "\e9b4";
}

.icon-bell:before {
  content: "\e9b5";
}

.icon-biohazard:before {
  content: "\e9b6";
}

.icon-blanket:before {
  content: "\e9b7";
}

.icon-bluetooth:before {
  content: "\e9b8";
}

.icon-bolt:before {
  content: "\e9b9";
}

.icon-book-alt:before {
  content: "\e9ba";
}

.icon-book-open:before {
  content: "\e9bb";
}

.icon-book:before {
  content: "\e9bc";
}

.icon-bookmark:before {
  content: "\e9bd";
}

.icon-box-fragile:before {
  content: "\e9be";
}

.icon-box-open:before {
  content: "\e9bf";
}

.icon-box-up:before {
  content: "\e9c0";
}

.icon-box:before {
  content: "\e9c1";
}

.icon-brackets-curly:before {
  content: "\e9c2";
}

.icon-brackets:before {
  content: "\e9c3";
}

.icon-brain:before {
  content: "\e9c4";
}

.icon-briefcase-medical:before {
  content: "\e9c5";
}

.icon-briefcase:before {
  content: "\e9c6";
}

.icon-bring-forward:before {
  content: "\e9c7";
}

.icon-broadcast-tower:before {
  content: "\e9c8";
}

.icon-browser:before {
  content: "\e9c9";
}

.icon-brush:before {
  content: "\e9ca";
}

.icon-buffer:before {
  content: "\e9cb";
}

.icon-building:before {
  content: "\e9cc";
}

.icon-bullhorn:before {
  content: "\e9cd";
}

.icon-bullseye:before {
  content: "\e9ce";
}

.icon-burn:before {
  content: "\e9cf";
}

.icon-bus-alt:before {
  content: "\e9d0";
}

.icon-bus:before {
  content: "\e9d1";
}

.icon-bycicle:before {
  content: "\e9d2";
}

.icon-calculator:before {
  content: "\e9d3";
}

.icon-calendar-alt:before {
  content: "\e9d4";
}

.icon-calendar-check:before {
  content: "\e9d5";
}

.icon-calendar-day:before {
  content: "\e9d6";
}

.icon-calendar-edit:before {
  content: "\e9d7";
}

.icon-calendar-minus:before {
  content: "\e9d8";
}

.icon-calendar-plus:before {
  content: "\e9d9";
}

.icon-calendar-star:before {
  content: "\e9da";
}

.icon-calendar-times:before {
  content: "\e9db";
}

.icon-calendar:before {
  content: "\e9dc";
}

.icon-camcorder:before {
  content: "\e9dd";
}

.icon-camera-movie:before {
  content: "\e9de";
}

.icon-camera:before {
  content: "\e9df";
}

.icon-campfire:before {
  content: "\e9e0";
}

.icon-campground:before {
  content: "\e9e1";
}

.icon-capsules:before {
  content: "\e9e2";
}

.icon-car-alt:before {
  content: "\e9e3";
}

.icon-car-battery:before {
  content: "\e9e4";
}

.icon-car-garage:before {
  content: "\e9e5";
}

.icon-car-mechanics:before {
  content: "\e9e6";
}

.icon-car-side:before {
  content: "\e9e7";
}

.icon-car-wash:before {
  content: "\e9e8";
}

.icon-car:before {
  content: "\e9e9";
}

.icon-caravan:before {
  content: "\e9ea";
}

.icon-cars:before {
  content: "\e9eb";
}

.icon-cart-arrow-down:before {
  content: "\e9ec";
}

.icon-cart-plus:before {
  content: "\e9ed";
}

.icon-chair:before {
  content: "\e9ee";
}

.icon-charging-station:before {
  content: "\e9ef";
}

.icon-dashboard:before {
  content: "\e9f0";
}

.icon-chart-area:before {
  content: "\e9f1";
}

.icon-chart-bar:before {
  content: "\e9f2";
}

.icon-chart-line-down:before {
  content: "\e9f3";
}

.icon-chart-line:before {
  content: "\e9f4";
}

.icon-chart-network:before {
  content: "\e9f5";
}

.icon-chart-pie-alt:before {
  content: "\e9f6";
}

.icon-chart-pie:before {
  content: "\e9f7";
}

.icon-chart-scatter:before {
  content: "\e9f8";
}

.icon-check-circle:before {
  content: "\e9f9";
}

.icon-check-double:before {
  content: "\e9fa";
}

.icon-check-square-alt:before {
  content: "\e9fb";
}

.icon-check-square:before {
  content: "\e9fc";
}

.icon-check:before {
  content: "\e9fd";
}

.icon-chromecast:before {
  content: "\e9fe";
}

.icon-circle:before {
  content: "\e9ff";
}

.icon-clinic-medical:before {
  content: "\ea00";
}

.icon-clipboard-check:before {
  content: "\ea01";
}

.icon-clipboard-list:before {
  content: "\ea02";
}

.icon-clipboard:before {
  content: "\ea03";
}

.icon-clock:before {
  content: "\ea04";
}

.icon-clone:before {
  content: "\ea05";
}

.icon-close:before {
  content: "\ea06";
}

.icon-close-alt:before {
  content: "\eb66";
}

.icon-cloud-drizzle:before {
  content: "\ea07";
}

.icon-cloud-shower:before {
  content: "\ea08";
}

.icon-cloud-snow:before {
  content: "\ea09";
}

.icon-cloud:before {
  content: "\ea0a";
}

.icon-code:before {
  content: "\ea0b";
}

.icon-coffee:before {
  content: "\ea0c";
}

.icon-cog-alt:before {
  content: "\ea0d";
}

.icon-cog:before {
  content: "\ea0e";
}

.icon-coins:before {
  content: "\ea0f";
}

.icon-comment-alt:before {
  content: "\ea10";
}

.icon-comment-s-alt:before {
  content: "\ea11";
}

.icon-comment-s:before {
  content: "\ea12";
}

.icon-comment:before {
  content: "\ea13";
}

.icon-comments-alt:before {
  content: "\ea14";
}

.icon-comments-s-alt:before {
  content: "\ea15";
}

.icon-comments-s:before {
  content: "\ea16";
}

.icon-comments:before {
  content: "\ea17";
}

.icon-compress:before {
  content: "\ea18";
}

.icon-cookie:before {
  content: "\ea19";
}

.icon-copyright:before {
  content: "\ea1a";
}

.icon-creative-commons:before {
  content: "\ea1b";
}

.icon-credit-card-alt:before {
  content: "\ea1c";
}

.icon-credit-card:before {
  content: "\ea1d";
}

.icon-crop:before {
  content: "\ea1e";
}

.icon-crosshairs:before {
  content: "\ea1f";
}

.icon-database-alt:before {
  content: "\ea20";
}

.icon-database:before {
  content: "\ea21";
}

.icon-dolly:before {
  content: "\ea22";
}

.icon-download-alt:before {
  content: "\ea23";
}

.icon-download-cloud:before {
  content: "\ea24";
}

.icon-download:before {
  content: "\ea25";
}

.icon-edit-alt:before {
  content: "\ea26";
}

.icon-edit:before {
  content: "\ea27";
}

.icon-envelope-open:before {
  content: "\ea28";
}

.icon-envelope:before {
  content: "\ea29";
}

.icon-eraser:before {
  content: "\ea2a";
}

.icon-exchange:before {
  content: "\ea2b";
}

.icon-exclamation-triangle-alt:before {
  content: "\ea2c";
}

.icon-exclamation-triangle:before {
  content: "\ea2d";
}

.icon-expand-arrows:before {
  content: "\ea2e";
}

.icon-expand:before {
  content: "\ea2f";
}

.icon-external-link:before {
  content: "\ea30";
}

.icon-eye-slash:before {
  content: "\ea31";
}

.icon-eye:before {
  content: "\ea32";
}

.icon-fast-backward:before {
  content: "\ea33";
}

.icon-fast-forward:before {
  content: "\ea34";
}

.icon-fax:before {
  content: "\ea35";
}

.icon-female:before {
  content: "\ea36";
}

.icon-film:before {
  content: "\ea37";
}

.icon-filter:before {
  content: "\ea38";
}

.icon-fingerprint:before {
  content: "\ea39";
}

.icon-first-aid:before {
  content: "\ea3a";
}

.icon-flag:before {
  content: "\ea3b";
}

.icon-font-case:before {
  content: "\ea3c";
}

.icon-fonts:before {
  content: "\ea3d";
}

.icon-forward-1:before {
  content: "\ea3e";
}

.icon-forward:before {
  content: "\ea3f";
}

.icon-gas-pump-slash:before {
  content: "\ea40";
}

.icon-gas-pump:before {
  content: "\ea41";
}

.icon-glass-alt:before {
  content: "\ea42";
}

.icon-glass:before {
  content: "\ea43";
}

.icon-globe:before {
  content: "\ea44";
}

.icon-graduation:before {
  content: "\ea45";
}

.icon-greater-than:before {
  content: "\ea46";
}

.icon-hand-paper-alt:before {
  content: "\ea47";
}

.icon-hand-paper:before {
  content: "\ea48";
}

.icon-hand-point-alt:before {
  content: "\ea49";
}

.icon-hand-point:before {
  content: "\ea4a";
}

.icon-hand-pointer:before {
  content: "\ea4b";
}

.icon-head-side:before {
  content: "\ea4c";
}

.icon-heart-rate:before {
  content: "\ea4d";
}

.icon-heart:before {
  content: "\ea4e";
}

.icon-heat:before {
  content: "\ea4f";
}

.icon-history:before {
  content: "\ea50";
}

.icon-holding-support:before {
  content: "\ea51";
}

.icon-home-alt:before {
  content: "\ea52";
}

.icon-home-damage:before {
  content: "\ea53";
}

.icon-home-filled:before {
  content: "\ea54";
}

.icon-home:before {
  content: "\ea55";
}

.icon-hospital-symbol:before {
  content: "\ea56";
}

.icon-hourglass:before {
  content: "\ea57";
}

.icon-id-card-alt:before {
  content: "\ea58";
}

.icon-id-card:before {
  content: "\ea59";
}

.icon-image-alt:before {
  content: "\ea5a";
}

.icon-inbox-in:before {
  content: "\ea5b";
}

.icon-inbox-out:before {
  content: "\ea5c";
}

.icon-inbox:before {
  content: "\ea5d";
}

.icon-industry-alt:before {
  content: "\ea5e";
}

.icon-industry:before {
  content: "\ea5f";
}

.icon-info-1:before {
  content: "\ea60";
}

.icon-info-alt:before {
  content: "\ea61";
}

.icon-info:before {
  content: "\ea62";
}

.icon-joystick:before {
  content: "\ea63";
}

.icon-jug:before {
  content: "\ea64";
}

.icon-key-alt:before {
  content: "\ea65";
}

.icon-key:before {
  content: "\ea66";
}

.icon-keyboard:before {
  content: "\ea67";
}

.icon-keynote:before {
  content: "\ea68";
}

.icon-knife:before {
  content: "\ea69";
}

.icon-landmark:before {
  content: "\ea6a";
}

.icon-language-alt:before {
  content: "\ea6b";
}

.icon-language:before {
  content: "\ea6c";
}

.icon-laptop:before {
  content: "\ea6d";
}

.icon-layer-group:before {
  content: "\ea6e";
}

.icon-layer-minus:before {
  content: "\ea6f";
}

.icon-leaf:before {
  content: "\ea70";
}

.icon-less-than:before {
  content: "\ea71";
}

.icon-lightbulb-exclamation:before {
  content: "\ea72";
}

.icon-lightbulb:before {
  content: "\ea73";
}

.icon-line-columns:before {
  content: "\ea74";
}

.icon-line-height:before {
  content: "\ea75";
}

.icon-link:before {
  content: "\ea76";
}

.icon-list-music:before {
  content: "\ea77";
}

.icon-list-ol:before {
  content: "\ea78";
}

.icon-list:before {
  content: "\ea79";
}

.icon-location-arrow:before {
  content: "\ea7a";
}

.icon-location-circle:before {
  content: "\ea7b";
}

.icon-location:before {
  content: "\ea7c";
}

.icon-lock-alt:before {
  content: "\ea7d";
}

.icon-lock-open-alt:before {
  content: "\ea7e";
}

.icon-lock-open:before {
  content: "\ea7f";
}

.icon-lock:before {
  content: "\ea80";
}

.icon-luggage-cart:before {
  content: "\ea81";
}

.icon-magnet:before {
  content: "\ea82";
}

.icon-mailbox:before {
  content: "\ea83";
}

.icon-male:before {
  content: "\ea84";
}

.icon-map-marker-minus:before {
  content: "\ea85";
}

.icon-map-marker-plus:before {
  content: "\ea86";
}

.icon-map-marker:before {
  content: "\ea87";
}

.icon-map-pin:before {
  content: "\ea88";
}

.icon-map-signs:before {
  content: "\ea89";
}

.icon-map:before {
  content: "\ea8a";
}

.icon-medal:before {
  content: "\ea8b";
}

.icon-megaphone:before {
  content: "\ea8c";
}

.icon-microphone-alt-slash:before {
  content: "\ea8d";
}

.icon-microphone-alt:before {
  content: "\ea8e";
}

.icon-microphone-slash:before {
  content: "\ea8f";
}

.icon-microphone:before {
  content: "\ea90";
}

.icon-microwave:before {
  content: "\ea91";
}

.icon-minus-circle:before {
  content: "\ea92";
}

.icon-minus:before {
  content: "\ea93";
}

.icon-mobile-alt:before {
  content: "\ea94";
}

.icon-mobile:before {
  content: "\ea95";
}

.icon-money-check:before {
  content: "\ea96";
}

.icon-money:before {
  content: "\ea97";
}

.icon-moon:before {
  content: "\ea98";
}

.icon-mountain:before {
  content: "\ea99";
}

.icon-mouse-alt:before {
  content: "\ea9a";
}

.icon-mouse-pointer-alt:before {
  content: "\ea9b";
}

.icon-mouse-pointer:before {
  content: "\ea9c";
}

.icon-mouse:before {
  content: "\ea9d";
}

.icon-mug:before {
  content: "\ea9e";
}

.icon-music:before {
  content: "\ea9f";
}

.icon-neuter:before {
  content: "\eaa0";
}

.icon-newspaper:before {
  content: "\eaa1";
}

.icon-not-equal:before {
  content: "\eaa2";
}

.icon-notes-medical:before {
  content: "\eaa3";
}

.icon-oil-can:before {
  content: "\eaa4";
}

.icon-oil-temp:before {
  content: "\eaa5";
}

.icon-omega:before {
  content: "\eaa6";
}

.icon-outdent:before {
  content: "\eaa7";
}

.icon-outlet:before {
  content: "\eaa8";
}

.icon-overline:before {
  content: "\eaa9";
}

.icon-page-break:before {
  content: "\eaaa";
}

.icon-paint-brush:before {
  content: "\eaab";
}

.icon-palette:before {
  content: "\eaac";
}

.icon-paperclip:before {
  content: "\eaad";
}

.icon-paperplane:before {
  content: "\eaae";
}

.icon-paragraph:before {
  content: "\eaaf";
}

.icon-parking:before {
  content: "\eab0";
}

.icon-passport:before {
  content: "\eab1";
}

.icon-paste:before {
  content: "\eab2";
}

.icon-pause-circle:before {
  content: "\eab3";
}

.icon-pause:before {
  content: "\eab4";
}

.icon-peace:before {
  content: "\eab5";
}

.icon-pen:before {
  content: "\eab6";
}

.icon-pencil:before {
  content: "\eab7";
}

.icon-percent:before {
  content: "\eab8";
}

.icon-phone-plus:before {
  content: "\eab9";
}

.icon-phone-rotary:before {
  content: "\eaba";
}

.icon-phone-slash:before {
  content: "\eabb";
}

.icon-phone-square:before {
  content: "\eabc";
}

.icon-phone:before {
  content: "\eabd";
}

.icon-pi:before {
  content: "\eabe";
}

.icon-pills:before {
  content: "\eabf";
}

.icon-plane-arrival:before {
  content: "\eac0";
}

.icon-plane-departure:before {
  content: "\eac1";
}

.icon-plane:before {
  content: "\eac2";
}

.icon-play-alt:before {
  content: "\eac3";
}

.icon-play-circle:before {
  content: "\eac4";
}

.icon-play:before {
  content: "\eac5";
}

.icon-plug:before {
  content: "\eac6";
}

.icon-plus-1:before {
  content: "\eac7";
}

.icon-plus-circle:before {
  content: "\eac8";
}

.icon-plus:before {
  content: "\eac9";
}

.icon-podcast:before {
  content: "\eaca";
}

.icon-portrait:before {
  content: "\eacb";
}

.icon-pound:before {
  content: "\eacc";
}

.icon-power-off:before {
  content: "\eacd";
}

.icon-presentation:before {
  content: "\eace";
}

.icon-print-1:before {
  content: "\eacf";
}

.icon-print:before {
  content: "\ead0";
}

.icon-puzzle-piece:before {
  content: "\ead1";
}

.icon-qr-code:before {
  content: "\ead2";
}

.icon-question-circle-1:before {
  content: "\ead3";
}

.icon-question-circle:before {
  content: "\ead4";
}

.icon-quote-left:before {
  content: "\ead5";
}

.icon-quote-right:before {
  content: "\ead6";
}

.icon-radiation-alt:before {
  content: "\ead7";
}

.icon-radiation:before {
  content: "\ead8";
}

.icon-radio:before {
  content: "\ead9";
}

.icon-random:before {
  content: "\eada";
}

.icon-react:before {
  content: "\eadb";
}

.icon-recycle:before {
  content: "\eadc";
}

.icon-redo:before {
  content: "\eadd";
}

.icon-repeat:before {
  content: "\eade";
}

.icon-reply-all:before {
  content: "\eadf";
}

.icon-reply:before {
  content: "\eae0";
}

.icon-restroom:before {
  content: "\eae1";
}

.icon-retweet:before {
  content: "\eae2";
}

.icon-router:before {
  content: "\eae3";
}

.icon-rss:before {
  content: "\eae4";
}

.icon-ruble:before {
  content: "\eae5";
}

.icon-running:before {
  content: "\eae6";
}

.icon-sack-dollar:before {
  content: "\eae7";
}

.icon-satellite-dish:before {
  content: "\eae8";
}

.icon-save-1:before {
  content: "\eae9";
}

.icon-save:before {
  content: "\eaea";
}

.icon-scalpel:before {
  content: "\eaeb";
}

.icon-scanner-image:before {
  content: "\eaec";
}

.icon-scroll:before {
  content: "\eaed";
}

.icon-sd-card:before {
  content: "\eaee";
}

.icon-search-minus:before {
  content: "\eaef";
}

.icon-search-plus:before {
  content: "\eaf0";
}

.icon-search:before {
  content: "\eaf1";
}

.icon-sensor-alert:before {
  content: "\eaf2";
}

.icon-sensor:before {
  content: "\eaf3";
}

.icon-server:before {
  content: "\eaf4";
}

.icon-share-1:before {
  content: "\eaf5";
}

.icon-share-all-1:before {
  content: "\eaf6";
}

.icon-share-all:before {
  content: "\eaf7";
}

.icon-share-alt-1:before {
  content: "\eaf8";
}

.icon-share-alt:before {
  content: "\eaf9";
}

.icon-share:before {
  content: "\eafa";
}

.icon-shield-alt:before {
  content: "\eafb";
}

.icon-shield-check:before {
  content: "\eafc";
}

.icon-shield:before {
  content: "\eafd";
}

.icon-shopping-bag:before {
  content: "\eafe";
}

.icon-shopping-basket:before {
  content: "\eaff";
}

.icon-shopping-cart:before {
  content: "\eb00";
}

.icon-showel:before {
  content: "\eb01";
}

.icon-shredder:before {
  content: "\eb02";
}

.icon-sigma:before {
  content: "\eb03";
}

.icon-sign-in-1:before {
  content: "\eb04";
}

.icon-sign-in:before {
  content: "\eb05";
}

.icon-sign-out-1:before {
  content: "\eb06";
}

.icon-sign-out:before {
  content: "\eb07";
}

.icon-signal-1:before {
  content: "\eb08";
}

.icon-signal-slash:before {
  content: "\eb09";
}

.icon-signal-stream:before {
  content: "\eb0a";
}

.icon-signal:before {
  content: "\eb0b";
}

.icon-sim-card:before {
  content: "\eb0c";
}

.icon-siren:before {
  content: "\eb0d";
}

.icon-sitemap-1:before {
  content: "\eb0e";
}

.icon-sitemap:before {
  content: "\eb0f";
}

.icon-ski-jump:before {
  content: "\eb10";
}

.icon-skull:before {
  content: "\eb11";
}

.icon-sliders-h:before {
  content: "\eb12";
}

.icon-sliders-v:before {
  content: "\eb13";
}

.icon-smile:before {
  content: "\eb14";
}

.icon-smoking:before {
  content: "\eb15";
}

.icon-snooze:before {
  content: "\eb16";
}

.icon-snowflake:before {
  content: "\eb17";
}

.icon-solarpanel:before {
  content: "\eb18";
}

.icon-sort-alpha:before {
  content: "\eb19";
}

.icon-sort-alt:before {
  content: "\eb1a";
}

.icon-sort-amount-down:before {
  content: "\eb1b";
}

.icon-sort-amount-up:before {
  content: "\eb1c";
}

.icon-sort-aplha-up:before {
  content: "\eb1d";
}

.icon-sort:before {
  content: "\eb1e";
}

.icon-spell-check:before {
  content: "\eb1f";
}

.icon-star-filled:before {
  content: "\eb20";
}

.icon-star:before {
  content: "\eb21";
}

.icon-step-backwards:before {
  content: "\eb22";
}

.icon-step-forwards:before {
  content: "\eb23";
}

.icon-sticky-note:before {
  content: "\eb24";
}

.icon-stop:before {
  content: "\eb25";
}

.icon-store:before {
  content: "\eb26";
}

.icon-stream:before {
  content: "\eb27";
}

.icon-subway-1:before {
  content: "\eb28";
}

.icon-subway:before {
  content: "\eb29";
}

.icon-suitcase-1:before {
  content: "\eb2a";
}

.icon-suitcase:before {
  content: "\eb2b";
}

.icon-sun:before {
  content: "\eb2c";
}

.icon-sunglasses:before {
  content: "\eb2d";
}

.icon-swimmer:before {
  content: "\eb2e";
}

.icon-sync:before {
  content: "\eb2f";
}

.icon-table:before {
  content: "\eb30";
}

.icon-tablet-alt:before {
  content: "\eb31";
}

.icon-tablet:before {
  content: "\eb32";
}

.icon-tachometer:before {
  content: "\eb33";
}

.icon-tag:before {
  content: "\eb34";
}

.icon-tags:before {
  content: "\eb35";
}

.icon-tally:before {
  content: "\eb36";
}

.icon-tasks:before {
  content: "\eb37";
}

.icon-taxi:before {
  content: "\eb38";
}

.icon-temperature:before {
  content: "\eb39";
}

.icon-terminal:before {
  content: "\eb3a";
}

.icon-thermometer:before {
  content: "\eb3b";
}

.icon-thumbs-down:before {
  content: "\eb3c";
}

.icon-thumbs-up:before {
  content: "\eb3d";
}

.icon-thumbtack:before {
  content: "\eb3e";
}

.icon-thunderstorm-1:before {
  content: "\eb3f";
}

.icon-thunderstorm:before {
  content: "\eb40";
}

.icon-ticket:before {
  content: "\eb41";
}

.icon-trash-alt:before {
  content: "\eb42";
}

.icon-trash-restore:before {
  content: "\eb43";
}

.icon-trash:before {
  content: "\eb44";
}

.icon-truck:before {
  content: "\eb45";
}

.icon-tv:before {
  content: "\eb46";
}

.icon-typewriter:before {
  content: "\eb47";
}

.icon-umbrella:before {
  content: "\eb48";
}

.icon-undo:before {
  content: "\eb49";
}

.icon-university:before {
  content: "\eb4a";
}

.icon-unlink:before {
  content: "\eb4b";
}

.icon-unlock-1:before {
  content: "\eb4c";
}

.icon-unlock:before {
  content: "\eb4d";
}

.icon-upload-1:before {
  content: "\eb4e";
}

.icon-upload-cloud:before {
  content: "\eb4f";
}

.icon-upload:before {
  content: "\eb50";
}

.icon-usb-drive:before {
  content: "\eb51";
}

.icon-usb:before {
  content: "\eb52";
}

.icon-utensiles:before {
  content: "\eb53";
}

.icon-video-slash:before {
  content: "\eb54";
}

.icon-video:before {
  content: "\eb55";
}

.icon-voice-slash:before {
  content: "\eb56";
}

.icon-voice:before {
  content: "\eb57";
}

.icon-voicemail:before {
  content: "\eb58";
}

.icon-volume-mute:before {
  content: "\eb59";
}

.icon-walking:before {
  content: "\eb5a";
}

.icon-wallet:before {
  content: "\eb5b";
}

.icon-warehouse:before {
  content: "\eb5c";
}

.icon-waveform:before {
  content: "\eb5d";
}

.icon-webcam:before {
  content: "\eb5e";
}

.icon-weight:before {
  content: "\eb5f";
}

.icon-wheelchair:before {
  content: "\eb60";
}

.icon-wifi:before {
  content: "\eb61";
}

.icon-wind:before {
  content: "\eb62";
}

.icon-window:before {
  content: "\eb63";
}

.icon-wrench:before {
  content: "\eb64";
}

.icon-yin-yang:before {
  content: "\eb65";
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-size: 16px;
}

:root {
  color-scheme: light;
}

@supports (color-scheme: only light) {
  :root {
    color-scheme: only light;
  }
}
/**
 * @param {number} $amount The amount of elevation, e.g. `100`.
 */
/*
 * Alternate buttons
 */
.btn-primary {
  color: #ffffff;
  background-color: #e4032e;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #e48194;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #e43557;
  outline: 0;
  box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #d1dae1;
  color: #212525;
}
.btn-primary:not(:disabled):active, .btn-primary:not(:disabled).active, .show > .btn-primary.dropdown-toggle {
  background-color: #e43557;
  color: #ffffff;
}

.btn-secondary {
  color: #e4032e;
  background-color: #ececed;
}
.btn-secondary:hover {
  color: #e4032e;
  background-color: #d1dae1;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #e4032e;
  background-color: #c4c8c8;
  outline: 0;
  box-shadow: none;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #ececed;
  color: #c4c8c8;
}
.btn-secondary:not(:disabled):active, .btn-secondary:not(:disabled).active, .show > .btn-secondary.dropdown-toggle {
  background-color: #c4c8c8;
  color: #e4032e;
}

.btn-text {
  color: #e4032e;
  background-color: transparent;
}
.btn-text:hover {
  color: #e48194;
  background-color: transparent;
}
.btn-text:focus, .btn-text.focus {
  color: #e43557;
  background-color: transparent;
  outline: 0;
  box-shadow: none;
}
.btn-text.disabled, .btn-text:disabled {
  background-color: transparent;
  color: #c4c8c8;
}
.btn-text:not(:disabled):active, .btn-text:not(:disabled).active, .show > .btn-text.dropdown-toggle {
  background-color: transparent;
  color: #e43557;
}

.btn-light {
  color: #74797c;
  background-color: #ffffff;
  border-color: #74797c;
}
.btn-light:hover {
  color: #212525;
  background-color: #ffffff;
  border-color: #74797c;
}
.btn-light:focus, .btn-light.focus {
  color: #212525;
  background-color: #ffffff;
  outline: 0;
  box-shadow: none;
  border-color: #74797c;
}
.btn-light.disabled, .btn-light:disabled {
  border-color: #74797c;
  background-color: #ffffff;
  color: #c4c8c8;
}
.btn-light:not(:disabled):active, .btn-light:not(:disabled).active, .show > .btn-light.dropdown-toggle {
  background-color: #ffffff;
  color: #212525;
  border-color: #74797c;
}

.btn-text {
  padding: 0;
}

.form-control:disabled, .znd-multiselect:disabled, .form-control[readonly], [readonly].znd-multiselect {
  border-color: #ececed;
  color: #c4c8c8;
}
.form-control:enabled:hover, .znd-multiselect:enabled:hover {
  outline: 0;
  border-color: #74797c;
  background-color: #ececed;
  color: #212525;
}

.custom-checkbox {
  padding-left: 1.75rem;
}
.custom-checkbox .custom-control-label::before {
  left: -1.75rem;
  border-color: #d1dae1;
  border-radius: 2px;
  background-color: #ffffff;
}
.custom-checkbox .custom-control-label::after {
  left: -1.75rem;
  background-size: 100% 100%;
}

.bg-gray-100 {
  background-color: #ececed;
}

.bg-gray-200 {
  background-color: #d1dae1;
}

.bg-gray-300 {
  background-color: #c4c8c8;
}

.bg-gray-400 {
  background-color: #74797c;
}

.bg-gray-500 {
  background-color: #212525;
}

.bg-gray-600 {
  background-color: #6c757d;
}

.bg-gray-700 {
  background-color: #495057;
}

.bg-gray-800 {
  background-color: #343a40;
}

.bg-gray-900 {
  background-color: #212529;
}

.bg-red-100 {
  background-color: #e4b3bd;
}

.bg-red-200 {
  background-color: #e49aa8;
}

.bg-red-300 {
  background-color: #e48194;
}

.bg-red-400 {
  background-color: #e46880;
}

.bg-red-500 {
  background-color: #e44f6b;
}

.bg-red-600 {
  background-color: #e43557;
}

@media (min-width: 576px) {
  .bg-sm-gray-100 {
    background-color: #ececed;
  }
  .bg-sm-gray-200 {
    background-color: #d1dae1;
  }
  .bg-sm-gray-300 {
    background-color: #c4c8c8;
  }
  .bg-sm-gray-400 {
    background-color: #74797c;
  }
  .bg-sm-gray-500 {
    background-color: #212525;
  }
  .bg-sm-gray-600 {
    background-color: #6c757d;
  }
  .bg-sm-gray-700 {
    background-color: #495057;
  }
  .bg-sm-gray-800 {
    background-color: #343a40;
  }
  .bg-sm-gray-900 {
    background-color: #212529;
  }
  .bg-sm-red-100 {
    background-color: #e4b3bd;
  }
  .bg-sm-red-200 {
    background-color: #e49aa8;
  }
  .bg-sm-red-300 {
    background-color: #e48194;
  }
  .bg-sm-red-400 {
    background-color: #e46880;
  }
  .bg-sm-red-500 {
    background-color: #e44f6b;
  }
  .bg-sm-red-600 {
    background-color: #e43557;
  }
}
@media (min-width: 768px) {
  .bg-md-gray-100 {
    background-color: #ececed;
  }
  .bg-md-gray-200 {
    background-color: #d1dae1;
  }
  .bg-md-gray-300 {
    background-color: #c4c8c8;
  }
  .bg-md-gray-400 {
    background-color: #74797c;
  }
  .bg-md-gray-500 {
    background-color: #212525;
  }
  .bg-md-gray-600 {
    background-color: #6c757d;
  }
  .bg-md-gray-700 {
    background-color: #495057;
  }
  .bg-md-gray-800 {
    background-color: #343a40;
  }
  .bg-md-gray-900 {
    background-color: #212529;
  }
  .bg-md-red-100 {
    background-color: #e4b3bd;
  }
  .bg-md-red-200 {
    background-color: #e49aa8;
  }
  .bg-md-red-300 {
    background-color: #e48194;
  }
  .bg-md-red-400 {
    background-color: #e46880;
  }
  .bg-md-red-500 {
    background-color: #e44f6b;
  }
  .bg-md-red-600 {
    background-color: #e43557;
  }
}
@media (min-width: 992px) {
  .bg-lg-gray-100 {
    background-color: #ececed;
  }
  .bg-lg-gray-200 {
    background-color: #d1dae1;
  }
  .bg-lg-gray-300 {
    background-color: #c4c8c8;
  }
  .bg-lg-gray-400 {
    background-color: #74797c;
  }
  .bg-lg-gray-500 {
    background-color: #212525;
  }
  .bg-lg-gray-600 {
    background-color: #6c757d;
  }
  .bg-lg-gray-700 {
    background-color: #495057;
  }
  .bg-lg-gray-800 {
    background-color: #343a40;
  }
  .bg-lg-gray-900 {
    background-color: #212529;
  }
  .bg-lg-red-100 {
    background-color: #e4b3bd;
  }
  .bg-lg-red-200 {
    background-color: #e49aa8;
  }
  .bg-lg-red-300 {
    background-color: #e48194;
  }
  .bg-lg-red-400 {
    background-color: #e46880;
  }
  .bg-lg-red-500 {
    background-color: #e44f6b;
  }
  .bg-lg-red-600 {
    background-color: #e43557;
  }
}
@media (min-width: 1200px) {
  .bg-xl-gray-100 {
    background-color: #ececed;
  }
  .bg-xl-gray-200 {
    background-color: #d1dae1;
  }
  .bg-xl-gray-300 {
    background-color: #c4c8c8;
  }
  .bg-xl-gray-400 {
    background-color: #74797c;
  }
  .bg-xl-gray-500 {
    background-color: #212525;
  }
  .bg-xl-gray-600 {
    background-color: #6c757d;
  }
  .bg-xl-gray-700 {
    background-color: #495057;
  }
  .bg-xl-gray-800 {
    background-color: #343a40;
  }
  .bg-xl-gray-900 {
    background-color: #212529;
  }
  .bg-xl-red-100 {
    background-color: #e4b3bd;
  }
  .bg-xl-red-200 {
    background-color: #e49aa8;
  }
  .bg-xl-red-300 {
    background-color: #e48194;
  }
  .bg-xl-red-400 {
    background-color: #e46880;
  }
  .bg-xl-red-500 {
    background-color: #e44f6b;
  }
  .bg-xl-red-600 {
    background-color: #e43557;
  }
}
@media (min-width: 1440px) {
  .bg-hg-gray-100 {
    background-color: #ececed;
  }
  .bg-hg-gray-200 {
    background-color: #d1dae1;
  }
  .bg-hg-gray-300 {
    background-color: #c4c8c8;
  }
  .bg-hg-gray-400 {
    background-color: #74797c;
  }
  .bg-hg-gray-500 {
    background-color: #212525;
  }
  .bg-hg-gray-600 {
    background-color: #6c757d;
  }
  .bg-hg-gray-700 {
    background-color: #495057;
  }
  .bg-hg-gray-800 {
    background-color: #343a40;
  }
  .bg-hg-gray-900 {
    background-color: #212529;
  }
  .bg-hg-red-100 {
    background-color: #e4b3bd;
  }
  .bg-hg-red-200 {
    background-color: #e49aa8;
  }
  .bg-hg-red-300 {
    background-color: #e48194;
  }
  .bg-hg-red-400 {
    background-color: #e46880;
  }
  .bg-hg-red-500 {
    background-color: #e44f6b;
  }
  .bg-hg-red-600 {
    background-color: #e43557;
  }
}
.elevation-100 {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
}

.elevation-200 {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15) !important;
}

.elevation-300 {
  box-shadow: 0 3px 15px rgba(38, 67, 89, 0.2) !important;
}

.elevation-400 {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15) !important;
}

.elevation-500 {
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.15) !important;
}

.elevation-600 {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15) !important;
}

@media (min-width: 576px) {
  .elevation-sm-100 {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-sm-200 {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-sm-300 {
    box-shadow: 0 3px 15px rgba(38, 67, 89, 0.2) !important;
  }
  .elevation-sm-400 {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-sm-500 {
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-sm-600 {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15) !important;
  }
}
@media (min-width: 768px) {
  .elevation-md-100 {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-md-200 {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-md-300 {
    box-shadow: 0 3px 15px rgba(38, 67, 89, 0.2) !important;
  }
  .elevation-md-400 {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-md-500 {
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-md-600 {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15) !important;
  }
}
@media (min-width: 992px) {
  .elevation-lg-100 {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-lg-200 {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-lg-300 {
    box-shadow: 0 3px 15px rgba(38, 67, 89, 0.2) !important;
  }
  .elevation-lg-400 {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-lg-500 {
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-lg-600 {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15) !important;
  }
}
@media (min-width: 1200px) {
  .elevation-xl-100 {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-xl-200 {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-xl-300 {
    box-shadow: 0 3px 15px rgba(38, 67, 89, 0.2) !important;
  }
  .elevation-xl-400 {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-xl-500 {
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-xl-600 {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15) !important;
  }
}
@media (min-width: 1440px) {
  .elevation-hg-100 {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-hg-200 {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-hg-300 {
    box-shadow: 0 3px 15px rgba(38, 67, 89, 0.2) !important;
  }
  .elevation-hg-400 {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-hg-500 {
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.15) !important;
  }
  .elevation-hg-600 {
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15) !important;
  }
}
.--white-space-normal {
  white-space: normal !important;
}

.transition-fade-enter {
  opacity: 0;
}
.transition-fade-exit {
  opacity: 1;
}
.transition-fade-enter-active {
  opacity: 1;
}
.transition-fade-exit-active {
  opacity: 0;
}
.transition-fade-enter-active, .transition-fade-exit-active {
  transition: opacity 0.2s ease-out;
}

.t-display {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 3.25rem;
  line-height: 3.5rem;
  letter-spacing: 0;
  text-transform: none;
}

.znd-software-page__full-description h1, .znd-shop-product-page__content-table h1, .znd-shop-product-page__content.contain-description h1,
.t-headline-1 {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.25rem;
  letter-spacing: 0;
  text-transform: none;
}

.znd-software-page__full-description h2,
.t-headline-2 {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
}

.znd-software-page__full-description h3, .znd-shop-product-page__content-table h2,
.znd-shop-product-page__content-table h3, .znd-shop-product-page__content.contain-description h2,
.znd-shop-product-page__content.contain-description h3,
.t-headline-3 {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.875rem;
  letter-spacing: 0;
  text-transform: none;
}

.znd-shop-product-page__meta-group-title, .znd-product-card__title,
.t-subhead-1 {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  text-transform: none;
}

.znd-shop-product-page__product-price-per-unit,
.t-subhead-2 {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  text-transform: none;
}

.t-subhead-small {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  text-transform: none;
}

.t-subhead-large-bold {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-transform: none;
}

.znd-tabs--small .znd-tabs__item, .znd-shop-product-page__specification-description, .znd-product-card__price,
.t-body {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
}

.znd-shop-product-page__specification-title, .znd-shop-product-page__application-title, .znd-product-card__price-info, .znd-product-card__description,
.t-body-small {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
}

.t-body-small-bold {
  margin-bottom: 0.25rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
}

.znd-shop-product-page__related-products-title,
.t-body-tiny {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.t-quote {
  margin: 0;
  font-style: italic;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.25rem;
  letter-spacing: 0;
  text-transform: none;
}

.t-navigation {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.125rem;
  letter-spacing: -0.005em;
  text-transform: none;
}

.t-card-title {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
}

.t-button {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.znd-anchored-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1020;
  background-color: #ffffff;
  min-width: 15rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(0.5rem);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s step-end, transform 0.2s ease-out, opacity 0.2s ease-out;
  border-radius: 5px;
}
@supports (filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.15))) {
  .znd-anchored-dropdown {
    filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.15));
    box-shadow: none;
  }
}
.znd-anchored-dropdown::before {
  display: block;
  position: absolute;
  right: 1.5625rem;
  bottom: 100%;
  transform: translateX(50%) translateY(2px);
  border: 0.625rem solid transparent;
  border-bottom: 0.75rem solid #ffffff;
  width: 0;
  content: "";
}
.znd-anchored-dropdown__visible {
  transform: translateY(1rem);
  visibility: visible;
  opacity: 1;
  transition: visibility 0.2s step-start, transform 0.2s ease-out, opacity 0.2s ease-out;
}

.znd-car__animation--rotate {
  transform-origin: center bottom;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: carRotate;
}
.znd-car__animation--bump {
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: carBody;
}

@keyframes carBody {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1%);
  }
}
@keyframes carRotate {
  0%, 100% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-0.375deg);
  }
}
.znd-icon-info {
  flex: 0 0 1.375rem;
  margin-right: 0.75rem;
  border-radius: 50%;
  background-color: #74797c;
  height: 1.375rem;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
}

.znd-multiselect {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  max-width: 100%;
}
.znd-multiselect--open {
  border-color: #74797c;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.znd-multiselect__arrow {
  display: flex;
  flex: 0 0 calc(1.5em + 1.5rem);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: -0.75rem -1rem -0.75rem auto;
  width: calc(1.5em + 1.5rem);
  color: #e4032e;
  font-size: 1.25rem;
}
.znd-multiselect__values {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
  margin-left: -0.375rem;
  padding: 0;
  overflow: hidden;
}
.znd-multiselect__value {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin: 0;
  border: 1px solid #212525;
  border-radius: 5px;
  padding: 0 2.375rem 0 0.625rem;
  max-width: 100%;
  height: 1.75rem;
  color: #212525;
  font-weight: 700;
  list-style-type: none;
}
.znd-multiselect__value + .znd-multiselect__value {
  margin-left: 0.25rem;
}
.znd-multiselect__value-remove {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 1px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 0.5rem;
  width: 1.625rem;
  font-size: 1.2em;
}
.znd-multiselect__value-remove:hover {
  color: #e4032e;
}
.znd-multiselect__value-label {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.znd-multiselect__dropdown {
  position: absolute;
  top: 100%;
  right: -1px;
  left: -1px;
  z-index: 1020;
  margin: -1px 0 0;
  border: 1px solid #74797c;
  border-top-color: #d1dae1;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #ffffff;
  padding: 0.875rem 0;
  max-height: 12.5rem;
  overflow: auto;
}
.znd-multiselect__option {
  cursor: pointer;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.znd-multiselect__option:hover {
  background-color: rgba(236, 236, 237, 0.5);
}
.znd-multiselect__label {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  cursor: pointer;
  margin: 0;
  padding: 0.375rem 1.5rem 0.375rem 3.25rem;
  color: #212525;
}
.znd-multiselect__label .custom-control-label::before, .znd-multiselect__label .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
}
.znd-multiselect__more {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
  margin-left: 0.25rem;
  border: 1px solid #74797c;
  border-radius: 5px;
  padding: 0 0.625rem;
  color: #74797c;
  font-weight: 700;
}
.znd-multiselect__placeholder {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  color: #212525;
}

.znd-native-select {
  position: relative;
  border: 1px solid #d1dae1;
  border-radius: 5px;
  background-color: #ececed;
  padding: 0.75rem 0;
  height: calc(1.5em + 1.5rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
}
.znd-native-select.znd-native-select--cart-line-item-quantity {
  padding: 0;
  width: 6.25rem;
  height: 2rem;
  line-height: 1.25;
}

@media (min-width: 1200px) {
  .znd-native-select {
    max-width: 16.25rem;
  }
}
.znd-native-select:hover {
  border-color: #74797c;
}
.znd-native-select__select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  appearance: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.znd-native-select__icon {
  position: absolute;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: #e4032e;
  font-size: 1.25rem;
}
.znd-native-select.znd-native-select--cart-line-item-quantity .znd-native-select__icon {
  right: 0.5rem;
}
.znd-native-select__label {
  margin: 0;
  padding-right: calc(1.5em + 1.5rem + 2px);
  padding-left: 1rem;
  width: 100%;
  overflow: hidden;
  color: #212525;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.znd-native-select__label--selected {
  color: #212525;
}
.znd-native-select.znd-native-select--cart-line-item-quantity .znd-native-select__label {
  padding: 0.25rem 0.5rem;
  padding-right: 2rem;
  width: 100%;
  text-align: center;
}

.znd-newsletter-and-terms {
  margin-bottom: 0.5rem;
}
.znd-newsletter-and-terms__checkbox-option {
  cursor: pointer;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.znd-newsletter-and-terms__checkbox-option:hover {
  background-color: rgba(236, 236, 237, 0.5);
}
.znd-newsletter-and-terms__checkbox-label {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  cursor: pointer;
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #212525;
}
.znd-newsletter-and-terms__checkbox-label .custom-control-label::before, .znd-newsletter-and-terms__checkbox-label .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
}

.znd-number-stepper {
  position: relative;
  width: 6.25rem;
  height: 2rem;
}
.znd-number-stepper__icon-button {
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 2rem;
  height: 110%;
  color: #e4032e;
}
.znd-number-stepper__icon-button.--plus {
  right: -0.125rem;
}
.znd-number-stepper__icon-button.--plus .icon {
  top: unset;
  font-size: 1.125rem;
}
.znd-number-stepper__icon-button.--plus .icon-plus {
  left: 0.25em;
}
.znd-number-stepper__icon-button.--minus {
  left: -0.125rem;
}
.znd-number-stepper__icon-button.--minus .icon-minus {
  left: 0.25rem;
}
.znd-number-stepper__input {
  /* Hide browser specific number stepper */
  -moz-appearance: textfield;
  padding: 0.375rem 1.5rem;
  height: 100%;
  text-align: center;
}
.znd-number-stepper__input::-webkit-outer-spin-button, .znd-number-stepper__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.znd-product-suggestion-dropdown {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 100%;
  left: -3.375rem;
  z-index: 1020;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  background-color: #ffffff;
  width: calc(100vw - 1rem);
  max-height: 15.75rem;
  overflow-y: scroll;
}
@media (min-width: 576px) {
  .znd-product-suggestion-dropdown {
    left: 0;
    width: calc(100% - 3.875rem);
  }
}
@media (min-width: 1200px) {
  .znd-product-suggestion-dropdown {
    width: 100%;
  }
}
.znd-product-suggestion-dropdown.--full-width {
  left: 0;
  width: 100%;
}
.znd-product-suggestion-dropdown__item {
  display: block;
  cursor: pointer;
  margin: 0.5rem;
  padding: 0.375rem 0.75rem;
}
.znd-product-suggestion-dropdown__item:hover {
  border-radius: 0.375rem;
  background-color: #ececed;
}
.znd-product-suggestion-dropdown__item__product-number {
  padding-bottom: 0.25rem;
  color: #74797c;
}
.znd-product-suggestion-dropdown__item__product-name {
  color: #212525;
}
.znd-product-suggestion-dropdown__no-results {
  margin: 0.5rem;
  padding: 0.375rem 0.75rem;
  color: #212525;
}

.znd-progress-stepper {
  display: flex;
}
.znd-progress-stepper__step {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  color: #74797c;
}
.znd-progress-stepper__step::before {
  display: block;
  position: absolute;
  top: 0.5rem;
  transform: translateY(-50%);
  background-color: currentColor;
  width: 100%;
  height: 1px;
  content: "";
}
.znd-progress-stepper__step:first-child::before {
  right: 0;
  width: 50%;
}
.znd-progress-stepper__step:last-child::before {
  left: 0;
  width: 50%;
}
.znd-progress-stepper__step--active {
  color: #e4032e;
}
.znd-progress-stepper__step--active::before {
  height: 3px;
}
.znd-progress-stepper__step--exact::before {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media (min-width: 768px) {
  .znd-progress-stepper__step {
    width: 11rem;
  }
}
.znd-progress-stepper__dot {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
  cursor: pointer;
  text-align: center;
}
.znd-progress-stepper.znd-progress-stepper--disabled .znd-progress-stepper__dot {
  cursor: auto;
}

.znd-progress-stepper__dot::before {
  display: block;
  margin-right: auto;
  margin-bottom: 0.5rem;
  margin-left: auto;
  border-radius: 100%;
  background-color: currentColor;
  width: 1rem;
  height: 1rem;
  content: "";
}

.znd-radio {
  cursor: pointer;
  margin: 0;
  padding: 1.5rem;
}
.znd-radio .znd-radio__label {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
  color: #4c575d;
  font-weight: 700;
}
.znd-radio .znd-radio__label::before {
  top: 50%;
  transform: translateY(-56.25%);
  border-color: #d1dae1;
  background-color: #ffffff;
}
.znd-radio .znd-radio__input:checked ~ .znd-radio__label::after {
  top: 50%;
  left: -1.25rem;
  transform: translateY(-62.5%);
  border-radius: 50%;
  background-image: none;
  background-color: #e4032e;
  width: 0.5rem;
  height: 0.5rem;
}
.znd-radio .znd-radio__input:checked ~ .znd-radio__label::before {
  border: 2px #e4032e solid;
  background-color: #ffffff;
}
.znd-radio__large {
  padding: 0.5rem 1rem 0.5rem 0;
}
.znd-radio__large:first-child {
  padding-left: 0;
}
@media (min-width: 576px) {
  .znd-radio__large {
    padding-left: 1rem;
  }
}
.znd-radio__large .znd-radio__label {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.znd-range-slider {
  position: relative;
}
.znd-range-slider__active-track {
  position: absolute;
  top: 0.625rem;
  left: 0;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
}
.znd-range-slider__active-bar {
  transform-origin: left center;
  background-color: #e4032e;
  width: 100%;
  height: 0.375rem;
}
.znd-range-slider__input:disabled + .znd-range-slider__active-track, .znd-range-slider__input[disabled] + .znd-range-slider__active-track {
  opacity: 0;
}

.znd-search-input {
  position: relative;
  min-width: 10rem;
}
.znd-search-input .znd-search-input__input {
  border-radius: 1.5625rem;
  background-color: #ffffff;
  padding-right: 3.25rem;
  padding-left: 1.5rem;
}
.znd-search-input .znd-search-input__input:disabled, .znd-search-input .znd-search-input__input[readonly] {
  background-color: #d1dae1;
}
.znd-search-input .znd-search-input__input:hover, .znd-search-input .znd-search-input__input:focus {
  background-color: #ffffff;
}
.znd-search-input__icon {
  position: absolute;
  top: 50%;
  right: 1.625rem;
  transform: translate(50%, -50%);
  pointer-events: none;
  font-size: 1.125rem;
  line-height: 1;
}
:disabled + .znd-search-input__icon, [readonly] + .znd-search-input__icon {
  color: #c4c8c8;
}
.znd-search-input__icon--clear {
  cursor: pointer;
  pointer-events: auto;
}

.znd-select {
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer;
}
.znd-select__box {
  position: relative;
  align-self: flex-end;
  border-radius: 1.25rem;
  background-color: #ffffff;
  padding: 0.625rem 2.375rem 0.625rem 1rem;
  width: fit-content;
  color: #4c575d;
  font-weight: 700;
}
.znd-select__icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
.znd-select__options-wrapper {
  position: absolute;
  top: 100%;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 1020;
}
.znd-select__options {
  position: relative;
  margin-top: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  white-space: nowrap;
}
.znd-select__options-item {
  position: relative;
  padding: 0.5rem 3.125rem 0.5rem 2.75rem;
}
.znd-select__options-item:hover {
  background-color: #ececed;
}
.znd-select__options-item-icon {
  position: absolute;
  top: 50%;
  left: 0.825rem;
  transform: translateY(-50%);
  color: #e4032e;
}
.znd-select__options::before {
  position: absolute;
  top: -0.5rem;
  right: 1.25rem;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid #ffffff;
  border-left: 0.5rem solid transparent;
  width: 0;
  height: 0;
  content: "";
}
.znd-select:hover .znd-select__options-wrapper {
  visibility: visible;
  opacity: 1;
}

.znd-select-box-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 1.25rem;
  background-color: #ffffff;
  padding: 0.625rem 1rem;
  color: #4c575d;
  font-weight: 700;
}
.znd-select-box-button:hover {
  background-color: #f1f1f2;
}
.znd-select-box-button__icon {
  margin-top: -0.1rem;
  margin-right: 0.375rem;
  margin-bottom: -0.1rem;
  font-size: 1.2rem;
}

.znd-tooltip {
  position: relative;
}
.znd-tooltip__label {
  display: none;
  position: absolute;
  z-index: 1070;
  min-width: 100px;
  max-width: 300px;
  font-family: "IBMPlexSans", -apple-system, "Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  color: #ffffff;
  line-height: 1.25rem;
  text-align: center;
  word-wrap: break-word;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out, visibility 0.2s step-end;
  transition-delay: 0.1s;
  border-radius: 5px;
  background-color: #212525;
  padding: 0.375rem;
}
.znd-tooltip__label::before {
  display: block;
  position: absolute;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 11'%3e%3cpath fill='%23212525' d='M10 10c-.6.6-1.4.6-2 0L.5 3.1c-1-1-.3-2.6 1-2.6h15c1.3 0 2 1.7 1 2.6l-7.5 7z'/%3e%3c/svg%3e");
  background-position: center center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 1.625rem;
  height: 0.875rem;
  content: "";
}
.znd-tooltip--visible .znd-tooltip__label {
  display: block;
}
@media (min-width: 576px) {
  .znd-tooltip--visible-sm .znd-tooltip__label {
    display: block;
  }
}
@media (min-width: 768px) {
  .znd-tooltip--visible-md .znd-tooltip__label {
    display: block;
  }
}
@media (min-width: 992px) {
  .znd-tooltip--visible-lg .znd-tooltip__label {
    display: block;
  }
}
@media (min-width: 1200px) {
  .znd-tooltip--visible-xl .znd-tooltip__label {
    display: block;
  }
}
@media (min-width: 1440px) {
  .znd-tooltip--visible-hg .znd-tooltip__label {
    display: block;
  }
}
.znd-tooltip--top-center .znd-tooltip__label {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) scale(0.9);
  transform-origin: center bottom;
  margin-bottom: 0.625rem;
}
.znd-tooltip--top-center .znd-tooltip__label::before {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-0.375rem);
}
.znd-tooltip--top-center:hover .znd-tooltip__label {
  transform: translateX(-50%) scale(1);
}
.znd-tooltip--top-left .znd-tooltip__label {
  bottom: 100%;
  left: 0;
  transform: scale(0.9);
  transform-origin: left bottom;
  margin-bottom: 0.625rem;
}
.znd-tooltip--top-left .znd-tooltip__label::before {
  top: 100%;
  left: 0.375rem;
  transform: translateY(-0.375rem);
}
.znd-tooltip--top-left:hover .znd-tooltip__label {
  transform: scale(1);
}
.znd-tooltip--top-right .znd-tooltip__label {
  right: 0;
  bottom: 100%;
  transform: scale(0.9);
  transform-origin: right bottom;
  margin-bottom: 0.625rem;
}
.znd-tooltip--top-right .znd-tooltip__label::before {
  top: 100%;
  right: 0.375rem;
  transform: translateY(-0.375rem);
}
.znd-tooltip--top-right:hover .znd-tooltip__label {
  transform: scale(1);
}
.znd-tooltip--bottom-center .znd-tooltip__label {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) scale(0.9);
  transform-origin: center top;
  margin-top: 0.625rem;
}
.znd-tooltip--bottom-center .znd-tooltip__label::before {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(0.375rem) rotate(180deg);
}
.znd-tooltip--bottom-center:hover .znd-tooltip__label {
  transform: translateX(-50%) scale(1);
}
.znd-tooltip--bottom-left .znd-tooltip__label {
  top: 100%;
  left: 0;
  transform: scale(0.9);
  transform-origin: left top;
  margin-top: 0.625rem;
}
.znd-tooltip--bottom-left .znd-tooltip__label::before {
  bottom: 100%;
  left: 0.375rem;
  transform: translateY(0.375rem) rotate(180deg);
}
.znd-tooltip--bottom-left:hover .znd-tooltip__label {
  transform: scale(1);
}
.znd-tooltip--bottom-right .znd-tooltip__label {
  top: 100%;
  right: 0;
  transform: scale(0.9);
  transform-origin: right top;
  margin-top: 0.625rem;
}
.znd-tooltip--bottom-right .znd-tooltip__label::before {
  right: 0.375rem;
  bottom: 100%;
  transform: translateY(0.375rem) rotate(180deg);
}
.znd-tooltip--bottom-right:hover .znd-tooltip__label {
  transform: scale(1);
}
.znd-tooltip--left .znd-tooltip__label {
  top: 50%;
  right: 100%;
  transform: translateY(-50%) scale(0.9);
  transform-origin: right center;
  margin-right: 0.625rem;
}
.znd-tooltip--left .znd-tooltip__label::before {
  top: 50%;
  left: 100%;
  transform: translateY(-50%) translateX(-0.75rem) rotate(-90deg);
}
.znd-tooltip--left:hover .znd-tooltip__label {
  transform: translateY(-50%) scale(1);
}
.znd-tooltip--right .znd-tooltip__label {
  top: 50%;
  left: 100%;
  transform: translateY(-50%) scale(0.9);
  transform-origin: left center;
  margin-left: 0.625rem;
}
.znd-tooltip--right .znd-tooltip__label::before {
  top: 50%;
  right: 100%;
  transform: translateY(-50%) translateX(0.75rem) rotate(90deg);
}
.znd-tooltip--right:hover .znd-tooltip__label {
  transform: translateY(-50%) scale(1);
}
.znd-tooltip:hover .znd-tooltip__label {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s ease-out, transform 0.1s ease-out, visibility 0.1s step-start;
  transition-delay: 0s;
}

.znd-user-button {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.znd-user-button__name {
  padding-left: 0.625rem;
}
@media (min-width: 1200px) {
  .znd-user-button__name {
    display: none;
  }
}
.znd-user-button__dropdown {
  position: absolute;
  bottom: 100%;
  left: 0;
  transform: translateY(-1.25rem);
  z-index: 1020;
  background-color: #ffffff;
  min-width: 15rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 0.75rem 0;
}
@media (min-width: 1200px) {
  @supports (filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.15))) {
    .znd-user-button__dropdown {
      filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.15));
      box-shadow: none;
    }
  }
}
.znd-user-button__dropdown::before {
  display: block;
  position: absolute;
  top: 100%;
  left: 1.5625rem;
  transform: translateX(-50%) translateY(-2px);
  border: 0.625rem solid transparent;
  border-top: 0.75rem solid #ffffff;
  width: 0;
  content: "";
}
@media (min-width: 1200px) {
  .znd-user-button__dropdown::before {
    top: auto;
    right: 1.5625rem;
    bottom: 100%;
    left: auto;
    transform: translateX(50%) translateY(2px);
    border: 0.625rem solid transparent;
    border-bottom: 0.75rem solid #ffffff;
  }
}
@media (min-width: 1200px) {
  .znd-user-button__dropdown {
    top: 100%;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translateY(1.25rem);
  }
}
.znd-user-button__dropdown__title {
  border-bottom: 1px solid #ececed;
  padding: 0.75rem 1.25rem;
}
.znd-user-button__dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1.25rem;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
}
.znd-user-button__dropdown-item, .znd-user-button__dropdown-item:hover, .znd-user-button__dropdown-item:focus, .znd-user-button__dropdown-item:active {
  color: #212525;
}
.znd-user-button__dropdown-item:hover, .znd-user-button__dropdown-item:focus, .znd-user-button__dropdown-item:active {
  background-color: rgba(236, 236, 237, 0.4);
}
.znd-user-button__dropdown-item i {
  margin-right: 0.5rem;
  color: #c4c8c8;
  font-size: 1.25rem;
}

.znd-typeahead-input {
  position: relative;
}
.znd-typeahead-input > input {
  padding-right: 2.75rem;
}
.znd-typeahead-input > input[readonly] {
  border-color: #d1dae1;
  color: #212525;
}
.znd-typeahead-input__icon {
  position: absolute;
  top: 50%;
  right: 1.625rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: #e4032e;
  font-size: 1.125rem;
  line-height: 1;
}
.znd-typeahead-input__options {
  display: none;
  padding-left: 0;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1020;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
  background-color: #ffffff;
  width: 100%;
  max-height: 15.75rem;
  overflow-y: scroll;
}
@media (min-width: 1200px) {
  .znd-typeahead-input__options {
    width: 100%;
  }
}
.znd-typeahead-input__options-item {
  display: block;
  cursor: pointer;
  margin: 0.5rem;
  padding: 0.375rem 0.75rem;
  color: #212525;
}
.znd-typeahead-input__options-item:hover, .znd-typeahead-input__options-item--selected {
  border-radius: 0.375rem;
  background-color: #ececed;
}
.znd-typeahead-input__options--visible {
  display: block;
}

.znd-activate-license-modal__body {
  padding: 0;
  height: calc(100vh - 6.25rem);
}
.znd-activate-license-modal__body iframe {
  width: 100%;
  height: 100%;
}

.znd-address__headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #212525;
}
.znd-address__icon {
  margin-top: -1px;
  margin-right: 0.5rem;
  color: #e4032e;
}
.znd-address__details {
  color: #212525;
}
.znd-address__button {
  font-size: 0.875rem;
}
.znd-address__button i {
  margin-left: 0.5rem;
  font-size: 1.2rem !important;
}
.znd-address__button span {
  padding-top: 3px;
}

.znd-addresses-table-sub {
  margin: 1rem 1rem 1.5rem 4.125rem;
}
.znd-addresses-table-sub__column {
  width: auto;
  min-width: 20%;
  max-width: 40%;
  font-size: 1rem;
  line-height: 1.5rem;
}
.znd-addresses-table-sub__column div + div {
  margin-top: 0.125rem;
}
.znd-addresses-table-sub__title {
  line-height: 1.375rem;
}

.znd-app {
  display: flex;
  flex-direction: column;
  padding-top: 3.875rem;
  min-height: 100vh;
}
@media (min-width: 1200px) {
  .znd-app {
    padding-top: 0;
    padding-left: 5rem;
  }
}

.znd-asset {
  position: relative;
  z-index: 0;
}
.znd-asset--scrollable {
  overflow-x: auto;
  overflow-y: hidden;
}
.znd-asset__check {
  color: #e4032e;
  font-size: 1.25rem;
}

.znd-assets-page {
  margin-top: 1.25rem;
}
.znd-assets-page__btn-change-request.btn.--has-icon, .znd-assets-page__btn-change-request.--has-icon.adyen-checkout__link {
  padding-right: 3.25rem;
}

.znd-assets-table-sub {
  margin: 1rem 1rem 1.5rem 4.125rem;
  width: fit-content;
  max-width: 100%;
}
.znd-assets-table-sub__return_table {
  margin: 1rem 1rem 0.5rem 4.125rem;
  max-width: inherit;
}
.znd-assets-table-sub__return_table__label {
  margin-top: 0 !important;
}
.znd-assets-table-sub__return_table__label__disabled {
  color: #74797c;
}
.znd-assets-table-sub__return_table__item {
  width: 51.5%;
}
.znd-assets-table-sub__return_table__sub-item {
  width: 30%;
}
.znd-assets-table-sub__return_table__sub-item__optional_text {
  color: #74797c;
  font-weight: normal;
}
.znd-assets-table-sub__return_table__sub-item__error {
  border: 1px solid #e24c4c;
}
.znd-assets-table-sub__return_table__sub-item__error__text {
  display: inline-flex;
}
.znd-assets-table-sub__image {
  margin-right: 1rem;
  border: 1px solid #d1dae1;
  border-radius: 0.375rem;
  padding: 0.75rem;
  width: 9.5rem;
  height: 10.125rem;
  object-fit: contain;
}
.znd-assets-table-sub__table {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: calc(100% - 3.125rem);
}
.znd-assets-table-sub__buttons {
  display: flex;
  flex-flow: wrap;
  width: 100%;
}
.znd-assets-table-sub__buttons .btn, .znd-assets-table-sub__buttons .adyen-checkout__link.adyen-checkout__link {
  margin: 1.25rem 0.75rem 0 0;
}
.znd-assets-table-sub__button-wrapper {
  width: 100%;
}
@media (min-width: 768px) {
  .znd-assets-table-sub__button-wrapper {
    width: auto;
  }
}
.znd-assets-table-sub__info {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}
.znd-assets-table-sub__filename {
  color: #4c575d;
}
.znd-assets-table-sub__filename .btn__icon-right {
  color: #e4032e;
}

.znd-block {
  background-position: center;
  background-repeat: no-repeat;
}
.znd-block__grid {
  display: grid;
  gap: 2.5rem;
  padding: 20px;
}
@media (min-width: 768px) {
  .znd-block__grid {
    grid-template-columns: 1fr 1fr;
  }
}
.znd-block__headline {
  padding: 70px 20px 20px;
}
.znd-block__text-on-image__text {
  display: grid;
  align-items: center;
  padding: 30px;
  min-height: 240px;
}
.znd-block__text-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2.5rem;
}
.znd-block p {
  margin-bottom: 0;
}

.btn, .adyen-checkout__link.adyen-checkout__link {
  position: relative;
}
.btn i, .adyen-checkout__link.adyen-checkout__link i {
  font-size: 1.5em;
}
.btn__icon-left {
  margin-right: 0.75rem;
}
.btn__icon-right {
  margin-left: 0.75rem;
}
.btn.--has-icon, .--has-icon.adyen-checkout__link {
  padding: 0.875rem 5rem 0.875rem 1.5rem;
}
.btn.--has-icon i, .--has-icon.adyen-checkout__link i {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
}
.btn.--has-icon.--btn-full-width, .--has-icon.--btn-full-width.adyen-checkout__link {
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 992px) {
  .btn.--has-icon.--btn-full-width, .--has-icon.--btn-full-width.adyen-checkout__link {
    width: auto;
  }
}
.btn-sm.--has-icon {
  padding: 0.625rem 1rem;
  padding-right: 2.75rem;
}
.btn-sm.--has-icon i {
  right: 0.625rem;
}
.btn-block {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.btn-block .btn__icon-left {
  margin-right: auto;
}
.btn-block .btn__icon-right {
  margin-left: auto;
}
.btn.--image-only, .--image-only.adyen-checkout__link, .btn.--icon-only, .--icon-only.adyen-checkout__link {
  position: relative;
  border-radius: 50%;
  padding: 1.5rem;
}
.btn.--icon-only i, .--icon-only.adyen-checkout__link i {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  font-size: 1.25rem;
}
.btn.--image-only, .--image-only.adyen-checkout__link {
  position: relative;
  overflow: hidden;
}
.btn.--image-only .btn__image, .--image-only.adyen-checkout__link .btn__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.2s ease-out;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.btn.--image-only:hover .btn__image, .--image-only.adyen-checkout__link:hover .btn__image {
  opacity: 0.8;
}
.btn.--text, .--text.adyen-checkout__link {
  padding: 0;
}
.btn.--text i, .--text.adyen-checkout__link i {
  left: 0;
  padding: 0;
}
.btn:disabled, .adyen-checkout__link:disabled {
  opacity: 1;
}
.btn .btn__loader, .adyen-checkout__link.adyen-checkout__link .btn__loader {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  width: 1.25rem;
  height: 1.25rem;
}
.btn .btn__loader path, .adyen-checkout__link.adyen-checkout__link .btn__loader path {
  stroke: currentColor;
  stroke-width: 6px;
}
.btn.btn-sm .btn__loader, .btn-sm.adyen-checkout__link .btn__loader {
  right: 0.5rem;
}
.btn-navigator {
  border-color: #d1dae1;
  background-color: #ffffff;
  color: #e4032e;
}
.btn-navigator:hover {
  color: #e4032e;
}
.btn-navigator:disabled {
  border-color: #ececed;
  color: #d1dae1;
}
.btn-white {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #4c575d;
}
.btn-white:hover {
  color: #4c575d;
}
.btn-white:disabled {
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn-white:focus {
  box-shadow: none;
}
.btn-gray {
  border-color: #ececed;
  background-color: #ececed;
  color: #4c575d;
}
.btn-gray:hover {
  background-color: #d1dae1;
  color: #4c575d;
}
.btn-gray:disabled {
  border-color: rgba(236, 236, 237, 0.8);
  background-color: rgba(236, 236, 237, 0.8);
}
.btn-gray:focus {
  box-shadow: none;
}

.znd-cart-list-item {
  position: relative;
  flex: 0 0 80%;
  border-bottom: 1px #d1dae1 solid;
  padding: 1.25rem;
  padding-right: 3.5rem;
  width: 100%;
  color: #212525;
}
@media (min-width: 992px) {
  .znd-cart-list-item {
    padding: 1.25rem;
  }
}
.znd-cart-list-item__content-wrapper {
  padding-left: 1rem;
  width: calc(100% - 6.25rem);
}
@media (min-width: 992px) {
  .znd-cart-list-item__content-wrapper {
    padding-left: 0;
    width: calc(100% - 7.25rem);
  }
}
.znd-cart-list-item__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.znd-cart-list-item__image-wrapper {
  position: relative;
  flex: 0 0 auto;
  border: 1px solid #d1dae1;
  border-radius: 0.25rem;
  width: 6.25rem;
  height: 3.25rem;
}
@media (min-width: 992px) {
  .znd-cart-list-item__image-wrapper {
    width: 7.75rem;
    height: 4rem;
  }
}
.znd-cart-list-item__text-wrapper {
  flex: 1 1 auto;
  flex-direction: column;
}
@media (min-width: 992px) {
  .znd-cart-list-item__text-wrapper {
    flex: 1 1 8.75rem;
    padding: 0 1.25rem;
    min-width: 8.75rem;
  }
}
@media (min-width: 1200px) {
  .znd-cart-list-item__text-wrapper {
    padding-right: 2.5rem;
    min-width: 15rem;
  }
}
.znd-cart-list-item__title {
  color: #4c575d;
}
.znd-cart-list-item__title:hover {
  color: #4c575d;
}
.znd-cart-list-item__description {
  display: none;
  padding-top: 0.25rem;
  max-width: 21rem;
  overflow: hidden;
  color: #212525;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 576px) {
  .znd-cart-list-item__description {
    display: block;
  }
}
.znd-cart-list-item__price {
  flex: 0 0 auto;
  padding: 1.75rem 0 1.5rem;
  color: #212525;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .znd-cart-list-item__price {
    padding: 0 2.5rem 0 1.25rem;
  }
}
.znd-cart-list-item__price-per-unit {
  margin-left: 0.25rem;
  color: #74797c;
  line-height: 1.375rem;
}
@media (min-width: 992px) {
  .znd-cart-list-item__price-per-unit {
    margin: 0;
    line-height: 1.25rem;
  }
}
.znd-cart-list-item__price-total {
  display: flex;
  justify-content: flex-start;
}
@media (min-width: 992px) {
  .znd-cart-list-item__price-total {
    flex: 0 0 7rem;
    margin-left: 1.125rem;
    padding-right: 0.5rem;
    text-align: right;
  }
}
@media (min-width: 992px) {
  .znd-cart-list-item__price-total {
    justify-content: flex-end;
  }
}
@media (min-width: 1200px) {
  .znd-cart-list-item__price-total {
    flex: 0 0 7.75rem;
  }
}
.znd-cart-list-item__number-stepper {
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
}
@media (min-width: 992px) {
  .znd-cart-list-item__number-stepper {
    flex: 0 0 auto;
    padding: 0 2.75rem 0 0;
  }
}
@media (min-width: 1200px) {
  .znd-cart-list-item__number-stepper {
    padding: 0 3.75rem 0 1.25rem;
  }
}
.znd-cart-list-item .znd-cart-list-item__button-trash {
  position: absolute;
  top: 1.75rem;
  right: 2.5rem;
}
@media (min-width: 992px) {
  .znd-cart-list-item .znd-cart-list-item__button-trash {
    position: relative;
    top: unset;
    right: unset;
  }
}
.znd-cart-list-item .znd-cart-list-item__button-trash .btn__loader {
  right: unset;
}

.znd-change-company-profile-modal__body {
  padding-top: 2.5rem;
}
.znd-change-company-profile-modal__footer {
  padding-bottom: 2.375rem;
}
.znd-change-company-profile-modal__info-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 2.5rem;
  border: 1px #4c575d solid;
  border-radius: 0.375rem;
  background-color: #ececed;
  padding: 1rem;
}
.znd-change-company-profile-modal__info-icon {
  font-size: 1.375rem;
}
.znd-change-company-profile-modal__info-text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}
.znd-change-company-profile-modal__info-icon + .znd-change-company-profile-modal__info-text {
  margin-left: 0.625rem;
}

.znd-change-contact-modal__label {
  margin-bottom: 0.375rem;
  font-weight: 700;
}
.znd-change-contact-modal__info {
  margin-top: 1.5rem;
}

.znd-change-request-modal__select-header, .znd-change-request-modal__input-header {
  margin-bottom: 0.375rem;
  font-weight: 700;
}
.znd-change-request-modal__body {
  max-height: calc(100vh - 13.25rem);
}
.znd-change-request-modal__body .required {
  color: #e4032e;
}
.znd-change-request-modal__body .form-control, .znd-change-request-modal__body .znd-multiselect {
  margin-bottom: 1.25rem;
}
.znd-change-request-modal__row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .znd-change-request-modal__row {
    flex-direction: row;
  }
}
.znd-change-request-modal__col {
  width: 100%;
}
@media (min-width: 992px) {
  .znd-change-request-modal__col {
    max-width: 49%;
  }
}
.znd-change-request-modal__select {
  margin-bottom: 1.25rem;
  max-width: 50%;
}
.znd-change-request-modal__select-country, .znd-change-request-modal__select-state {
  width: 100%;
  max-width: 99%;
}
.znd-change-request-modal__checkbox-label {
  margin-bottom: 1.25rem;
  padding-left: 1.75rem;
}

.znd-change-usage-modal__label {
  margin-bottom: 0.375rem;
  font-weight: 700;
}

.znd-company-declined-modal__body {
  max-height: calc(100vh - 16.25rem);
}
.znd-company-declined-modal__footer {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
@media (min-width: 992px) {
  .znd-company-declined-modal__footer {
    flex-direction: row;
  }
}

.znd-contact-person__icon {
  margin-top: -1px;
  margin-right: 0.5rem;
  color: #e4032e;
}
.znd-contact-person__link {
  color: #212525;
}
.znd-contact-person__details {
  color: #212525;
}
.znd-contact-person__name {
  padding-right: 0.375rem;
  font-weight: 700;
  word-break: break-word;
}
.znd-contact-person__actions {
  display: flex;
  margin-left: auto;
}
.znd-contact-person__button {
  font-size: 0.875rem;
}
.znd-contact-person__button i {
  margin-left: 0.5rem;
  font-size: 1.25rem !important;
}
.znd-contact-person__roles {
  color: #212525;
}
.znd-contact-person__roles td {
  padding-bottom: 0.375rem;
}

.znd-cutter-property {
  flex: 0 0 50%;
  padding-bottom: 1.25rem;
}

.znd-cutter-slider {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
}
.znd-cutter-slider__title {
  margin-bottom: 0.625rem;
  color: #212525;
}

.znd-cutter-tile {
  display: block;
  position: relative;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 1.25rem 1.25rem 0 1.25rem;
  height: 100%;
}
@media (min-width: 576px) {
  .znd-cutter-tile {
    padding: 1.875rem;
  }
}
.znd-cutter-tile__title {
  margin-bottom: 1.25rem;
  color: #4c575d;
}
.znd-cutter-tile__image {
  margin-bottom: 1.25rem;
  padding: 0 1rem;
  width: 100%;
  max-height: 18.75rem;
  object-fit: contain;
}
.znd-cutter-tile__button {
  z-index: 2;
  margin: 0 0.25rem;
}
@media (max-width: 575.98px) {
  .znd-cutter-tile__button {
    margin-bottom: 1.75rem;
  }
}
.znd-cutter-tile__link-stretched {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.znd-dashboard-disrupter {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  padding: 2.5rem 0;
  width: 100%;
}
@media (min-width: 1440px) {
  .znd-dashboard-disrupter {
    flex-direction: row;
  }
}
.znd-dashboard-disrupter--loading {
  min-height: 7rem;
}

.znd-dashboard-disrupter-item {
  margin-top: 1.5rem;
  margin-right: 2rem;
  border-radius: 0.375rem;
  background-color: #4c575d;
  padding: 1.25rem 1.5rem;
  width: 100%;
  color: #ffffff;
}
@media (min-width: 1440px) {
  .znd-dashboard-disrupter-item {
    margin-top: 0;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .znd-dashboard-disrupter-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }
}
.znd-dashboard-disrupter-item + .znd-dashboard-disrupter-item {
  margin-right: 0;
}
.znd-dashboard-disrupter-item__content-wrapper {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.znd-dashboard-disrupter-item__media {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1.5rem;
  width: 24rem;
  max-width: 100%;
}
@media (min-width: 768px) {
  .znd-dashboard-disrupter-item__media {
    flex: 0 0 24rem;
    margin-left: auto;
    padding-top: 0;
    padding-left: 1.5rem;
  }
}
.znd-dashboard-disrupter-item__image, .znd-dashboard-disrupter-item__video {
  width: 100%;
}
.znd-dashboard-disrupter-item__content *:last-child {
  margin-bottom: 0;
}
.znd-dashboard-disrupter-item__link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  font-weight: 700;
}
.znd-dashboard-disrupter-item__link, .znd-dashboard-disrupter-item__link:hover {
  color: inherit;
}
.znd-dashboard-disrupter-item__link i {
  padding-left: 0.5em;
  font-size: 1em;
}
.znd-dashboard-disrupter-item__title + .znd-dashboard-disrupter-item__content, .znd-dashboard-disrupter-item__title + .znd-dashboard-disrupter-item__link {
  padding-top: 0.5rem;
}
.znd-dashboard-disrupter-item__content + .znd-dashboard-disrupter-item__link, .znd-dashboard-disrupter-item__image + .znd-dashboard-disrupter-item__link, .znd-dashboard-disrupter-item__video + .znd-dashboard-disrupter-item__link {
  padding-top: 1.25rem;
}

.znd-dashboard-news {
  margin-top: 2.5rem;
}
.znd-dashboard-news__subtitle {
  color: #212525;
}
.znd-dashboard-news__list-container {
  position: relative;
  margin: 0.625rem 0;
}
.znd-dashboard-news__list {
  flex-wrap: nowrap;
  overflow: hidden;
}
.znd-dashboard-news__slide-prev {
  left: -1.5rem;
}
.znd-dashboard-news__slide-next {
  right: -1.5rem;
}
.znd-dashboard-news .znd-dashboard-news__slide-prev, .znd-dashboard-news .znd-dashboard-news__slide-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.znd-dashboard-news-item {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 1.25rem;
}
.znd-dashboard-news-item__title, .znd-dashboard-news-item__text {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.znd-dashboard-news-item__title {
  margin-bottom: 1rem;
  color: #4c575d;
  font-weight: 700;
}
.znd-dashboard-news-item__text {
  margin: 0.25rem 0 0.5rem;
  color: #212525;
}
.znd-dashboard-news-item__link, .znd-dashboard-news-item__link:hover, .znd-dashboard-news-item__link:visited {
  color: #e4032e;
  font-weight: 700;
  text-decoration: none;
}
.znd-dashboard-news-item__image img {
  border-radius: 5px;
  width: 100%;
}

.znd-dashboard-page {
  display: flex;
  flex-direction: column;
  margin: 1.25rem;
  height: 100%;
}
@media (min-width: 768px) {
  .znd-dashboard-page {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
}
.znd-dashboard-page__title {
  color: #4c575d;
}
.znd-dashboard-page__datetime {
  display: inline-block;
  margin-top: 0.25rem;
  color: #74797c;
  font-size: 0.875rem;
}
.znd-dashboard-page__cutter-and-shortcuts {
  align-items: stretch;
  padding-top: 2.5rem;
}
.znd-dashboard-page__cutter {
  margin-bottom: 2.5rem;
  min-height: 4rem;
}

.znd-dashboard-cutter-info-text {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 1.25rem;
  height: 100%;
}
.znd-dashboard-cutter-info-text__text {
  color: #212525;
  font-size: 1.125rem;
}

.znd-dashboard-shortcut-item {
  display: flex;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 1.25rem;
}
.znd-dashboard-shortcut-item + .znd-dashboard-shortcut-item {
  margin-top: 0.625rem;
}
.znd-dashboard-shortcut-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.25rem;
  border-radius: 5px;
  background-color: #ececed;
  width: 3.125rem;
  height: 3.125rem;
  color: #74797c;
  font-size: 1.25rem;
}
.znd-dashboard-shortcut-item__text {
  flex: 1;
  align-self: center;
  margin-right: 1.25rem;
  min-width: 0;
}
.znd-dashboard-shortcut-item__text-title {
  color: #4c575d;
  font-weight: 700;
}
.znd-dashboard-shortcut-item__text-body {
  margin-top: 0.25rem;
  overflow: hidden;
  color: #212525;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.znd-dashboard-shortcut-item__link, .znd-dashboard-shortcut-item__link:hover {
  align-self: center;
  margin-left: auto;
  color: #e4032e;
  font-size: 1.5rem;
  text-decoration: none;
}

.znd-dashboard-shortcuts__subtitle {
  margin-bottom: 0.625rem;
  color: #212525;
}

.znd-download-tile {
  cursor: pointer;
  padding: 0.25rem;
}
.znd-download-tile__inner {
  display: flex;
  border: 1px solid #d1dae1;
  border-radius: 0.375rem;
  padding: 1rem;
  height: 100%;
}
.znd-download-tile__detail {
  overflow: hidden;
}
.znd-download-tile__title {
  overflow: hidden;
  color: #4c575d;
  line-height: 1.625rem;
  text-overflow: ellipsis;
}
.znd-download-tile__info {
  margin-top: 0.25rem;
  color: #74797c;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.08em;
}
.znd-download-tile__icon {
  display: flex;
  align-items: center;
  margin-right: 0.625rem;
  margin-left: auto;
  padding-left: 1rem;
  color: #e4032e;
  font-size: 1.5rem;
}
.znd-download-tile__icon--external-link {
  font-size: 1.375rem;
}

.znd-dropdown {
  display: flex;
  position: fixed;
  top: 100%;
  left: 0;
  flex-direction: column;
  align-items: stretch;
  z-index: 1020;
  margin-top: -3px;
  background-color: #ffffff;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  min-width: 18.75rem;
  max-width: 100vw;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  transform: translateY(-1rem);
  visibility: hidden;
  opacity: 0;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, visibility 0.2s step-end;
  border-radius: 5px;
}
.znd-dropdown--visible {
  position: fixed;
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, visibility 0.2s step-start;
}
.znd-dropdown__item {
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  padding: 0.625rem 1.25rem;
  color: #212525;
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width: 576px) {
  .znd-dropdown__item {
    white-space: nowrap;
  }
}
.znd-dropdown__item:hover, .znd-dropdown__item:focus {
  background-color: #ececed;
  color: #212525;
  text-decoration: none;
}
.znd-dropdown__item--active {
  color: #e4032e;
}

.znd-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -1px 0 #d1dae1;
  background: #ffffff;
  padding: 1.875rem 0.625rem;
}
@media (max-width: 767.98px) {
  .znd-footer {
    padding: 2.5rem 0.625rem;
  }
}

.znd-footer-navigation {
  text-align: center;
}
.znd-footer-navigation__item {
  display: inline-block;
  padding: 0.625rem 1.875rem;
  color: #e4032e;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
@media (max-width: 767.98px) {
  .znd-footer-navigation__item {
    display: block;
    padding: 0.875rem 0;
  }
}

.znd-header-page {
  margin-bottom: 1.25rem;
  width: 100%;
}
@media (min-width: 992px) {
  .znd-header-page {
    margin-bottom: 1rem;
  }
}
.znd-header-page__title {
  margin-bottom: 0.25rem;
  color: #4c575d;
}
.znd-header-page__subtitle {
  color: #212525;
}
.znd-header-page-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 0.25rem;
  width: 100%;
}
@media (min-width: 576px) {
  .znd-header-page-right {
    margin: 0;
    width: auto;
  }
}

.znd-info-box {
  margin: 1.5rem auto;
  border-radius: 0.375rem;
  background-color: #4c575d;
  padding: 1.25rem 1.5rem;
  max-width: 40rem;
  color: #ffffff;
}
.znd-info-box__title {
  padding-bottom: 0.5rem;
}
.znd-info-box__text {
  padding-bottom: 1.25rem;
}
.znd-info-box__link {
  display: flex;
  align-items: center;
  width: fit-content;
  color: #ffffff;
  font-weight: 700;
}
.znd-info-box__link:hover {
  color: #ffffff;
}
.znd-info-box__link i {
  padding-left: 0.5rem;
  font-size: 1rem;
}

.znd-loading-spinner {
  width: 4rem;
  height: 4rem;
}
.znd-loading-spinner--position-absolute {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
.znd-loading-spinner--position-absolute path {
  stroke: currentColor;
}
.znd-loading-spinner--small {
  width: 1rem;
  height: 1rem;
}
.znd-loading-spinner--small path {
  stroke-width: 6px;
}
.znd-loading-spinner__path {
  stroke-width: 2px;
  stroke: #e4032e;
  stroke-dasharray: 150, 391;
  fill: none;
  animation: dash 1s linear infinite;
}
.znd-loading-spinner--thick .znd-loading-spinner__path {
  stroke-width: 4px;
}
.znd-loading-spinner--center {
  position: absolute;
  top: calc(50% - 2rem);
  left: calc(50% - 2rem);
}
.znd-loading-spinner--center.znd-loading-spinner--small {
  top: calc(50% - 0.5rem);
  left: calc(50% - 0.5rem);
}
@keyframes dash {
  to {
    stroke-dashoffset: 541;
  }
}

.znd-main-navigation {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  box-shadow: 0 1px 0 #d1dae1;
  background-color: #ffffff;
  width: 100vw;
}
@media (min-width: 1200px) {
  .znd-main-navigation {
    right: auto;
    bottom: 0;
    box-shadow: 1px 0 0 #d1dae1;
    width: 5rem;
  }
}
.znd-main-navigation__header {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  background-color: #ffffff;
  padding-right: 1.375rem;
  padding-left: 1.375rem;
  height: 3.875rem;
}
@media (min-width: 1200px) {
  .znd-main-navigation__header {
    display: none;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    height: auto;
  }
}
.znd-main-navigation__logo {
  height: 2.625rem;
}
@media (min-width: 1200px) {
  .znd-main-navigation__logo {
    visibility: hidden;
  }
}
.znd-main-navigation__buttons {
  margin: 0 0 0 auto;
}
.znd-main-navigation__hamburger {
  margin: 0 0 0 1rem;
}
@media (min-width: 375px) {
  .znd-main-navigation__hamburger {
    margin-left: 1.5rem;
  }
}
.znd-main-navigation__search-button {
  margin: 0 0 0 1rem;
}
@media (min-width: 375px) {
  .znd-main-navigation__search-button {
    margin-left: 1.5rem;
  }
}
.znd-main-navigation__search-button.transition-fade-exit-done {
  visibility: hidden;
  opacity: 0;
}
.znd-main-navigation__help-button {
  z-index: 1050;
  margin: 0 0 0 1rem;
  border-radius: 50%;
  background-color: #ffffff;
}
@media (min-width: 375px) {
  .znd-main-navigation__help-button {
    margin-left: 1.5rem;
  }
}
.znd-main-navigation__help-button.transition-fade-exit-done {
  visibility: hidden;
  opacity: 0;
}
.znd-main-navigation__notification-button {
  z-index: 1050;
  margin: 0 0 0 1rem;
  border-radius: 50%;
  background-color: #ffffff;
}
@media (min-width: 375px) {
  .znd-main-navigation__notification-button {
    margin-left: 1.5rem;
  }
}
.znd-main-navigation__notification-button.transition-fade-exit-done {
  visibility: hidden;
  opacity: 0;
}
.znd-main-navigation__notification-button__icon-disabled {
  color: #212525;
}
.znd-main-navigation__notification-button__badge {
  display: flex;
  position: absolute;
  top: -0.25rem;
  right: -0.125rem;
  justify-content: center;
  align-items: center;
  z-index: 110;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  background-color: #212525;
  min-width: 1.125rem;
  color: #ffffff;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
}
.znd-main-navigation__notification-button__badge__content {
  transform: translateY(1px);
}
.znd-main-navigation__hamburger, .znd-main-navigation__search-button, .znd-main-navigation__notification-button, .znd-main-navigation__help-button {
  outline: none;
  border: none;
  padding: 0;
  font-size: 1.125rem;
  line-height: 0;
}
.znd-main-navigation__hamburger, .znd-main-navigation__hamburger:hover, .znd-main-navigation__hamburger:focus, .znd-main-navigation__hamburger:active, .znd-main-navigation__search-button, .znd-main-navigation__search-button:hover, .znd-main-navigation__search-button:focus, .znd-main-navigation__search-button:active, .znd-main-navigation__notification-button, .znd-main-navigation__notification-button:hover, .znd-main-navigation__notification-button:focus, .znd-main-navigation__notification-button:active, .znd-main-navigation__help-button, .znd-main-navigation__help-button:hover, .znd-main-navigation__help-button:focus, .znd-main-navigation__help-button:active {
  color: #e4032e;
}
@media (min-width: 1200px) {
  .znd-main-navigation__hamburger, .znd-main-navigation__search-button, .znd-main-navigation__notification-button, .znd-main-navigation__help-button {
    display: none;
  }
}
.znd-main-navigation__backdrop-nav {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
@media (min-width: 768px) {
  .znd-main-navigation__backdrop-nav {
    display: block;
    position: absolute;
    top: 0;
    right: 18.75rem;
    left: -1.875rem;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
  }
}
@media (min-width: 1200px) {
  .znd-main-navigation__backdrop-nav {
    display: none;
  }
}
.znd-main-navigation__backdrop-notification {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
@media (min-width: 576px) {
  .znd-main-navigation__backdrop-notification {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
  }
}
.znd-main-navigation__backdrop-search {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
}
.znd-main-navigation__backdrop-search--transition-enter {
  display: block;
  opacity: 0;
}
.znd-main-navigation__backdrop-search--transition-enter-active {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}
.znd-main-navigation__backdrop-search--transition-enter-done {
  display: block;
}
.znd-main-navigation__backdrop-search--transition-exit {
  display: block;
  opacity: 1;
}
.znd-main-navigation__backdrop-search--transition-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
@media (min-width: 1200px) {
  .znd-main-navigation__backdrop-search {
    display: none;
  }
}
.znd-main-navigation__content {
  display: flex;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  flex-direction: column;
  z-index: 1;
  height: calc(100vh - 3.875rem);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .znd-main-navigation__content {
    left: auto;
    width: 18.75rem;
  }
}
@media (min-width: 1200px) {
  .znd-main-navigation__content {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    visibility: visible;
    width: auto;
    overflow: visible;
  }
}
.znd-main-navigation__content--transition-enter {
  transform: translateY(-1.875rem);
  opacity: 0;
}
@media (min-width: 768px) {
  .znd-main-navigation__content--transition-enter {
    transform: translateX(1.875rem);
  }
}
.znd-main-navigation__content--transition-exit {
  transform: translate(0, 0);
  opacity: 1;
}
.znd-main-navigation__content--transition-enter-active {
  transform: translate(0, 0);
  visibility: visible;
  opacity: 1;
}
.znd-main-navigation__content--transition-exit-active {
  transform: translateY(-1.875rem);
  visibility: visible;
  opacity: 0;
}
@media (min-width: 768px) {
  .znd-main-navigation__content--transition-exit-active {
    transform: translateX(1.875rem);
  }
}
.znd-main-navigation__content--transition-enter-active, .znd-main-navigation__content--transition-exit-active {
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}
.znd-main-navigation__content--transition-enter-done {
  visibility: visible;
  pointer-events: auto;
}
.znd-main-navigation__content--transition-exit-done {
  visibility: hidden;
}
@media (min-width: 1200px) {
  .znd-main-navigation__content.znd-main-navigation__content--transition-exit-done {
    visibility: visible;
  }
}
.znd-main-navigation__nav {
  padding-top: 0.375rem;
}
.znd-main-navigation__user {
  margin-top: auto;
  margin-bottom: 3.75rem;
  padding-right: 1.25rem;
  padding-bottom: 0.875rem;
  padding-left: 1.25rem;
}
@media (min-width: 768px) {
  .znd-main-navigation__user {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .znd-main-navigation__user {
    display: none;
  }
}
.znd-main-navigation .znd-main-navigation__loading {
  right: 3rem;
}
.znd-main-navigation__search {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: stretch;
  z-index: 5;
}
@media (min-width: 1200px) {
  .znd-main-navigation__search.znd-main-navigation__search {
    display: none;
  }
}
.znd-main-navigation__search-back {
  display: flex;
  position: relative;
  flex: 0 0 3.875rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 0;
  background-color: #ffffff;
  padding: 0;
  width: 3.875rem;
  font-size: 1.125rem;
}
.znd-main-navigation__search-back, .znd-main-navigation__search-back:hover, .znd-main-navigation__search-back:focus, .znd-main-navigation__search-back:active {
  color: #e4032e;
}
.znd-main-navigation__search-back::after {
  display: block;
  position: absolute;
  top: 1rem;
  right: 0;
  bottom: 1rem;
  border-right: 1px solid #d1dae1;
  content: "";
}
.znd-main-navigation__search-input-wrapper {
  display: flex;
  position: relative;
  flex: 1 1 100%;
  flex-direction: row;
  align-items: stretch;
  background-color: #ffffff;
}
.znd-main-navigation__search-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
.znd-main-navigation__search-clear, .znd-main-navigation__search-icon {
  display: flex;
  flex: 0 0 3.875rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 0;
  background-color: #ffffff;
  padding: 0;
  width: 3.875rem;
  font-size: 1.125rem;
}
.znd-main-navigation__search-clear, .znd-main-navigation__search-clear:hover, .znd-main-navigation__search-clear:focus, .znd-main-navigation__search-clear:active, .znd-main-navigation__search-icon, .znd-main-navigation__search-icon:hover, .znd-main-navigation__search-icon:focus, .znd-main-navigation__search-icon:active {
  color: #4c575d;
}
.znd-main-navigation__search-input {
  flex: 1 1 100%;
  font-family: "IBMPlexSans", -apple-system, "Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  border: none;
  background: none;
  padding-left: 3.875rem;
  color: #212525;
}
.znd-main-navigation__search-input, .znd-main-navigation__search-input:hover, .znd-main-navigation__search-input:focus, .znd-main-navigation__search-input:active {
  outline: none;
}
.znd-main-navigation__search-input::placeholder {
  color: #74797c;
}
.znd-main-navigation__search--transition-enter {
  display: flex;
}
.znd-main-navigation__search--transition-enter .znd-main-navigation__search-input-wrapper {
  transform: translateX(1.875rem);
  opacity: 0;
}
.znd-main-navigation__search--transition-enter .znd-main-navigation__search-back {
  transform: translateX(-1.875rem);
  opacity: 0;
}
.znd-main-navigation__search--transition-enter .znd-main-navigation__search-back::after {
  opacity: 0;
}
.znd-main-navigation__search--transition-enter-active .znd-main-navigation__search-back,
.znd-main-navigation__search--transition-enter-active .znd-main-navigation__search-input-wrapper {
  transform: translateX(0);
  opacity: 1;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}
.znd-main-navigation__search--transition-enter-active .znd-main-navigation__search-back::after,
.znd-main-navigation__search--transition-enter-active .znd-main-navigation__search-input-wrapper::after {
  opacity: 1;
  transition: opacity 0.2s ease-out;
  transition-delay: 0.2s;
}
.znd-main-navigation__search--transition-enter-done {
  display: flex;
}
.znd-main-navigation__search--transition-exit {
  display: flex;
}
.znd-main-navigation__search--transition-exit .znd-main-navigation__search-back,
.znd-main-navigation__search--transition-exit .znd-main-navigation__search-input-wrapper {
  transform: translateX(0);
  opacity: 1;
}
.znd-main-navigation__search--transition-exit .znd-main-navigation__search-back::after,
.znd-main-navigation__search--transition-exit .znd-main-navigation__search-input-wrapper::after {
  opacity: 1;
}
.znd-main-navigation__search--transition-exit-active .znd-main-navigation__search-input-wrapper {
  transform: translateX(1.875rem);
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}
.znd-main-navigation__search--transition-exit-active .znd-main-navigation__search-back {
  transform: translateX(-1.875rem);
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}

.znd-main-navigation-item {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 1.75rem 1.875rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
@media (min-width: 1200px) {
  .znd-main-navigation-item {
    padding: 1.375rem 1.875rem;
  }
}
.znd-main-navigation-item, .znd-main-navigation-item:hover {
  color: #74797c;
  text-decoration: none;
}
.znd-main-navigation-item--active, .znd-main-navigation-item--active:hover {
  color: #e4032e;
}
.znd-main-navigation-item__icon {
  transition: color 0.4s ease-out;
  margin-right: 1.875rem;
  font-size: 1.5rem;
  line-height: 0;
}
.znd-main-navigation-item__label {
  transition: color 0.4s ease-out;
}
@media (min-width: 1200px) {
  .znd-main-navigation-item__label {
    display: none;
  }
}
.znd-main-navigation-item::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-3px);
  transition: transform 0.2s ease-out;
  margin-top: -1.875rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #e4032e;
  width: 3px;
  height: 3.75rem;
  content: "";
}
.znd-main-navigation-item:hover::before, .znd-main-navigation-item--active::before {
  transform: translateX(0);
}

.znd-message-modal__button {
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 100%;
}
@media (min-width: 768px) {
  .znd-message-modal__button {
    margin-bottom: 0;
    width: auto;
  }
}

.znd-modal.modal {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100dvh;
}
.znd-modal__header {
  position: relative;
  border-bottom: 1px solid #d1dae1;
}
.znd-modal__title {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  text-transform: none;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.znd-modal__close {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 4rem;
  color: #e4032e;
  font-size: 1.25rem;
}
.znd-modal__body {
  padding: 1.25rem 2.5rem;
}
.znd-modal__footer {
  padding: 1rem 2.25rem;
}
@media (max-width: 575.98px) {
  .znd-modal__content {
    border-radius: 0;
    min-height: 100dvh;
  }
}
.znd-modal .modal-xl {
  margin-top: 3.75rem;
}
@media (min-width: 576px) {
  .znd-modal .modal-xl {
    padding: 1.875rem 2.5rem;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .znd-modal .modal-xl {
    max-width: calc(1600px + 5rem);
  }
}
.znd-modal__scrollable {
  overflow-x: hidden;
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .znd-modal__scrollable .modal-dialog .modal-body {
    overflow-y: auto;
  }
}
.znd-modal .modal-dialog-centered::before {
  height: 100dvh;
}
@media (min-width: 576px) {
  .znd-modal .modal-dialog-centered::before {
    height: calc(100dvh - 3.5rem);
  }
}
.znd-modal--as-desktop .modal-dialog {
  margin: 1.75rem auto;
  max-width: 400px;
}
.znd-modal--as-desktop .modal-dialog-scrollable {
  max-height: calc(100% - 3.5rem);
}
.znd-modal--as-desktop .modal-dialog-scrollable .modal-content {
  max-height: calc(100dvh - 3.5rem);
}
.znd-modal--as-desktop .modal-dialog-centered {
  min-height: calc(100% - 3.5rem);
}
.znd-modal--as-desktop .modal-dialog-centered::before {
  height: calc(100dvh - 3.5rem);
  height: min-content;
}
.znd-modal--as-desktop .modal-sm {
  max-width: 21.25rem;
}
.znd-modal--as-desktop .znd-modal__content {
  border-radius: 0.3rem;
  min-height: 0;
}
@media (max-width: 575.98px) {
  .znd-modal--as-desktop .znd-modal__body {
    padding: 1.25rem;
  }
}

.modal-backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100dvh;
}

.znd-my-documents-page {
  margin-top: 1.25rem;
}

.znd-notification-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ececed;
  padding: 1.25rem 1.5rem;
  color: #212525;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
}
.znd-notification-item:hover {
  cursor: default;
}
.znd-notification-item__label {
  text-align: left;
}
.znd-notification-item--link:hover {
  cursor: pointer;
  background-color: #ececed;
  color: #4c575d;
}
.znd-notification-item--link:hover .znd-notification-item__icon-info {
  background-color: #e4032e;
}

.znd-notifications-button {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  z-index: 1050;
  cursor: pointer;
}
.znd-notifications-button__name {
  padding-left: 0.625rem;
}
@media (min-width: 1200px) {
  .znd-notifications-button__name {
    display: none;
  }
}
.znd-notifications-button__dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1.25rem;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
}
.znd-notifications-button__dropdown-item, .znd-notifications-button__dropdown-item:hover, .znd-notifications-button__dropdown-item:focus, .znd-notifications-button__dropdown-item:active {
  color: #212525;
}
.znd-notifications-button__dropdown-item:hover, .znd-notifications-button__dropdown-item:focus, .znd-notifications-button__dropdown-item:active {
  background-color: rgba(236, 236, 237, 0.4);
}
.znd-notifications-button__dropdown-item i {
  margin-right: 0.5rem;
  color: #c4c8c8;
  font-size: 1.25rem;
}

.znd-notifications-dropdown {
  position: absolute;
  top: 100%;
  right: -0.75rem;
  bottom: auto;
  left: auto;
  transform: translateY(1.25rem);
  z-index: 1040;
  border-radius: 0.375rem;
  background-color: #ffffff;
  min-width: 26.25rem;
  min-height: 25rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1200px) {
  .znd-notifications-dropdown {
    right: 0;
  }
}
.znd-notifications-dropdown::before {
  display: block;
  position: absolute;
  top: 100%;
  left: 1.5rem;
  transform: translateX(-50%) translateY(-0.125rem);
  border: 0.625rem solid transparent;
  border-top: 0.75rem solid #ffffff;
  width: 0;
  content: "";
}
@media (min-width: 576px) {
  .znd-notifications-dropdown::before {
    top: auto;
    right: 1.5rem;
    bottom: 100%;
    left: auto;
    transform: translateX(50%) translateY(2px);
    border: 0.625rem solid transparent;
    border-bottom: 0.75rem solid #ffffff;
  }
}
.znd-notifications-dropdown__title {
  border-bottom: 1px solid #ececed;
  padding: 0.75rem 1.25rem;
  color: #212525;
  text-align: left;
}
.znd-notifications-dropdown__item-wrapper {
  max-height: 21rem;
  overflow-y: scroll;
}

.znd-offers-table {
  overflow-y: scroll;
}
.znd-offers-table.znd-offers-table .znd-offers-table__checkout {
  margin: 0.75rem 0;
  padding: 0.625rem 2.75rem 0.625rem 1rem;
}
.znd-offers-table.znd-offers-table .znd-offers-table__checkout--button:hover {
  background-color: #d1dae1;
}
.znd-offers-table__sub-table {
  margin: 0 3.25rem 0 auto;
}
.znd-offers-table__sub-table__row td {
  padding: 0.25rem 0;
  color: #212525;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.znd-offers-table__sub-table__row td:first-child {
  padding-right: 1.5rem;
}
.znd-offers-table__sub-table__row td:last-child {
  text-align: right;
}
.znd-offers-table__sub-table__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.znd-offers-table__sub-table__image-wrapper {
  position: relative;
  flex: 0 0 auto;
  margin: 1rem 1rem 1rem 0;
  border: 1px solid #d1dae1;
  border-radius: 0.25rem;
  width: 7.75rem;
  height: 4rem;
}

.znd-orders-table-sub {
  margin: 1rem 1rem 1.5rem 4.125rem;
}
.znd-orders-table-sub__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.znd-orders-table-sub__image-wrapper {
  position: relative;
  flex: 0 0 auto;
  margin: 1rem 1rem 1rem 0;
  border: 1px solid #d1dae1;
  border-radius: 0.25rem;
  width: 7.75rem;
  height: 4rem;
}
.znd-orders-table-sub__table {
  flex: 1 1 auto;
}
.znd-orders-table-sub__prices {
  margin: 0 3.25rem 0 auto;
}
.znd-orders-table-sub__prices-total td {
  color: #4c575d;
  font-weight: 700;
}
.znd-orders-table-sub__prices-sub td {
  color: #212525;
}
.znd-orders-table-sub__prices-total td, .znd-orders-table-sub__prices-sub td {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.znd-orders-table-sub__prices-total td:first-child, .znd-orders-table-sub__prices-sub td:first-child {
  padding-right: 1.5rem;
}
.znd-orders-table-sub__prices-total td:last-child, .znd-orders-table-sub__prices-sub td:last-child {
  text-align: right;
}
.znd-orders-table-sub__column {
  width: auto;
  min-width: 20%;
  max-width: 40%;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.znd-orders-table-sub__column-label {
  color: #4c575d;
  font-weight: 700;
}
.znd-orders-table-sub__column-comment {
  margin-bottom: 0;
  max-width: 31.25rem;
}
@media (min-width: 1440px) {
  .znd-orders-table-sub__column {
    width: 16.25rem;
    min-width: auto;
    max-width: none;
  }
}
.znd-orders-table-sub__info-box {
  width: 30rem;
  max-width: none;
}

.znd-product-card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 1.25rem;
  width: 100%;
  overflow: hidden;
}
.znd-product-card-wrapper {
  display: flex;
  flex: 0 0 100%;
  padding: 0.625rem;
  width: 100%;
  max-width: none;
}
@media (min-width: 768px) {
  .znd-product-card-wrapper {
    flex: 0 0 50%;
    width: auto;
    max-width: 50%;
  }
}
@media (min-width: 1200px) {
  .znd-product-card-wrapper {
    flex: 0 0 25%;
    width: auto;
    max-width: 25%;
  }
}
@media (min-width: 1440px) {
  .znd-product-card-wrapper {
    flex: 0 0 16.66%;
    width: auto;
    max-width: 16.66%;
  }
}
.znd-product-card__image-wrapper {
  position: relative;
  border: 1px solid #d1dae1;
  border-radius: 5px;
  overflow: hidden;
}
.znd-product-card__image-wrapper::before {
  display: block;
  padding-top: 52%;
  content: "";
}
.znd-product-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.znd-product-card__title {
  margin-top: 1.25rem;
  color: #212525;
}
.znd-product-card__description {
  margin-top: 0.375rem;
  color: #212525;
}
.znd-product-card__footer {
  margin-top: auto;
}
.znd-product-card__price {
  margin-top: 1.875rem;
  color: #212525;
}
.znd-product-card__price-info {
  margin-top: 0.375rem;
  margin-bottom: 1.25rem;
  color: #74797c;
}

.znd-product-filter__row--price input {
  -moz-appearance: textfield;
}
.znd-product-filter__row--price input::-webkit-outer-spin-button, .znd-product-filter__row--price input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
.znd-product-filter__row + .znd-product-filter__row {
  margin-top: 1.25rem;
}
.znd-product-filter__label {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 0.375rem;
  color: #212525;
  font-weight: 700;
}
.znd-product-filter__btn-clear {
  justify-content: center;
  margin-top: 1.25rem;
  width: 100%;
}

@media (max-width: 767.98px) {
  .znd-product-filter-modal__button {
    justify-content: center;
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .znd-product-list {
    flex-wrap: wrap;
  }
}
.znd-product-list__headline-price {
  margin-bottom: 0.5rem;
  padding-right: 9.125rem;
  color: #74797c;
  text-align: right;
}
.znd-product-list--cards {
  margin-left: -0.625rem;
  width: calc(100% + 1.25rem);
}

.znd-product-list-item {
  margin-bottom: 0.75rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 1.25rem;
  width: 100%;
}
.znd-product-list-item-wrapper {
  flex: 0 0 auto;
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .znd-product-list-item-wrapper {
    flex: 0 0 calc(50% - 0.75rem);
    margin-bottom: 0.5rem;
    width: calc(50% - 0.75rem);
  }
  .znd-product-list-item-wrapper:nth-child(2n+1) {
    margin-left: 1.25rem;
  }
}
@media (min-width: 992px) {
  .znd-product-list-item-wrapper {
    flex: auto;
  }
}
.znd-product-list-item__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.znd-product-list-item__image-wrapper {
  position: relative;
  flex: 0 0 auto;
  border: 1px solid #d1dae1;
  border-radius: 0.25rem;
  width: 100%;
}
.znd-product-list-item__image-wrapper::before {
  display: block;
  padding-top: 51.38%;
  content: "";
}
@media (min-width: 992px) {
  .znd-product-list-item__image-wrapper {
    width: 7.75rem;
    height: 4rem;
  }
  .znd-product-list-item__image-wrapper::before {
    display: none;
  }
}
.znd-product-list-item__title {
  color: #4c575d;
}
.znd-product-list-item__description {
  flex: 1 1 auto;
  color: #212525;
}
@media (min-width: 992px) {
  .znd-product-list-item__description {
    flex: 1 1 50%;
    padding: 0 1.25rem;
  }
}
.znd-product-list-item__text-wrapper {
  padding: 1.5rem 0 0.5rem;
  width: 100%;
}
@media (min-width: 992px) {
  .znd-product-list-item__text-wrapper {
    flex: 1 1 50%;
    padding: 0 1.25rem;
    min-width: 15rem;
  }
}
.znd-product-list-item__secondary-description {
  width: 100%;
  overflow: hidden;
  color: #212525;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .znd-product-list-item__secondary-description {
    max-width: 25rem;
  }
}
.znd-product-list-item__price {
  flex: 0 0 auto;
  padding: 1.75rem 0 1.5rem;
  min-width: auto;
  color: #212525;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .znd-product-list-item__price {
    flex: 0 0 18%;
    padding-right: 2.5rem;
    min-width: 11.25rem;
  }
}
@media (min-width: 1200px) {
  .znd-product-list-item__price {
    flex: 0 0 16%;
  }
}
@media (min-width: 1440px) {
  .znd-product-list-item__price {
    flex: 0 0 13%;
  }
}
.znd-product-list-item__price-per-unit {
  margin-left: 0.25rem;
  color: #74797c;
  line-height: 1.375rem;
}
@media (min-width: 992px) {
  .znd-product-list-item__price-per-unit {
    margin: 0;
    line-height: 1.25rem;
  }
}
.znd-product-list-item__price-amount-from {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
  color: #74797c;
}
.znd-product-list-item__btn-add {
  flex: 0 0 auto;
}
.znd-product-list-item__btn-add-link {
  width: 100%;
}
@media (min-width: 992px) {
  .znd-product-list-item__btn-add-link {
    width: auto;
  }
}

.znd-product-price__normal-price {
  color: #c4c8c8;
  text-decoration: line-through;
}

.znd-region-modal__select {
  max-width: 100%;
}
.znd-region-modal__button {
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 100%;
}
@media (min-width: 768px) {
  .znd-region-modal__button {
    margin-bottom: 0;
    width: auto;
  }
}

.znd-registration-company-information {
  margin: 0 auto;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 100%;
  max-width: 51.75rem;
  color: #4c575d;
}
@media (min-width: 576px) {
  .znd-registration-company-information {
    padding: 1.25rem;
  }
}
.znd-registration-company-information__title {
  margin-left: 1.25rem;
}
@media (min-width: 576px) {
  .znd-registration-company-information__title {
    margin-left: 0;
  }
}
.znd-registration-company-information__container {
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 2.5rem;
  min-height: 34.875rem;
}
.znd-registration-company-information__tax-input {
  margin-bottom: 3.75rem;
  width: 100%;
}
@media (min-width: 576px) {
  .znd-registration-company-information__tax-input {
    width: 48%;
  }
}
.znd-registration-company-information__select-header {
  margin-top: 1.5rem;
  font-weight: 700;
}
.znd-registration-company-information__state-select, .znd-registration-company-information__country-select {
  max-width: 100%;
}
.znd-registration-company-information__zip-container, .znd-registration-company-information__country-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.znd-registration-company-information__input-header {
  margin-top: 1.5rem;
  font-weight: 700;
}
.znd-registration-company-information__input-container {
  width: 100%;
}
@media (min-width: 576px) {
  .znd-registration-company-information__input-container {
    width: 48%;
  }
}
.znd-registration-company-information__city-input {
  width: 100%;
}
.znd-registration-company-information__button {
  z-index: 10;
  margin: 2.5rem auto 0;
}
@media (min-width: 768px) {
  .znd-registration-company-information__button {
    position: absolute;
    right: 2.5rem;
    bottom: 2.5rem;
  }
}
.znd-registration-company-information__divider {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  border-top: 1px solid #d1dae1;
}

.znd-registration-personal-information {
  margin: 0 auto;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 100%;
  max-width: 51.75rem;
  color: #4c575d;
}
@media (min-width: 576px) {
  .znd-registration-personal-information {
    padding: 1.25rem;
  }
}
.znd-registration-personal-information__title,
.znd-registration-personal-information p {
  margin-left: 1.25rem;
}
@media (min-width: 576px) {
  .znd-registration-personal-information__title,
  .znd-registration-personal-information p {
    margin-left: 0;
  }
}
.znd-registration-personal-information__container {
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 2.5rem;
  min-height: 34.875rem;
}
.znd-registration-personal-information__select-header {
  font-weight: 700;
}
.znd-registration-personal-information__salutation-select {
  max-width: 100%;
  height: 3.125rem;
}
@media (min-width: 576px) {
  .znd-registration-personal-information__salutation-select {
    max-width: 9.875rem;
  }
}
.znd-registration-personal-information__name-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.znd-registration-personal-information__input-header {
  margin-top: 1.5rem;
  font-weight: 700;
}
.znd-registration-personal-information__input-container {
  width: 100%;
}
@media (min-width: 576px) {
  .znd-registration-personal-information__input-container {
    width: 48%;
  }
}
.znd-registration-personal-information__phone-input {
  width: 100%;
}
@media (min-width: 576px) {
  .znd-registration-personal-information__phone-input {
    width: 48%;
  }
}
.znd-registration-personal-information__terms-container {
  margin-top: 3.25rem;
  margin-bottom: 0.5rem;
  padding-top: 2.625rem;
}
.znd-registration-personal-information__checkbox-option {
  cursor: pointer;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.znd-registration-personal-information__checkbox-option:hover {
  background-color: rgba(236, 236, 237, 0.5);
}
.znd-registration-personal-information__checkbox-label {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  cursor: pointer;
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #212525;
}
.znd-registration-personal-information__checkbox-label .custom-control-label::before, .znd-registration-personal-information__checkbox-label .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
}
.znd-registration-personal-information__divider {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
  border-top: 1px solid #d1dae1;
}
.znd-registration-personal-information__button {
  margin: 0 auto;
}
@media (min-width: 576px) {
  .znd-registration-personal-information__button {
    position: absolute;
    right: 2.5rem;
    bottom: 2.5rem;
  }
}
.znd-registration-personal-information__existing-company-button {
  z-index: 10;
  margin: 2.5rem auto 0;
}
@media (min-width: 768px) {
  .znd-registration-personal-information__existing-company-button {
    position: absolute;
    right: 2.5rem;
    bottom: 2.5rem;
  }
}
.znd-registration-personal-information__role-select-header, .znd-registration-personal-information__department-select-header {
  margin-top: 1.5rem;
  font-weight: 700;
}
.znd-registration-personal-information__role-select, .znd-registration-personal-information__department-select {
  width: 100%;
  height: 3.125rem;
}
@media (min-width: 576px) {
  .znd-registration-personal-information__role-select, .znd-registration-personal-information__department-select {
    max-width: 48%;
  }
}
.znd-registration-personal-information__department-select {
  margin-bottom: 2.625rem;
}

.znd-registration-sign-up {
  margin: 0 auto;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 100%;
  max-width: 51.75rem;
  color: #4c575d;
}
@media (min-width: 576px) {
  .znd-registration-sign-up {
    padding: 1.25rem;
  }
}
.znd-registration-sign-up__title {
  margin-left: 1.25rem;
}
@media (min-width: 576px) {
  .znd-registration-sign-up__title {
    margin-left: 0;
  }
}
.znd-registration-sign-up__container {
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 2.5rem;
  min-height: 19.875rem;
}
.znd-registration-sign-up__subhead {
  margin-bottom: 2rem;
}
.znd-registration-sign-up__select-header {
  margin-bottom: 0.25rem;
  font-weight: 700;
}
.znd-registration-sign-up__country-select {
  margin-bottom: 1.5rem;
  height: 3.125rem;
}
@media (min-width: 576px) {
  .znd-registration-sign-up__country-select {
    max-width: 21.375rem;
  }
}
.znd-registration-sign-up__reseller-select {
  max-width: 100%;
}
.znd-registration-sign-up__customer-title {
  margin-top: 3.75rem;
}
.znd-registration-sign-up__radio-content-container {
  margin-right: 3rem;
  margin-bottom: 1.5rem;
  margin-left: 3rem;
}
@media (min-width: 576px) {
  .znd-registration-sign-up__radio-content-container {
    width: 24.375rem;
  }
}
.znd-registration-sign-up__radio-container-unfolded {
  margin-top: -0.75rem;
}
.znd-registration-sign-up__radio-container-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.znd-registration-sign-up__radio-container-row label {
  visibility: hidden;
  margin-right: 1rem;
  margin-left: 2rem;
  padding-top: 0.875rem;
  font-weight: 700;
}
@media (min-width: 576px) {
  .znd-registration-sign-up__radio-container-row {
    flex-direction: row;
    width: auto;
  }
  .znd-registration-sign-up__radio-container-row label {
    visibility: visible;
  }
  .znd-registration-sign-up__radio-container-row [name=zip] {
    max-width: 10rem;
  }
}
.znd-registration-sign-up__serial-number-title {
  margin-bottom: 0.25rem;
  font-weight: 700;
}
.znd-registration-sign-up__customer-info-container {
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem;
}
.znd-registration-sign-up__customer-info-icon {
  margin-right: 0.563rem;
  color: #74797c;
}
.znd-registration-sign-up__customer-info-text {
  color: #74797c;
}
.znd-registration-sign-up__customer-type-container {
  margin-bottom: 2.5rem;
  border: 1px solid #d1dae1;
  border-radius: 0.375rem;
}
@media (min-width: 576px) {
  .znd-registration-sign-up__customer-type-container {
    margin-bottom: 9.375rem;
  }
}
.znd-registration-sign-up__customer-existing-container {
  border-bottom: 1px solid #d1dae1;
}
.znd-registration-sign-up__customer-existing-container:last-child {
  border-bottom: 0 none transparent;
}
.znd-registration-sign-up__button {
  margin: 0 auto;
}
.znd-registration-sign-up__button:disabled {
  background-color: #ececed;
  color: #c4c8c8;
}
@media (min-width: 576px) {
  .znd-registration-sign-up__button {
    position: absolute;
    right: 2.5rem;
    bottom: 2.5rem;
  }
}

.znd-registration-success {
  margin: 0 auto;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 100%;
  max-width: 31.25rem;
  color: #4c575d;
}
.znd-registration-success__container {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  max-height: 26.25rem;
}
@media (min-width: 576px) {
  .znd-registration-success__container {
    margin-top: 2.5rem;
    padding: 3.125rem;
  }
}
.znd-registration-success__icon-container {
  display: flex;
  position: absolute;
  top: -3rem;
  left: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  border-radius: 3.125rem;
  background-color: #24a14b;
  width: 6.25rem;
  height: 6.25rem;
}
.znd-registration-success__icon {
  color: #ffffff;
  font-size: 3.625rem;
}
.znd-registration-success__title {
  margin-top: 2rem;
  margin-bottom: 1.25rem;
  text-align: center;
}
.znd-registration-success__text {
  margin-bottom: 3.75rem;
  font-weight: 400;
  text-align: center;
}
.znd-registration-success__button {
  align-self: flex-end;
  margin: 0 auto;
}

.znd-registration-verify-company {
  margin: 0 auto;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  width: 100%;
  max-width: 51.75rem;
  color: #4c575d;
}
@media (min-width: 576px) {
  .znd-registration-verify-company {
    padding: 1.25rem;
  }
}
.znd-registration-verify-company__title {
  margin-left: 1.25rem;
}
@media (min-width: 576px) {
  .znd-registration-verify-company__title {
    margin-left: 0;
  }
}
.znd-registration-verify-company__container {
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 2.5rem;
  min-height: 23.375rem;
}
.znd-registration-verify-company__info-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 2.5rem;
  max-width: 39rem;
}
.znd-registration-verify-company__info-icon {
  margin-right: 0.563rem;
  color: #74797c;
}
.znd-registration-verify-company__info-text {
  color: #74797c;
}
.znd-registration-verify-company__company-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3.75rem;
  border: 1px solid #d1dae1;
  border-radius: 0.375rem;
  padding: 1.5rem;
  min-height: 6.5rem;
}
@media (min-width: 576px) {
  .znd-registration-verify-company__company-container {
    padding: 2.565rem;
  }
}
.znd-registration-verify-company__company-icon {
  margin-right: 1rem;
  width: 1.375rem;
  height: 1.125rem;
  color: #e4032e;
  font-size: 1.25rem;
}
.znd-registration-verify-company__button-container {
  width: 100%;
}
@media (min-width: 576px) {
  .znd-registration-verify-company__button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
.znd-registration-verify-company__button-decline, .znd-registration-verify-company__button-accept {
  margin: 0 auto;
  padding-right: 2.25rem;
  padding-left: 2.25rem;
}
@media (min-width: 576px) {
  .znd-registration-verify-company__button-decline, .znd-registration-verify-company__button-accept {
    margin: 0 0 0 0.75rem;
  }
}
.znd-registration-verify-company__button-decline {
  margin-bottom: 1.5rem;
  background-color: #ececed;
  color: #e4032e;
}
@media (min-width: 576px) {
  .znd-registration-verify-company__button-decline {
    margin-bottom: 0;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.znd-reseller-file-explorer {
  margin: 1.25rem;
  border-radius: 0.375rem;
  background: #ffffff;
  padding: 1.875rem;
  width: calc(100vw - 2.5rem);
  max-width: 96rem;
  overflow-x: auto;
  overflow-y: hidden;
}
@media (min-width: 992px) {
  .znd-reseller-file-explorer {
    margin: 2.5rem;
  }
}
.znd-reseller-file-explorer__tree {
  position: relative;
  border-radius: inherit;
  width: 100%;
}
.znd-reseller-file-explorer__tree__node {
  padding: 0.05rem;
}
.znd-reseller-file-explorer__tree__label-selected {
  color: #e4032e;
}
.znd-reseller-file-explorer__tree-icon {
  display: block;
  margin-left: auto;
  color: #e4032e !important;
}
.znd-reseller-file-explorer__files {
  padding: 0.05rem;
}

.znd-reseller-modal__select {
  max-width: 100%;
}
.znd-reseller-modal__button {
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 100%;
}
@media (min-width: 768px) {
  .znd-reseller-modal__button {
    margin-bottom: 0;
    width: auto;
  }
}

.znd-reseller-page {
  margin-top: 1.25rem;
}
.znd-reseller-page__content {
  margin: 1.25rem;
}
@media (min-width: 576px) {
  .znd-reseller-page__content {
    margin: 2.5rem;
  }
}
.znd-reseller-page__file-explorer {
  margin-left: 2.5rem;
  background: white;
}
.znd-reseller-page__title {
  margin-left: 2.5rem;
  padding: 7px;
}
.znd-reseller-page p {
  margin: 0;
  padding: 4px;
}

.znd-return-table-input__select-header, .znd-return-table-input__input-header {
  margin-bottom: 0.375rem;
  font-weight: 700;
}
.znd-return-table-input__body {
  max-height: calc(100vh - 13.25rem);
}
.znd-return-table-input__body .required {
  color: #e4032e;
}
.znd-return-table-input__body .form-control, .znd-return-table-input__body .znd-multiselect {
  margin-bottom: 1.25rem;
}
.znd-return-table-input__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.znd-return-table-input__col {
  max-width: 30%;
}
.znd-return-table-input__select {
  margin-bottom: 1.25rem;
  max-width: 50%;
}
.znd-return-table-input__select-country, .znd-return-table-input__select-state {
  width: 100%;
  max-width: 99%;
}
.znd-return-table-input__checkbox-label {
  cursor: pointer;
  margin-bottom: 0;
}
.znd-return-table-input__checkbox-label .custom-control-label {
  padding-left: 0.375rem;
}
.znd-return-table-input__checkbox-label .custom-control-label::before, .znd-return-table-input__checkbox-label .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
  width: 1.375rem;
  height: 1.375rem;
}
.znd-return-table-input__checkbox-label .custom-control-label::before {
  border-radius: 0.25rem;
}
.znd-return-table-input__checkbox-label .custom-control-label::after {
  left: -1.75rem;
}
.znd-return-table-input__button {
  width: max-content;
}

.znd-root__loader {
  height: 100vh;
}
.znd-root__content {
  display: flex;
  position: relative;
  flex: 1 0 auto;
  flex-direction: column;
}

.znd-shop-select-address__subhead {
  margin-bottom: 1.25rem;
  color: #212525;
}
.znd-shop-select-address__subhead:last-child {
  margin-top: 2.75rem;
}
.znd-shop-select-address__subhead--section {
  margin-top: 3rem;
}
.znd-shop-select-address__details {
  margin-top: 1.5rem;
}
.znd-shop-select-address__loading {
  margin-top: 1.5rem;
  max-width: 16.25rem;
}

.znd-shop-cart-page {
  margin: 1.25rem;
}
@media (min-width: 992px) {
  .znd-shop-cart-page {
    margin: 1.875rem;
  }
}
@media (min-width: 768px) {
  .znd-shop-cart-page {
    margin: 2.5rem;
  }
}
.znd-shop-cart-page__title {
  margin-bottom: 0.25rem;
  color: #4c575d;
}
.znd-shop-cart-page__subtitle {
  color: #212525;
}
.znd-shop-cart-page__quick-order {
  border-bottom: 1px #d1dae1 solid;
  padding: 1.25rem;
}
.znd-shop-cart-page__quick-order__input {
  border: 1px solid #74797c;
  border-radius: 0.375rem;
  background-color: #ececed;
  padding: 1rem;
  width: 100%;
  height: 3.125rem;
  color: #212525;
}
.znd-shop-cart-page__quick-order__input-wrapper {
  position: relative;
  width: 100%;
}
@media (min-width: 576px) {
  .znd-shop-cart-page__quick-order__input-wrapper {
    width: 18.75rem;
  }
}
.znd-shop-cart-page__quick-order__icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
}
.znd-shop-cart-page__items-list {
  margin-top: 2.25rem;
  margin-bottom: 1.25rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list {
    margin-bottom: 0;
  }
}
.znd-shop-cart-page__items-list-wrapper {
  position: relative;
  border-radius: 0.375rem;
  background-color: white;
}
.znd-shop-cart-page__items-list-wrapper.--quick-order-is-focused {
  z-index: 300;
}
.znd-shop-cart-page__items-list__footer {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
  background-color: #ffffff;
  padding: 1.25rem;
  width: 100%;
}
@media (min-width: 768px) {
  .znd-shop-cart-page__items-list__footer {
    padding: 1.25rem 2.5rem 1.25rem 3.75rem;
  }
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    box-shadow: none;
    background-color: unset;
    padding: 1.5rem;
  }
}
.znd-shop-cart-page__items-list__footer__subtotal {
  display: flex;
  align-items: flex-end;
  color: #4c575d;
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer__subtotal {
    flex-direction: row;
    padding-top: 0.5rem;
  }
}
.znd-shop-cart-page__items-list__footer__subtotal__price {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  text-transform: none;
  padding-left: 1rem;
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer__subtotal__price {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 2rem;
    letter-spacing: 0;
    text-transform: none;
    padding-left: 1.5rem;
    min-width: 8rem;
  }
}
.znd-shop-cart-page__items-list__footer__subtotal__text {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-transform: none;
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer__subtotal__text {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 2rem;
    letter-spacing: 0;
    text-transform: none;
  }
}
.znd-shop-cart-page__items-list__footer__subtotal-exclude {
  display: none;
  padding-top: 0.25rem;
  padding-bottom: 1.75rem;
  color: #c4c8c8;
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer__subtotal-exclude {
    display: block;
  }
}
.znd-shop-cart-page__items-list__footer__order-on-account-blocked-message {
  padding-left: 1rem;
  width: 100%;
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer__order-on-account-blocked-message {
    margin-bottom: 1.75rem;
    width: auto;
  }
}
.znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge {
  padding-top: 0.25rem;
  width: 100%;
  color: #c4c8c8;
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge {
    display: block;
    font-size: 1rem;
  }
}
.znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge-wrapper {
  display: none;
  width: 100%;
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge-wrapper {
    display: block;
  }
}
.znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge-wrapper-mobile {
  padding: 1rem 1.25rem 1.25rem;
}
@media (min-width: 576px) {
  .znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge-wrapper-mobile {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge-wrapper-mobile {
    display: none;
  }
}
@media (min-width: 576px) {
  .znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge__label {
    margin-left: auto;
    text-align: right;
  }
}
.znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge__remove {
  vertical-align: middle;
  border: none;
  background: none;
  padding: 0;
}
.znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge__price {
  margin-top: auto;
  padding-left: 1.5rem;
  text-align: right;
}
@media (min-width: 576px) {
  .znd-shop-cart-page__items-list__footer__subtotal-discount-surcharge__price {
    min-width: calc(8rem + 1px);
  }
}
@media (min-width: 992px) {
  .znd-shop-cart-page__items-list__footer__subtotal-values {
    align-items: flex-end;
  }
}
.znd-shop-cart-page__items-list__footer__buttons {
  display: flex;
}
.znd-shop-cart-page__items-list__footer__buttons__proceed {
  margin-left: 0.75rem;
}
.znd-shop-cart-page__voucher {
  margin-bottom: 8.125rem;
}

.znd-shop-category-page__content {
  margin: 1.25rem;
}
@media (min-width: 992px) {
  .znd-shop-category-page__content {
    margin: 1.875rem;
  }
}
@media (min-width: 768px) {
  .znd-shop-category-page__content {
    margin: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .znd-shop-category-page__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.znd-shop-category-page__sidebar {
  display: none;
}
@media (min-width: 1200px) {
  .znd-shop-category-page__sidebar {
    display: block;
    position: sticky;
    top: 5.5rem;
    left: 2.5rem;
    flex: 0 0 18.75rem;
    margin-right: 2.5rem;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 1.25rem;
    width: 18.75rem;
    max-width: 18.75rem;
  }
}
.znd-shop-category-page__sidebar-loader {
  width: 2rem;
  height: 2rem;
}
@media (min-width: 1200px) {
  .znd-shop-category-page__main {
    flex: 1 1 auto;
  }
}

.znd-shop-checkout-page__stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .znd-shop-checkout-page__stepper {
    margin-top: 2.875rem;
  }
}
.znd-shop-checkout-page__wrapper {
  margin-bottom: 2.5rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
@media (min-width: 768px) {
  .znd-shop-checkout-page__wrapper {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}
.znd-shop-checkout-page__title {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.25rem;
  letter-spacing: 0;
  text-transform: none;
  margin: 0 0 1.25rem;
}
.znd-shop-checkout-page__content {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: "sidebar" "main" "footer";
  border-radius: 5px;
  background-color: #ffffff;
}
@media (min-width: 992px) {
  .znd-shop-checkout-page__content {
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: 3fr 2fr;
    grid-template-areas: "main sidebar" "main footer" "main empty";
  }
}
.znd-shop-checkout-page__main {
  grid-area: main;
  padding: 1.25rem 1.25rem 0;
}
@media (min-width: 768px) {
  .znd-shop-checkout-page__main {
    padding: 2.5rem 2.5rem 0;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-page__main {
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .znd-shop-checkout-page__main {
    min-width: 36.75rem;
  }
}
.znd-shop-checkout-page__sidebar {
  display: none;
  grid-area: sidebar;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
}
.znd-shop-checkout-page__sidebar--always {
  display: block;
}
@media (min-width: 768px) {
  .znd-shop-checkout-page__sidebar {
    padding-top: 2.5rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-page__sidebar {
    display: block;
    margin-bottom: 0;
    padding: 2.5rem 2.5rem 0 1.25rem;
  }
}
.znd-shop-checkout-page__footer {
  grid-area: footer;
  padding: 1.75rem 1.25rem 1.25rem;
}
@media (min-width: 768px) {
  .znd-shop-checkout-page__footer {
    padding: 1.75rem 2.5rem 2.5rem;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-page__footer {
    padding: 2.5rem 2.5rem 2.5rem 1.25rem;
  }
}
.znd-shop-checkout-page__empty {
  grid-area: empty;
}
@media (min-width: 992px) {
  .znd-shop-checkout-page__sidebar, .znd-shop-checkout-page__footer, .znd-shop-checkout-page__empty {
    border-left: 1px solid #d1dae1;
  }
}

.znd-shop-checkout-pay-content {
  display: block;
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 2rem;
  min-height: 200px;
  overflow: hidden;
}
.znd-shop-checkout-pay-content__wrapper {
  position: relative;
}
.znd-shop-checkout-pay-content__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.znd-shop-checkout-pay-content__adyen--three-ds2 {
  height: 600px;
}

.znd-shop-checkout-pay-error-content__error-information {
  margin-bottom: 1rem;
  color: #e4032e;
}

.znd-shop-checkout-payment-page__title {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.875rem;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 2.5rem;
  color: #4c575d;
}
.znd-shop-checkout-payment-page__button {
  justify-content: center;
  width: 100%;
}
@media (min-width: 768px) {
  .znd-shop-checkout-payment-page__button {
    flex: 1 1 12.5rem;
    margin-left: auto;
    width: auto;
    min-width: 12.5rem;
  }
}

.znd-shop-checkout-radio {
  cursor: pointer;
  margin: 0;
  padding: 1.25rem;
}
.znd-shop-checkout-radio__wrapper {
  transition: background-color 0.2s ease-out;
  margin-bottom: 0.75rem;
  border: 1px solid #d1dae1;
  border-radius: 0.375rem;
}
.znd-shop-checkout-radio__wrapper:hover {
  background-color: rgba(236, 236, 237, 0.4);
}
.znd-shop-checkout-radio__wrapper.--loading .znd-shop-checkout-radio__image {
  padding-left: 0;
}
.znd-shop-checkout-radio__image {
  padding-left: 1.5rem;
  width: auto;
  height: 1.625rem;
}
.znd-shop-checkout-radio__loading {
  flex: 0 0 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}
.znd-shop-checkout-radio__additional-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0.875rem 1.25rem;
}
@media (min-width: 1200px) {
  .znd-shop-checkout-radio__additional-fields {
    padding-right: 20%;
    padding-left: 2.375rem;
  }
}
.znd-shop-checkout-radio__additional-field--column {
  flex: 1 1 auto;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  width: 100%;
}
.znd-shop-checkout-radio__additional-field--column + .znd-shop-checkout-radio__additional-field--column {
  margin-top: 0.75rem;
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .znd-shop-checkout-radio__additional-field--column {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .znd-shop-checkout-radio__additional-field--column + .znd-shop-checkout-radio__additional-field--column {
    margin-top: 0;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-radio__additional-field--column {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .znd-shop-checkout-radio__additional-field--column + .znd-shop-checkout-radio__additional-field--column {
    margin-top: 0;
  }
}
.znd-shop-checkout-radio .znd-shop-checkout-radio__label {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  text-transform: none;
  color: #212525;
}
.znd-shop-checkout-radio .znd-shop-checkout-radio__label::before {
  top: 50%;
  transform: translateY(-56.25%);
  border-color: #d1dae1;
  background-color: #ffffff;
}
.znd-shop-checkout-radio .znd-shop-checkout-radio__input:checked ~ .znd-shop-checkout-radio__label::after {
  top: 50%;
  left: -1.25rem;
  transform: translateY(-62.5%);
  border-radius: 50%;
  background-image: none;
  background-color: #e4032e;
  width: 0.5rem;
  height: 0.5rem;
}
.znd-shop-checkout-radio .znd-shop-checkout-radio__input:checked ~ .znd-shop-checkout-radio__label::before {
  border: 2px #e4032e solid;
  background-color: #ffffff;
}

.znd-shop-checkout-reseller__subhead {
  margin-bottom: 1.25rem;
  color: #212525;
}
.znd-shop-checkout-reseller__subhead::after {
  position: relative;
  top: -2px;
  left: 2px;
  content: "*";
  color: #74797c;
}
.znd-shop-checkout-reseller__loading {
  margin-top: 1.5rem;
  max-width: 16.25rem;
}

.znd-shop-checkout-shipping__headline {
  margin-bottom: 1.5rem;
  color: #4c575d;
}
@media (min-width: 576px) {
  .znd-shop-checkout-shipping__headline {
    margin-bottom: 2.5rem;
  }
}
.znd-shop-checkout-shipping__addresses {
  margin-bottom: 3.75rem;
}
@media (min-width: 576px) {
  .znd-shop-checkout-shipping__addresses {
    margin-bottom: 4.25rem;
  }
}
.znd-shop-checkout-shipping__addresses__column {
  flex: 0 0 50%;
  margin-bottom: 2.75rem;
}
@media (min-width: 768px) {
  .znd-shop-checkout-shipping__addresses__column {
    flex: 0 0 45%;
    margin-bottom: 0;
  }
}
.znd-shop-checkout-shipping__addresses__column:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .znd-shop-checkout-shipping__addresses__column:last-child {
    margin-left: auto;
  }
}
.znd-shop-checkout-shipping__addresses--summary {
  margin-bottom: 2.5rem;
}
.znd-shop-checkout-shipping__addresses--summary .znd-shop-checkout-shipping__addresses__column {
  margin-bottom: 2.5rem;
}
.znd-shop-checkout-shipping__addresses--summary .znd-shop-checkout-shipping__addresses__column:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .znd-shop-checkout-shipping__addresses--summary .znd-shop-checkout-shipping__addresses__column {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-shipping__addresses--summary {
    margin-bottom: 3rem;
  }
}
.znd-shop-checkout-shipping__button {
  justify-content: center;
  width: 100%;
}
@media (min-width: 768px) {
  .znd-shop-checkout-shipping__button {
    flex: 1 1 12.5rem;
    margin-left: auto;
    width: auto;
    min-width: 12.5rem;
  }
}
.znd-shop-checkout-shipping__service-input {
  max-width: none;
}
.znd-shop-checkout-shipping__change-address-text {
  color: #74797c;
}

.znd-shop-checkout-summary__title {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.875rem;
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 2.5rem;
  color: #4c575d;
}
@media (min-width: 992px) {
  .znd-shop-checkout-summary__title {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    text-transform: none;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-summary__title {
    margin-bottom: 2.5rem;
  }
}
.znd-shop-checkout-summary__loader {
  margin-right: auto;
  margin-left: auto;
}
.znd-shop-checkout-summary__purchase-order__subhead, .znd-shop-checkout-summary__tax-id__subhead {
  margin-bottom: 1.25rem;
  color: #212525;
}
.znd-shop-checkout-summary__purchase-order__input, .znd-shop-checkout-summary__tax-id__input {
  margin-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__purchase-order__input, .znd-shop-checkout-summary__tax-id__input {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-summary__purchase-order__input, .znd-shop-checkout-summary__tax-id__input {
    max-width: 16.25rem;
  }
}
.znd-shop-checkout-summary__item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
}
.znd-shop-checkout-summary__item + .znd-shop-checkout-summary__item {
  padding-top: 1rem;
}
.znd-shop-checkout-summary__item-info {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1px;
  min-width: 0;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__item-info {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-summary__item-info {
    flex-wrap: wrap;
  }
}
.znd-shop-checkout-summary__item-name {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  text-transform: none;
  flex: 0 0 60%;
  order: 1;
  overflow: hidden;
  color: #212525;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__item-name {
    flex: 0 0 70%;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-summary__item-name {
    flex: 0 0 63%;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-summary__item-name {
    flex: 0 0 48%;
  }
}
.znd-shop-checkout-summary__item-quantity {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  order: 4;
  padding-top: 0.125rem;
  color: #74797c;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__item-quantity {
    flex: 0 0 10%;
    order: 2;
    padding-top: 0;
    text-align: right;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-summary__item-quantity {
    flex: 0 0 auto;
    order: 4;
    margin-top: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-summary__item-quantity {
    flex: 0 0 22%;
    order: 2;
    margin: 0;
    padding-left: 1.25rem;
  }
}
.znd-shop-checkout-summary__item-payload-descriptions {
  flex: 0 0 100%;
  order: 3;
  padding-top: 0.25rem;
  overflow: hidden;
  color: #212525;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__item-payload-descriptions {
    order: 4;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-summary__item-payload-descriptions {
    order: 3;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-summary__item-payload-descriptions {
    order: 4;
  }
}
.znd-shop-checkout-summary__item-price {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  display: flex;
  flex: 0 0 40%;
  justify-content: flex-end;
  align-items: center;
  order: 2;
  color: #212525;
  text-align: right;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__item-price {
    flex: 0 0 20%;
    order: 3;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-summary__item-price {
    flex: 0 0 37%;
    order: 2;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-summary__item-price {
    flex: 0 0 30%;
    order: 3;
  }
}
.znd-shop-checkout-summary__sums {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
  border-top: 1px solid #d1dae1;
  padding-top: 0.75rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__sums {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-summary__sums {
    margin-right: -2.5rem;
    margin-left: -1.25rem;
    padding-right: 2.5rem;
    padding-left: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-summary__sums {
    padding-top: 1.25rem;
  }
}
.znd-shop-checkout-summary__sum {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  color: #74797c;
}
.znd-shop-checkout-summary__sum + .znd-shop-checkout-summary__sum {
  margin-top: 0.5rem;
}
.znd-shop-checkout-summary__sum-label {
  flex: 0 0 66%;
  padding-right: 1.75rem;
  text-align: right;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__sum-label {
    flex: 0 0 80%;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-summary__sum-label {
    flex: 0 0 63%;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-summary__sum-label {
    flex: 0 0 70%;
  }
}
.znd-shop-checkout-summary__sum-price {
  flex: 0 0 33%;
  align-self: flex-end;
  text-align: right;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__sum-price {
    flex: 0 0 20%;
  }
}
@media (min-width: 992px) {
  .znd-shop-checkout-summary__sum-price {
    flex: 0 0 37%;
  }
}
@media (min-width: 1200px) {
  .znd-shop-checkout-summary__sum-price {
    flex: 0 0 30%;
  }
}
.znd-shop-checkout-summary__sum--total {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  text-transform: none;
  color: #212525;
}
.znd-shop-checkout-summary__submit {
  justify-content: center;
  width: 100%;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__submit {
    flex: 1 1 12.5rem;
    margin-left: auto;
    width: auto;
    min-width: 12.5rem;
  }
}
.znd-shop-checkout-summary__error-information {
  opacity: 1;
  transition: opacity ease-out 1s;
  margin: 1.25rem auto 0;
  width: fit-content;
  color: #e4032e;
  text-align: left;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary__error-information {
    margin-right: 0;
    margin-left: auto;
  }
}
.znd-shop-checkout-summary__error-information--hide {
  opacity: 0;
}
.znd-shop-checkout-summary__checkbox-label {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  cursor: pointer;
  margin: 0;
  color: #212525;
  text-align: right;
}
.znd-shop-checkout-summary__checkbox-label .custom-control-label::before, .znd-shop-checkout-summary__checkbox-label .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
}
.znd-shop-checkout-summary__checkbox-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
  padding-bottom: 2.625rem;
}
.znd-shop-checkout-summary__promotion-remove {
  vertical-align: middle;
  border: none;
  background: none;
  padding: 0;
}

.znd-shop-checkout-summary-method {
  margin-bottom: 0.75rem;
  border: 1px solid #d1dae1;
  border-radius: 0.375rem;
  padding: 1.25rem;
}

.znd-shop-checkout-summary-order-data {
  margin-bottom: 2.5rem;
}
.znd-shop-checkout-summary-order-data__column {
  flex: 0 0 50%;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .znd-shop-checkout-summary-order-data__column {
    flex: 0 0 45%;
  }
}

.znd-shop-confirmation-page__content {
  display: block;
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
  padding-bottom: 6rem;
  overflow: hidden;
}
.znd-shop-confirmation-page__title {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.875rem;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 1.25rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  width: 100%;
  max-width: 43rem;
  color: #212525;
  text-align: center;
}
@media (min-width: 768px) {
  .znd-shop-confirmation-page__title {
    margin-top: 3.75rem;
    width: 80%;
  }
}
.znd-shop-confirmation-page__description {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 1.25rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  width: 100%;
  max-width: 43rem;
  color: #212525;
  text-align: center;
}
@media (min-width: 768px) {
  .znd-shop-confirmation-page__description {
    width: 80%;
  }
}
.znd-shop-confirmation-page__back {
  display: block;
  margin-top: 2.5rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  width: 100%;
}
@media (min-width: 768px) {
  .znd-shop-confirmation-page__back {
    padding: 0;
    width: 12.5rem;
  }
}
.znd-shop-confirmation-page__title, .znd-shop-confirmation-page__description, .znd-shop-confirmation-page__back {
  position: relative;
  z-index: 1;
}
.znd-shop-confirmation-page__animation {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  overflow: hidden;
}
.znd-shop-confirmation-page__animation-city {
  flex: 0 0 1296px;
  opacity: 0.4;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: city;
  background-image: url(../../img/city.svg);
  background-position: left bottom;
  background-repeat: repeat-x;
  width: 1296px;
  height: 192px;
}
.znd-shop-confirmation-page__animation-car {
  position: absolute;
  right: 100%;
  bottom: 0;
  animation-duration: 55s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: carSpeed;
}

@keyframes carSpeed {
  0% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  49% {
    transform: translateX(37.5vw);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(37.5vw);
    animation-timing-function: ease-in;
  }
  95%, 100% {
    transform: translateX(calc(100vw + 100%));
    animation-timing-function: ease-in;
  }
}
@keyframes city {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
.znd-shop-page__category-navigation {
  top: 0;
  z-index: 2;
  background-color: #ececed;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.znd-shop-page__product {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-radius: 0.375rem;
  background: #ffffff;
  padding: 1.5rem;
}
.znd-shop-page__product__img {
  flex: 0 0 40%;
  margin-right: 1rem;
  width: 40%;
}
@media (min-width: 768px) {
  .znd-shop-page__product__img {
    display: none;
  }
}
@media (min-width: 992px) {
  .znd-shop-page__product__img {
    display: block;
  }
}
.znd-shop-page__product__img img {
  border: 1px solid #d1dae1;
  border-radius: 5px;
  width: 100%;
  height: auto;
}
.znd-shop-page__product__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.znd-shop-page__product__content-description {
  margin-bottom: 1rem !important;
}
.znd-shop-page__product__content-title {
  font-weight: bold;
}

.znd-shop-product-page {
  padding-top: 1.25rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
@media (min-width: 768px) {
  .znd-shop-product-page {
    padding-top: 2rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}
.znd-shop-product-page__back-button {
  font-weight: 400;
  font-size: 1rem;
}
.znd-shop-product-page__bottom {
  margin-bottom: 2.5rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
}
.znd-shop-product-page__bottom.divider-line {
  border-top: 1px solid #d1dae1;
}
.znd-shop-product-page__bottom-read-more {
  margin-top: 3.75rem;
}
.znd-shop-product-page__bottom-read-more, .znd-shop-product-page__bottom-read-more i {
  font-size: 1rem;
}
div + .znd-shop-product-page__bottom-read-more {
  margin-top: 2rem;
}
@media (min-width: 768px) {
  .znd-shop-product-page__bottom {
    padding-right: 2.5rem;
    padding-bottom: 2.5rem;
    padding-left: 2.5rem;
  }
}
.znd-shop-product-page__content {
  margin-top: 1.25rem;
  border-radius: 5px;
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .znd-shop-product-page__content {
    margin-top: 2rem;
  }
}
@media (min-width: 992px) {
  .znd-shop-product-page__content {
    margin-top: 2.625rem;
  }
}
.znd-shop-product-page__content.--no-margin {
  margin-top: 0;
}
.znd-shop-product-page__content.contain-description {
  margin-top: 3.75rem;
  max-width: 52.5rem;
  color: #212525;
}
.znd-shop-product-page__content-table {
  overflow: auto;
  color: #212525;
}
.znd-shop-product-page__content-table table {
  position: relative;
  width: 62rem;
}
@media (min-width: 992px) {
  .znd-shop-product-page__content-table table {
    width: 100%;
  }
}
.znd-shop-product-page__content-table table::after {
  position: absolute;
  top: 1px;
  left: 1px;
  box-shadow: 0 0 0 1px #d1dae1;
  border-style: hidden;
  border-radius: 0.375rem;
  border-collapse: collapse;
  width: calc(100% - 0.125rem);
  height: calc(100% - 0.125rem);
  content: "";
}
.znd-shop-product-page__content-table table thead {
  color: #4c575d;
  font-size: 1rem;
  line-height: 1.375rem;
  white-space: nowrap;
}
.znd-shop-product-page__content-table table th,
.znd-shop-product-page__content-table table td {
  padding: 1.25rem;
}
.znd-shop-product-page__content-table table th.disable-nowrap {
  white-space: normal;
}
.znd-shop-product-page__content-table table td {
  color: #212525;
  font-size: 1rem;
  line-height: 1.5rem;
}
.znd-shop-product-page__content-table table td > i {
  font-size: 1.75rem;
}
.znd-shop-product-page__content-table table tr {
  border-bottom: 1px solid #d1dae1;
}
.znd-shop-product-page__content-table table tbody tr:last-child {
  border: none;
}
.znd-shop-product-page__top {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.25rem 3.75rem;
}
@media (min-width: 768px) {
  .znd-shop-product-page__top {
    flex-direction: row;
    align-items: flex-start;
    padding: 2.5rem 2.5rem 5rem;
  }
}
@media (min-width: 992px) {
  .znd-shop-product-page__top {
    padding: 2.5rem 2.5rem 5.625rem;
  }
}
.znd-shop-product-page__image-wrapper {
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid #d1dae1;
  border-radius: 5px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .znd-shop-product-page__image-wrapper {
    flex: 0 0 50%;
    margin-right: 2.5rem;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .znd-shop-product-page__image-wrapper {
    flex: 0 0 46%;
    margin-right: 5rem;
  }
}
@media (min-width: 1200px) {
  .znd-shop-product-page__image-wrapper {
    flex: 0 0 35%;
  }
}
.znd-shop-product-page__image-wrapper::before {
  display: block;
  padding-top: 100%;
  content: "";
}
.znd-shop-product-page__info {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
.znd-shop-product-page__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.znd-shop-product-page__title {
  margin-bottom: 0.625rem;
  color: #4c575d;
}
.znd-shop-product-page__product-description {
  margin-bottom: 1.5rem;
  color: #212525;
}
.znd-shop-product-page__product-number {
  margin-bottom: 1.5rem;
  color: #74797c;
}
.znd-shop-product-page__product-quantity-title, .znd-shop-product-page__product-price-title {
  margin-bottom: 0.625rem;
  color: #212525;
}
.znd-shop-product-page__product-price {
  color: #4c575d;
}
.znd-shop-product-page__variant-option {
  margin-top: 2.5rem;
}
.znd-shop-product-page__product-price-per-unit {
  color: #74797c;
}
.znd-shop-product-page__product-price-info {
  margin-bottom: 2.5rem;
  color: #74797c;
}
.znd-shop-product-page__product-quantity {
  margin-top: 2.5rem;
  margin-right: auto;
  width: 100%;
  min-width: 15rem;
}
@media (min-width: 992px) {
  .znd-shop-product-page__product-quantity {
    width: auto;
  }
}
.znd-shop-product-page__product-quantity-title {
  margin-bottom: 0.375rem;
}
.znd-shop-product-page__product-quantity-submit {
  margin-top: 1rem;
}
.znd-shop-product-page__tabs {
  margin-right: -1.25rem;
  margin-bottom: 2.5rem;
  margin-left: -1.25rem;
}
@media (min-width: 768px) {
  .znd-shop-product-page__tabs {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
}
.znd-shop-product-page__meta {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .znd-shop-product-page__meta {
    flex-direction: row;
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
}
.znd-shop-product-page__meta-group {
  padding-bottom: 1.875rem;
}
.znd-shop-product-page__meta-group + .znd-shop-product-page__meta-group {
  border-top: 1px solid #d1dae1;
  padding-top: 1.875rem;
}
@media (max-width: 1439.98px) {
  .znd-shop-product-page__meta-group:last-child {
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .znd-shop-product-page__meta-group {
    flex: 0 0 50%;
    padding: 0 2.5rem;
  }
  .znd-shop-product-page__meta-group + .znd-shop-product-page__meta-group {
    border-top: none;
    padding-top: 0;
  }
  .znd-shop-product-page__meta-group:nth-child(even) {
    border-left: 1px solid #d1dae1;
  }
}
.znd-shop-product-page__meta-group-title {
  margin-bottom: 1.25rem;
  color: #4c575d;
}
@media (min-width: 1200px) {
  .znd-shop-product-page__meta-group-title {
    margin-bottom: 2.5rem;
  }
}
.znd-shop-product-page__applications {
  display: flex;
  flex-direction: row;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) {
  .znd-shop-product-page__applications {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
}
@media (min-width: 1200px) {
  .znd-shop-product-page__applications {
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
    padding-right: 0;
    padding-left: 0;
  }
}
.znd-shop-product-page__application {
  flex: 0 0 90%;
}
.znd-shop-product-page__application + .znd-shop-product-page__application {
  margin-left: 1.25em;
}
@media (min-width: 768px) {
  .znd-shop-product-page__application {
    flex: 0 0 39%;
    margin-left: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .znd-shop-product-page__application {
    flex: 0 0 50%;
    margin: 0;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .znd-shop-product-page__application + .znd-shop-product-page__application {
    margin-left: 0;
  }
  .znd-shop-product-page__application:nth-child(n+3) {
    margin-top: 2.5rem;
  }
}
.znd-shop-product-page__application-scroll-helper {
  display: none;
}
@media (min-width: 768px) {
  .znd-shop-product-page__application-scroll-helper {
    display: block;
    flex: 0 0 2.5rem;
    width: 2.5rem;
    height: 1px;
  }
}
@media (min-width: 1200px) {
  .znd-shop-product-page__application-scroll-helper {
    display: none;
  }
}
.znd-shop-product-page__application-image {
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid #d1dae1;
  border-radius: 5px;
  padding-top: 52%;
  overflow: hidden;
}
.znd-shop-product-page__application-image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.znd-shop-product-page__application-title {
  color: #212525;
  font-weight: 700;
}
@media (min-width: 768px) {
  .znd-shop-product-page__specifications {
    column-count: 2;
  }
}
@media (min-width: 1200px) {
  .znd-shop-product-page__specifications {
    column-count: auto;
  }
}
.znd-shop-product-page__specification + .znd-shop-product-page__specification {
  margin-top: 1.5rem;
}
@media (min-width: 1200px) {
  .znd-shop-product-page__specification {
    display: flex;
    flex-direction: row;
  }
  .znd-shop-product-page__specification + .znd-shop-product-page__specification {
    margin-top: 2.5rem;
  }
}
.znd-shop-product-page__specification-title {
  flex: 0 0 35%;
  margin-bottom: 0.5rem;
  color: #212525;
  font-weight: 700;
}
@media (min-width: 1200px) {
  .znd-shop-product-page__specification-title {
    margin-bottom: 0;
    padding-right: 2.5rem;
  }
}
.znd-shop-product-page__specification-description {
  color: #212525;
}
.znd-shop-product-page__specification-description-line {
  display: block;
}
.znd-shop-product-page__related-products {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.znd-shop-product-page__related-products-title {
  margin-bottom: 1rem;
  color: #212525;
}
.znd-shop-product-page__related-products-list {
  display: flex;
  flex-direction: row;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-right: -1.25rem;
  margin-left: -1.25rem;
}
@media (min-width: 768px) {
  .znd-shop-product-page__related-products-list {
    margin-right: -2.5rem;
    margin-left: -2.5rem;
  }
}
.znd-shop-product-page__related-products-item {
  display: flex;
  flex: 0 0 85%;
  padding: 0 0.625rem;
  max-width: 85%;
}
.znd-shop-product-page__related-products-item:first-child {
  margin-left: 0.625rem;
}
.znd-shop-product-page__related-products-item-helper {
  flex: 0 0 auto;
  width: 0.625rem;
  height: 1px;
}
@media (min-width: 768px) {
  .znd-shop-product-page__related-products-item-helper {
    width: 1.875rem;
  }
}
@media (min-width: 768px) {
  .znd-shop-product-page__related-products-item {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .znd-shop-product-page__related-products-item:first-child {
    flex: 0 0 40%;
    margin-left: 1.875rem;
    max-width: 40%;
  }
}
@media (min-width: 992px) {
  .znd-shop-product-page__related-products-item {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .znd-shop-product-page__related-products-item:first-child {
    flex: 0 0 35%;
    margin-left: 1.875rem;
    max-width: 35%;
  }
}
@media (min-width: 1200px) {
  .znd-shop-product-page__related-products-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .znd-shop-product-page__related-products-item:first-child {
    flex: 0 0 25%;
    margin-left: 1.875rem;
    max-width: 25%;
  }
}
@media (min-width: 1440px) {
  .znd-shop-product-page__related-products-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .znd-shop-product-page__related-products-item:first-child {
    flex: 0 0 20%;
    margin-left: 1.875rem;
    max-width: 20%;
  }
}
.znd-shop-product-page__bulk-price {
  width: 100%;
}
.znd-shop-product-page__bulk-price-wrapper {
  margin-top: 2.5rem;
  border: 1px solid #d1dae1;
  border-radius: 5px;
  width: 75%;
}
@media (min-width: 768px) {
  .znd-shop-product-page__bulk-price-wrapper {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .znd-shop-product-page__bulk-price-wrapper {
    margin-right: auto;
    width: auto;
    min-width: 15rem;
  }
}
@media (min-width: 1200px) {
  .znd-shop-product-page__bulk-price-wrapper {
    margin-top: 0;
    margin-left: 2.5rem;
  }
}
.znd-shop-product-page__bulk-price-title {
  color: #74797c;
}
.znd-shop-product-page__bulk-price-cell {
  padding: 0.5rem 0.75rem 0.375rem;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.znd-shop-product-page__bulk-price-row {
  border-top: 1px solid #d1dae1;
}
.znd-shop-product-page .znd-shop-product-page__subscription-options-title {
  color: #212525;
}
.znd-shop-product-page .znd-shop-product-page__subscription-options-compare {
  cursor: pointer;
  color: #e4032e;
}
.znd-shop-product-page .znd-shop-product-page__subscription-options-radios {
  display: block;
}
@media (min-width: 576px) {
  .znd-shop-product-page .znd-shop-product-page__subscription-options-radios {
    display: flex;
  }
}
.znd-shop-product-page .znd-shop-product-page__additional-user-title {
  color: #212525;
}
.znd-shop-product-page__subscription-options + .znd-shop-product-page__additional-user {
  margin-top: 1.5rem;
}
.znd-shop-product-page__machine-selection {
  width: max-content;
  color: #212525;
}
.znd-shop-product-page__machine-selection-item {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
  text-transform: none;
  margin: 0;
}
.znd-shop-product-page__machine-selection-item .custom-control-label::before, .znd-shop-product-page__machine-selection-item .custom-control-label::after {
  top: 0;
}
.znd-shop-product-page__machine-selection-item + .znd-shop-product-page__machine-selection-item {
  margin-top: 0.875rem;
}
.znd-shop-product-page__machine-selection-item label {
  cursor: pointer;
}
.znd-shop-product-page__info-text {
  color: #212525;
}

.znd-shop-search-result-page__content {
  margin: 1.25rem;
}
@media (min-width: 992px) {
  .znd-shop-search-result-page__content {
    margin: 1.875rem;
  }
}
@media (min-width: 768px) {
  .znd-shop-search-result-page__content {
    margin: 2.5rem;
  }
}
.znd-shop-search-result-page__header {
  margin-bottom: 1.25rem;
  width: 100%;
}
@media (min-width: 992px) {
  .znd-shop-search-result-page__header {
    margin-bottom: 1rem;
  }
}
.znd-shop-search-result-page__header__title {
  margin-bottom: 0.25rem;
  color: #4c575d;
}
.znd-shop-search-result-page__header__subtitle {
  color: #212525;
}
.znd-shop-search-result-page__header-right {
  margin-top: 1.25rem;
  margin-bottom: 0.25rem;
}
@media (min-width: 576px) {
  .znd-shop-search-result-page__header-right {
    margin: 0;
  }
}

.znd-social-media {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: auto;
  padding: 2.5rem 0.625rem;
}
.znd-social-media__link {
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: color 0.4s ease-out;
  margin: 0.25rem;
  border: 1px solid #d1dae1;
  border-radius: 50%;
  background: #ffffff;
  width: 3.75rem;
  height: 3.75rem;
  color: #74797c;
  text-align: center;
}
.znd-social-media__link:before {
  font-size: 1.125rem;
}
.znd-social-media__link:hover {
  color: #212525;
}
.znd-social-media__link.icon-instagram:before, .znd-social-media__link.icon-youtube:before, .znd-social-media__link.icon-twitter:before {
  font-size: 1.5rem;
}
.znd-social-media__link.icon-facebook:before, .znd-social-media__link.icon-pinterest:before {
  font-size: 1.25rem;
}

.znd-software-downloads {
  display: none;
  margin: 0 auto 3.125rem;
  padding: 2.5rem 1.875rem;
  max-width: 64.75rem;
}
.znd-software-downloads--active {
  display: block;
}
.znd-software-downloads__title {
  margin: 0 -0.75rem 1rem;
  color: #4c575d;
}

.znd-software-downloads-tabs .znd-software-downloads-tabs__wrapper {
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
}
.znd-software-downloads-tabs .znd-software-downloads-tabs__item {
  padding-bottom: 1.125rem;
}
.znd-software-downloads-tabs .znd-software-downloads-tabs__item::before {
  right: auto;
  left: 50%;
  width: 3.125rem;
  height: 0.25rem;
  content: "";
}
.znd-software-downloads-tabs .znd-software-downloads-tabs__item.znd-software-downloads-tabs__item--active::before {
  transform: translateY(-100%) translateX(-50%);
}

.znd-software-license-modal__info {
  display: flex;
  margin-bottom: 1.5rem;
}
.znd-software-license-modal__info-icon {
  background-color: #4c575d;
}
.znd-software-license-modal__info-text {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
}
.znd-software-license-modal__text a {
  color: #212525;
  font-weight: 700;
  text-decoration: underline;
}
.znd-software-license-modal__checkbox-label {
  cursor: pointer;
  margin-bottom: 0;
  padding-top: 0.75rem;
}
.znd-software-license-modal__checkbox-label .custom-control-label {
  padding-left: 0.375rem;
}
.znd-software-license-modal__checkbox-label .custom-control-label::before, .znd-software-license-modal__checkbox-label .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
  width: 1.375rem;
  height: 1.375rem;
}
.znd-software-license-modal__checkbox-label .custom-control-label::before {
  border-radius: 0.25rem;
}
.znd-software-license-modal__checkbox-label .custom-control-label::after {
  left: -1.75rem;
}
.znd-software-license-modal__guide {
  margin-top: 1.5rem;
}
.znd-software-license-modal__guide a {
  color: #212525;
  font-weight: 700;
  text-decoration: underline;
}
.znd-software-license-modal__button {
  padding-right: 2rem;
  padding-left: 2rem;
}
.znd-software-license-modal__button[disabled] {
  background-color: #ececed;
  color: #c4c8c8;
}

.znd-software-page__overview {
  margin: 1.25rem;
}
@media (min-width: 768px) {
  .znd-software-page__overview {
    margin: 2rem 2.5rem;
  }
}
.znd-software-page__overview-header {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .znd-software-page__overview-header {
    margin-bottom: 1.75rem;
  }
}
.znd-software-page__bottom-read-more {
  margin-top: 0;
}
.znd-software-page__bottom-read-more, .znd-software-page__bottom-read-more i {
  font-size: 1rem;
}
.znd-software-page__full-description {
  margin-bottom: 1rem;
  color: #212525;
}
.znd-software-page__full-description h1,
.znd-software-page__full-description h2,
.znd-software-page__full-description h3 {
  color: #4c575d;
}
.znd-software-page__buttons {
  margin-top: 2.25rem;
}

.znd-table-filter-dropdown {
  padding: 1.25rem;
  min-width: 20rem;
}
.znd-table-filter-dropdown__separator {
  margin: 1rem -1.25rem;
}
.znd-table-filter-dropdown__option-row + .znd-table-filter-dropdown__option-row {
  margin-top: 1rem;
}
.znd-table-filter-dropdown__checkbox {
  margin: 0;
}

.znd-table-head {
  display: flex;
}
.znd-table-head__title {
  padding-right: 0.5rem;
}
.znd-table-head__icon {
  display: block;
  font-size: 1.25rem;
}
.znd-table-head .znd-tooltip__label {
  width: -moz-max-content;
  width: max-content;
  text-align: left;
}

.rdt_TableCol_Sortable.rdt_TableCol_Sortable {
  overflow: visible;
}
.rdt_TableCol_Sortable.rdt_TableCol_Sortable:focus, .rdt_TableCol_Sortable.rdt_TableCol_Sortable:hover {
  opacity: unset;
}
.rdt_TableCol_Sortable.rdt_TableCol_Sortable:focus > div:not(.znd-table-head), .rdt_TableCol_Sortable.rdt_TableCol_Sortable:focus span, .rdt_TableCol_Sortable.rdt_TableCol_Sortable:focus i, .rdt_TableCol_Sortable.rdt_TableCol_Sortable:hover > div:not(.znd-table-head), .rdt_TableCol_Sortable.rdt_TableCol_Sortable:hover span, .rdt_TableCol_Sortable.rdt_TableCol_Sortable:hover i {
  opacity: 0.7;
}

.znd-table-tile {
  display: inline-block;
  margin: 1.25rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 1.25rem 0;
  width: calc(100% - 2.5rem);
  min-width: min-content;
}
@media (min-width: 576px) {
  .znd-table-tile {
    margin: 2.5rem;
    padding: 1.875rem 0;
    width: calc(100% - 5rem);
  }
}
.znd-table-tile__header {
  padding: 0 1.875rem 1.25rem;
}
@media (min-width: 576px) {
  .znd-table-tile__header {
    padding: 0.625rem 2.5rem 1.875rem;
  }
}
.znd-table-tile__header-title {
  color: #4c575d;
}
.znd-table-tile__icon-caret, .znd-table-tile .znd-table-tile__icon-caret.icon-caret-down {
  display: block;
  margin-left: auto;
  color: #e4032e;
}
.znd-table-tile__info {
  display: flex;
  color: #e4032e;
}
.znd-table-tile__info--dark {
  color: #212525;
}
.znd-table-tile__info .znd-tooltip__label {
  width: max-content;
  text-align: left;
}
.znd-table-tile__icon-info {
  display: inline-block;
  border-radius: 50%;
  background-color: #e4032e;
  width: 1.375rem;
  height: 1.375rem;
  color: #ffffff;
  text-align: center;
}
.znd-table-tile__icon-info--dark {
  display: flex;
  color: #212525;
  text-align: center;
}
.znd-table-tile__icon-info--dark-icon {
  font-size: 1.25rem;
}
.znd-table-tile__name {
  pointer-events: none;
}
.znd-table-tile__name--with-icon {
  margin-right: 0.25rem;
}
.znd-table-tile--inner-scroll .znd-table-tile__scroll-wrapper {
  width: calc(100vw - 2.5rem);
  overflow-x: auto;
  overflow-y: hidden;
}
@media (min-width: 576px) {
  .znd-table-tile--inner-scroll .znd-table-tile__scroll-wrapper {
    width: calc(100vw - 5rem);
  }
}
@media (min-width: 1200px) {
  .znd-table-tile--inner-scroll .znd-table-tile__scroll-wrapper {
    width: calc(100vw - 11.5rem);
  }
}
.znd-table-tile--inner-scroll .znd-table-tile__header {
  position: sticky;
  top: 3.875rem;
  z-index: 1001;
  margin-top: -1.875rem;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding-top: 1.675rem;
}
@media (min-width: 1200px) {
  .znd-table-tile--inner-scroll .znd-table-tile__header {
    top: 0;
  }
}
.znd-table-tile__footer {
  border-top: 1px solid #d1dae1;
  height: 4.5rem;
}
.znd-table-tile__footer__content {
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding-top: 20px;
  width: 97%;
}
.znd-table-tile__footer__item {
  display: flex;
  flex-flow: column;
  justify-content: center;
  color: #4c575d;
  font-weight: 700;
}
.znd-table-tile__footer__item div,
.znd-table-tile__footer__item button {
  margin: 0;
}
.znd-table-tile__footer__icon {
  padding-top: 0.15rem;
  color: #4c575d;
  font-size: 1.25rem;
}

.znd-tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #d1dae1;
  min-height: 3rem;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.znd-tabs--center {
  justify-content: center;
}
.znd-tabs__tooltip .znd-tooltip__label {
  width: max-content;
}
.znd-tabs__item-wrapper {
  position: relative;
  padding: 0 0.5rem;
}
.znd-tabs__item {
  display: block;
  position: relative;
  flex: 0 0 auto;
  transition: color 0.2s ease-out;
  cursor: pointer;
  padding: 1rem 1rem 0.875rem;
  color: #212525;
  font-size: 1.125rem;
  line-height: 1;
  white-space: nowrap;
}
.znd-tabs__item::before {
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #e4032e;
  height: 5px;
  content: "";
}
.znd-tabs__item:hover, .znd-tabs__item:focus {
  background-color: #ececed;
  color: #212525;
  text-decoration: none;
}
.znd-tabs__item.znd-tabs__item--active {
  color: #e4032e;
}
.znd-tabs__item.znd-tabs__item--active::before {
  transform: translateY(-100%);
  opacity: 1;
}
.znd-tabs__arrow {
  margin-left: 0.875rem;
  color: #e4032e;
  font-size: 0.875rem;
}
.znd-tabs__category-navigation {
  top: 0;
  z-index: 100;
  background-color: #ececed;
}
@media (min-width: 768px) {
  .znd-tabs__category-navigation {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.znd-top-bar {
  visibility: hidden;
  padding: 0;
  width: 0;
  height: 0;
}
@media (min-width: 1200px) {
  .znd-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    visibility: visible;
    padding-right: 1.25rem;
    padding-left: 2.5rem;
    width: 100%;
    height: 5rem;
  }
}
.znd-top-bar__logo {
  height: 2.625rem;
}
.znd-top-bar__search-input {
  margin-left: 2.5rem;
  width: 16.25rem;
}
.znd-top-bar__menus {
  display: flex;
  margin-left: auto;
}
.znd-top-bar__icon-button {
  margin-left: 0.625rem;
}
.znd-top-bar__icon-button-shopping-cart, .znd-top-bar__icon-button-notifications {
  position: fixed;
  right: 1.75rem;
  bottom: 1.75rem;
  visibility: visible;
  z-index: 100;
}
.znd-top-bar__icon-button-shopping-cart .btn, .znd-top-bar__icon-button-shopping-cart .adyen-checkout__link.adyen-checkout__link, .znd-top-bar__icon-button-notifications .btn, .znd-top-bar__icon-button-notifications .adyen-checkout__link.adyen-checkout__link {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
@media (min-width: 1200px) {
  .znd-top-bar__icon-button-shopping-cart .btn, .znd-top-bar__icon-button-shopping-cart .adyen-checkout__link.adyen-checkout__link, .znd-top-bar__icon-button-notifications .btn, .znd-top-bar__icon-button-notifications .adyen-checkout__link.adyen-checkout__link {
    box-shadow: none;
  }
}
@media (min-width: 1200px) {
  .znd-top-bar__icon-button-shopping-cart, .znd-top-bar__icon-button-notifications {
    position: relative;
    right: 0;
    bottom: 0;
  }
}
.znd-top-bar__icon-button-shopping-cart__badge, .znd-top-bar__icon-button-notifications__badge {
  display: flex;
  position: absolute;
  top: -0.25rem;
  right: -0.125rem;
  justify-content: center;
  align-items: center;
  z-index: 110;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  background-color: #ffffff;
  min-width: 1.25rem;
  color: #e4032e;
  font-weight: 700;
}
.znd-top-bar__company {
  margin-right: 1.375rem;
}
.znd-top-bar__company-selector {
  margin-right: 0.5rem;
}
.znd-top-bar__company-title {
  color: #4c575d;
}

.znd-top-page {
  margin: 0 1.5rem 1.25rem;
}
@media (min-width: 768px) {
  .znd-top-page {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }
}
.znd-top-page__title {
  color: #4c575d;
}

.znd-user-notice {
  position: absolute;
  top: 4rem;
  right: 1rem;
  z-index: 1030;
  width: calc(100vw - 2rem);
  max-width: 21.5rem;
  min-height: 9.625rem;
}
.znd-user-notice .znd-user-notice-content {
  margin-top: 0.625rem;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}
@supports (filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.15))) {
  .znd-user-notice .znd-user-notice-content {
    filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.15));
    box-shadow: none;
  }
}
.znd-user-notice .znd-user-notice__body {
  margin-bottom: 1.5rem;
  min-height: 3rem;
}
.znd-user-notice .znd-user-notice__body .znd-user-notice__message {
  font-weight: 700;
}
.znd-user-notice .znd-user-notice__close {
  color: #e4032e;
}
.znd-user-notice .znd-user-notice-triangle {
  position: absolute;
  top: -1px;
  right: 1.25rem;
  -webkit-transform: rotate(360deg);
  border-width: 0 9px 11px 9px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
  width: 0;
  height: 0;
}
@media (min-width: 576px) {
  .znd-user-notice {
    max-width: 22.75rem;
  }
}
@media (min-width: 1200px) {
  .znd-user-notice {
    top: 4.5rem;
  }
}

.znd-user-profile-page {
  margin-top: 1.25rem;
}
.znd-user-profile-page__sticky-header {
  position: sticky;
  top: 3.875rem;
  z-index: 1000;
  background-color: #ececed;
}
@media (min-width: 1200px) {
  .znd-user-profile-page__sticky-header {
    top: 0;
  }
}
.znd-user-profile-page__content {
  margin-left: 0;
  padding: 0 1.25rem;
  width: 100%;
}
@media (min-width: 768px) {
  .znd-user-profile-page__content {
    margin-left: -0.625rem;
    padding: 0.625rem 2.5rem 0;
    width: 100%;
  }
}
.znd-user-profile-page__content-data-title {
  margin: 1.875rem 0 1.25rem;
}
@media (min-width: 768px) {
  .znd-user-profile-page__content-data-title {
    margin: 1.875rem 0.625rem 0.625rem;
  }
}
.znd-user-profile-page__content-data-group {
  display: block;
  flex-wrap: nowrap;
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .znd-user-profile-page__content-data-group {
    display: flex;
    flex-wrap: wrap;
  }
}
.znd-user-profile-page__content-data-item {
  position: relative;
  margin: 1.25rem 0 1.25rem;
  border-radius: 0.375rem;
  background: #ffffff;
  padding: 1.25rem;
  width: 100%;
  word-break: break-word;
}
@media (min-width: 768px) {
  .znd-user-profile-page__content-data-item {
    margin: 0.625rem;
    width: calc(50% - 1.25rem);
  }
}
@media (min-width: 992px) {
  .znd-user-profile-page__content-data-item {
    padding: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .znd-user-profile-page__content-data-item {
    width: calc(33.333% - 1.25rem);
  }
}
@media (min-width: 1440px) {
  .znd-user-profile-page__content-data-item {
    width: calc(25% - 1.25rem);
  }
}
.znd-user-profile-page__table {
  width: 100%;
  overflow-y: scroll;
}
.znd-user-profile-page__reorder-button {
  margin: 0.75rem 0;
}
.znd-user-profile-page.znd-user-profile-page .znd-user-profile-page__reorder-button:hover {
  background-color: #d1dae1;
}
.znd-user-profile-page__placeholder-button {
  margin: 0.75rem 0;
  height: 2.625rem;
}
.znd-user-profile-page__header {
  display: block;
  position: sticky;
  top: 6.875rem;
  z-index: 999;
  margin: 0;
  background-color: #ececed;
  padding: 2.125rem 0 1.5rem;
}
@media (min-width: 576px) {
  .znd-user-profile-page__header {
    display: flex;
  }
}
@media (min-width: 768px) {
  .znd-user-profile-page__header {
    margin: 0 0.625rem;
  }
}
@media (min-width: 1200px) {
  .znd-user-profile-page__header {
    top: 3rem;
  }
}
.znd-user-profile-page__subtitle {
  color: #212525;
}
.znd-user-profile-page__add-button {
  margin-right: 0.875rem;
  margin-left: auto;
}
@media (max-width: 575.98px) {
  .znd-user-profile-page__add-button {
    margin-top: 0.875rem;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .znd-user-profile-page__administration-search {
    margin-top: 0.875rem;
  }
}
.znd-user-profile-page__bullet {
  display: block;
  border-radius: 50%;
  background-color: #e4032e;
  width: 1rem;
  height: 1rem;
}
.znd-user-profile-page__empty, .znd-user-profile-page__check {
  font-size: 1.25rem;
}
.znd-user-profile-page__empty {
  color: #c4c8c8;
}
.znd-user-profile-page__check {
  color: #e4032e;
}
.znd-user-profile-page__button {
  font-size: 0.875rem;
}

.zkp-voucher-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border-radius: 0.375rem;
  background-color: #fff;
  padding: 1.25rem;
  min-width: 26rem;
}
@media (min-width: 992px) {
  .zkp-voucher-input {
    border: 1px solid #d1dae1;
  }
}
.zkp-voucher-input__collapse-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.zkp-voucher-input__title {
  flex: 1 1 100%;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0;
  text-transform: none;
}
.zkp-voucher-input__icon {
  flex: 0 0 auto;
  margin-left: 3rem;
  color: #e4032e;
  font-size: 1.25rem;
}
.zkp-voucher-input__form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}
.zkp-voucher-input__input {
  flex: 1 1 100%;
}
.zkp-voucher-input__button {
  flex: 0 0 auto;
  margin-left: 0.75rem;
}

.znd-webcomponent {
  all: initial;
}

.znd-return-table-sub {
  margin: 1rem 1rem 0.5rem 4.125rem;
  max-width: inherit;
}
.znd-return-table-sub__label {
  margin-top: 0 !important;
}
.znd-return-table-sub__label__disabled {
  color: #74797c;
}
.znd-return-table-sub__item {
  width: 51.5%;
}
.znd-return-table-sub__sub-item {
  width: 30%;
}
.znd-return-table-sub__sub-item__optional_text {
  color: #74797c;
  font-weight: normal;
}
.znd-return-table-sub__sub-item__error {
  border: 1px solid #e24c4c;
}
.znd-return-table-sub__sub-item__error__text {
  display: inline-flex;
}
.znd-return-table-sub__image {
  margin-right: 1rem;
  border: 1px solid #d1dae1;
  border-radius: 0.375rem;
  padding: 0.75rem;
  width: 9.5rem;
  height: 10.125rem;
  object-fit: contain;
}
.znd-return-table-sub__table {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: calc(100% - 3.125rem);
}
.znd-return-table-sub__buttons {
  display: flex;
  flex-flow: wrap;
  width: 100%;
}
.znd-return-table-sub__buttons .btn, .znd-return-table-sub__buttons .adyen-checkout__link.adyen-checkout__link {
  margin: 1.25rem 0.75rem 0 0;
}
.znd-return-table-sub__button-wrapper {
  width: 100%;
}
@media (min-width: 768px) {
  .znd-return-table-sub__button-wrapper {
    width: auto;
  }
}
.znd-return-table-sub__info {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}
.znd-return-table-sub__filename {
  color: #4c575d;
}
.znd-return-table-sub__filename .btn__icon-right {
  color: #e4032e;
}