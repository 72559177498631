.znd-registration-personal-information {
    margin: 0 auto;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    width: 100%;
    max-width: 51.75rem;
    color: $secondary;

    @include media-breakpoint-up(sm) {
        padding: 1.25rem;
    }

    &__title,
    p {
        margin-left: 1.25rem;

        @include media-breakpoint-up(sm) {
            margin-left: 0;
        }
    }

    &__container {
        position: relative;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        border-radius: 0.375rem;
        background-color: $white;
        padding: 2.5rem;
        min-height: 34.875rem;
    }

    &__select-header {
        font-weight: $font-weight-bold;
    }

    &__salutation-select {
        max-width: 100%;
        height: 3.125rem;

        @include media-breakpoint-up(sm) {
            max-width: 9.875rem;
        }
    }

    &__name-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__input-header {
        margin-top: 1.5rem;
        font-weight: $font-weight-bold;
    }

    &__input-container {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 48%;
        }
    }

    &__phone-input {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 48%;
        }
    }

    &__terms-container {
        margin-top: 3.25rem;
        margin-bottom: 0.5rem;
        padding-top: 2.625rem;
    }

    &__checkbox-option {
        cursor: pointer;
        margin: 0;
        padding: 0;
        list-style-type: none;

        &:hover {
            background-color: rgba($gray-100, 0.5);
        }
    }

    &__checkbox-label {
        @include t-body;
        cursor: pointer;
        margin: 0;
        padding-top: 0.5rem;

        padding-bottom: 0.5rem;
        color: $gray-500;

        .custom-control-label {
            &::before,
            &::after {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__divider {
        margin-top: 3.75rem;
        margin-bottom: 3.75rem;
        border-top: $input-border-width solid $input-border-color;
    }

    &__button {
        margin: 0 auto;

        @include media-breakpoint-up(sm) {
            position: absolute;
            right: 2.5rem;
            bottom: 2.5rem;
        }
    }

    &__existing-company-button {
        z-index: 10;
        margin: 2.5rem auto 0;

        @include media-breakpoint-up(md) {
            position: absolute;
            right: 2.5rem;
            bottom: 2.5rem;
        }
    }

    &__role-select-header,
    &__department-select-header {
        margin-top: 1.5rem;
        font-weight: $font-weight-bold;
    }

    &__role-select,
    &__department-select {
        width: 100%;
        height: 3.125rem;

        @include media-breakpoint-up(sm) {
            max-width: 48%;
        }
    }

    &__department-select {
        margin-bottom: 2.625rem;
    }
}
