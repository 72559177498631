.znd-shop-checkout-page {
    &__stepper {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 1.25rem;
        margin-bottom: 2.5rem;

        @include media-breakpoint-up(md) {
            margin-top: 2.875rem;
        }
    }

    &__wrapper {
        margin-bottom: 2.5rem;
        padding-right: 1.25rem;
        padding-left: 1.25rem;

        @include media-breakpoint-up(md) {
            padding-right: 2.5rem;
            padding-left: 2.5rem;
        }
    }

    &__title {
        @include t-headline-1;
        margin: 0 0 1.25rem;
    }

    &__content {
        display: grid;
        grid-template-rows: auto auto auto;

        grid-template-columns: 1fr;
        grid-template-areas: 'sidebar' 'main' 'footer';
        border-radius: $border-radius;

        background-color: $white;

        @include media-breakpoint-up(lg) {
            grid-template-rows: min-content min-content 1fr;
            grid-template-columns: 3fr 2fr;
            grid-template-areas: 'main sidebar' 'main footer' 'main empty';
        }
    }

    &__main {
        grid-area: main;
        padding: 1.25rem 1.25rem 0;

        @include media-breakpoint-up(md) {
            padding: 2.5rem 2.5rem 0;
        }

        @include media-breakpoint-up(lg) {
            padding-bottom: 2.5rem;
        }

        @include media-breakpoint-only(lg) {
            min-width: 36.75rem;
        }
    }

    &__sidebar {
        display: none;
        grid-area: sidebar;
        margin-bottom: 1.25rem;

        padding: 1.25rem;

        &--always {
            display: block;
        }

        @include media-breakpoint-up(md) {
            padding-top: 2.5rem;
            padding-right: 2.5rem;
            padding-left: 2.5rem;
        }

        @include media-breakpoint-up(lg) {
            display: block;
            margin-bottom: 0;
            padding: 2.5rem 2.5rem 0 1.25rem;
        }
    }

    &__footer {
        grid-area: footer;
        padding: 1.75rem 1.25rem 1.25rem;

        @include media-breakpoint-up(md) {
            padding: 1.75rem 2.5rem 2.5rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 2.5rem 2.5rem 2.5rem 1.25rem;
        }
    }

    &__empty {
        grid-area: empty;
    }

    &__sidebar,
    &__footer,
    &__empty {
        @include media-breakpoint-up(lg) {
            border-left: 1px solid $gray-200;
        }
    }
}
