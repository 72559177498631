.znd-shop-checkout-pay-content {
    display: block;
    position: relative;
    border-radius: $border-radius;

    background-color: $white;

    padding: 2rem;
    min-height: 200px;
    overflow: hidden;

    &__wrapper {
        position: relative;
    }

    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &__adyen {
        &--three-ds2 {
            height: 600px; // Maximum possible 3DS2 challenge window size: https://docs.adyen.com/online-payments/3d-secure/native-3ds2/web-drop-in/
        }
    }
}
