.znd-root {
    &__loader {
        height: 100vh;
    }

    &__content {
        display: flex;
        position: relative;
        flex: 1 0 auto;
        flex-direction: column;
    }
}
