.znd-change-contact-modal {
    &__label {
        margin-bottom: 0.375rem;
        font-weight: $font-weight-bold;
    }

    &__info {
        margin-top: 1.5rem;
    }
}
