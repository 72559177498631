.znd-shop-category-page {
    &__content {
        margin: 1.25rem;

        @include media-breakpoint-up(lg) {
            margin: 1.875rem;
        }

        @include media-breakpoint-up(md) {
            margin: 2.5rem;
        }

        @include navbarOnDesktop {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__sidebar {
        display: none;

        @include navbarOnDesktop {
            display: block;

            position: sticky;
            top: 5.5rem;
            left: 2.5rem;
            flex: 0 0 18.75rem;
            margin-right: 2.5rem;
            border-radius: $border-radius;
            background-color: $white;
            padding: 1.25rem;
            width: 18.75rem;
            max-width: 18.75rem;
        }
    }

    &__sidebar-loader {
        width: 2rem;
        height: 2rem;
    }

    &__main {
        @include navbarOnDesktop {
            flex: 1 1 auto;
        }
    }
}
