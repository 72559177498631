.znd-asset {
    position: relative;
    z-index: 0;

    &--scrollable {
        overflow-x: auto;
        overflow-y: hidden;
    }

    &__check {
        color: $primary;
        font-size: 1.25rem;
    }
}
