.form-control {
    &:disabled,
    &[readonly] {
        border-color: $input-disabled-border-color;
        color: $input-disabled-color;
    }

    &:enabled:hover {
        outline: 0;
        border-color: $input-focus-border-color;
        background-color: $input-focus-bg;
        color: $input-focus-color;
    }
}

.custom-checkbox {
    padding-left: 1.75rem;

    .custom-control-label {
        &::before {
            left: -1.75rem;
            border-color: $gray-200;
            border-radius: 2px;
            background-color: $white;
        }
        &::after {
            left: -1.75rem;
            background-size: 100% 100%;
        }
    }
}
