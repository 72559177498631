.znd-registration-company-information {
    margin: 0 auto;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    width: 100%;
    max-width: 51.75rem;
    color: $secondary;

    @include media-breakpoint-up(sm) {
        padding: 1.25rem;
    }

    &__title {
        margin-left: 1.25rem;

        @include media-breakpoint-up(sm) {
            margin-left: 0;
        }
    }

    &__container {
        position: relative;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        border-radius: 0.375rem;
        background-color: $white;
        padding: 2.5rem;
        min-height: 34.875rem;
    }

    &__tax-input {
        margin-bottom: 3.75rem;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 48%;
        }
    }

    &__select-header {
        margin-top: 1.5rem;
        font-weight: $font-weight-bold;
    }

    &__state-select,
    &__country-select {
        max-width: 100%;
    }

    &__zip-container,
    &__country-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__input-header {
        margin-top: 1.5rem;
        font-weight: $font-weight-bold;
    }

    &__input-container {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 48%;
        }
    }

    &__city-input {
        width: 100%;
    }

    &__button {
        z-index: 10;
        margin: 2.5rem auto 0;

        @include media-breakpoint-up(md) {
            position: absolute;
            right: 2.5rem;
            bottom: 2.5rem;
        }
    }

    &__divider {
        margin-top: 3.75rem;
        margin-bottom: 3.75rem;
        border-top: $input-border-width solid $input-border-color;
    }
}
