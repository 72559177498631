.znd-registration-verify-company {
    margin: 0 auto;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    width: 100%;
    max-width: 51.75rem;
    color: $secondary;

    @include media-breakpoint-up(sm) {
        padding: 1.25rem;
    }

    &__title {
        margin-left: 1.25rem;

        @include media-breakpoint-up(sm) {
            margin-left: 0;
        }
    }

    &__container {
        position: relative;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        border-radius: 0.375rem;
        background-color: $white;
        padding: 2.5rem;
        min-height: 23.375rem;
    }

    &__info-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 2.5rem;
        max-width: 39rem;
    }

    &__info-icon {
        margin-right: 0.563rem;
        color: $gray-400;
    }

    &__info-text {
        color: $gray-400;
    }

    &__company-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 3.75rem;
        border: $input-border-width solid $input-border-color;
        border-radius: 0.375rem;
        padding: 1.5rem;
        min-height: 6.5rem;

        @include media-breakpoint-up(sm) {
            padding: 2.565rem;
        }
    }

    &__company-icon {
        margin-right: 1rem;
        width: 1.375rem;
        height: 1.125rem;
        color: $primary;
        font-size: 1.25rem;
    }

    &__button-container {
        width: 100%;

        @include media-breakpoint-up(sm) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }

    &__button-decline,
    &__button-accept {
        margin: 0 auto;
        padding-right: 2.25rem;
        padding-left: 2.25rem;

        @include media-breakpoint-up(sm) {
            margin: 0 0 0 0.75rem;
        }
    }

    &__button-decline {
        margin-bottom: 1.5rem;
        background-color: $gray-100;
        color: $primary;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }
}
