.znd-dropdown {
    display: flex;
    position: fixed;
    top: 100%;
    left: 0;
    flex-direction: column;
    align-items: stretch;
    z-index: $zindex-dropdown;
    margin-top: -3px;

    background-color: $white;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;

    min-width: 18.75rem;
    max-width: 100vw;

    @include elevate(200);

    transform: translateY(-1rem);
    visibility: hidden;
    opacity: 0;
    transition:
        transform 0.2s ease-out,
        opacity 0.2s ease-out,
        visibility 0.2s step-end;
    border-radius: $border-radius;

    &--visible {
        position: fixed;
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
        transition:
            transform 0.2s ease-out,
            opacity 0.2s ease-out,
            visibility 0.2s step-start;
    }

    &__item {
        transition:
            background-color 0.2s ease-out,
            color 0.2s ease-out;
        padding: 0.625rem 1.25rem;
        color: $gray-500;
        font-size: 1rem;
        line-height: 1.5rem;

        @include media-breakpoint-up(sm) {
            white-space: nowrap;
        }

        &:hover,
        &:focus {
            background-color: $gray-100;
            color: $gray-500;
            text-decoration: none;
        }

        &--active {
            color: $primary;
        }
    }
}
