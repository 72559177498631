.znd-assets-table-sub {
    margin: 1rem 1rem 1.5rem 4.125rem;
    width: fit-content;
    max-width: 100%;

    &__return_table {
        margin: 1rem 1rem 0.5rem 4.125rem;
        max-width: inherit;

        &__label {
            margin-top: 0 !important;

            &__disabled {
                color: $gray-400;
            }
        }

        &__item {
            width: 51.5%;
        }

        &__sub-item {
            width: 30%;

            &__optional_text {
                color: $gray-400;
                font-weight: normal;
            }

            &__error {
                border: 1px solid $danger;

                &__text {
                    display: inline-flex;
                }
            }
        }
    }

    &__image {
        margin-right: 1rem;
        border: 1px solid $gray-200;
        border-radius: 0.375rem;
        padding: 0.75rem;
        width: 9.5rem;
        height: 10.125rem;
        object-fit: contain;
    }

    &__table {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        max-width: calc(100% - 3.125rem);
    }

    &__buttons {
        display: flex;
        flex-flow: wrap;
        width: 100%;

        .btn {
            margin: 1.25rem 0.75rem 0 0;
        }
    }

    &__button-wrapper {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    &__info {
        margin-top: 0.5rem;
        font-size: 0.75rem;
    }

    &__filename {
        color: $secondary;

        .btn__icon-right {
            color: $primary;
        }
    }
}
