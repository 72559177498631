.znd-shop-cart-page {
    $price-min-width: 8rem;
    margin: 1.25rem;

    @include cartLineItemOnDesktop {
        margin: 1.875rem;
    }

    @include media-breakpoint-up(md) {
        margin: 2.5rem;
    }

    &__title {
        margin-bottom: 0.25rem;
        color: $secondary;
    }

    &__subtitle {
        color: $gray-500;
    }

    &__quick-order {
        border-bottom: 1px $gray-200 solid;
        padding: 1.25rem;

        &__input {
            border: 1px solid $gray-400;
            border-radius: 0.375rem;

            background-color: $gray-100;

            padding: 1rem;

            width: 100%;
            height: 3.125rem;
            color: $gray-500;
            &-wrapper {
                position: relative;
                width: 100%;

                @include media-breakpoint-up(sm) {
                    width: 18.75rem;
                }
            }
        }

        &__icon {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);

            width: 1rem;
            height: 1rem;
        }
    }

    &__items-list {
        margin-top: 2.25rem;
        margin-bottom: 1.25rem;

        border-radius: 0.375rem;
        background-color: $white;

        @include cartLineItemOnDesktop {
            margin-bottom: 0;
        }

        &-wrapper {
            position: relative;
            border-radius: 0.375rem;
            background-color: white;

            &.--quick-order-is-focused {
                z-index: 300;
            }
        }

        &__footer {
            display: flex;
            position: fixed;
            bottom: 0;
            left: 0;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            z-index: 200;

            box-shadow: $elevation-shadow-200;
            border-top-right-radius: 0.75rem;

            border-top-left-radius: 0.75rem;

            background-color: $white;

            padding: 1.25rem;

            width: 100%;

            @include media-breakpoint-up(md) {
                padding: 1.25rem 2.5rem 1.25rem 3.75rem;
            }

            @include cartLineItemOnDesktop {
                display: flex;
                position: relative;
                flex-direction: column;
                align-items: flex-end;
                box-shadow: none;
                background-color: unset;
                padding: 1.5rem;
            }

            &__subtotal {
                display: flex;
                align-items: flex-end;
                color: $secondary;

                @include cartLineItemOnDesktop {
                    flex-direction: row;
                    padding-top: 0.5rem;
                }

                &__price {
                    @include t-subhead-1;
                    padding-left: 1rem;

                    @include cartLineItemOnDesktop {
                        @include t-headline-2;
                        padding-left: 1.5rem;
                        min-width: $price-min-width;
                    }
                }

                &__text {
                    @include t-body-small;

                    @include cartLineItemOnDesktop {
                        @include t-headline-2;
                    }
                }
            }

            &__subtotal-exclude {
                display: none;
                padding-top: 0.25rem;
                padding-bottom: 1.75rem;
                color: $gray-300;

                @include cartLineItemOnDesktop {
                    display: block;
                }
            }

            &__order-on-account-blocked-message {
                padding-left: 1rem;
                width: 100%;

                @include cartLineItemOnDesktop {
                    margin-bottom: 1.75rem;
                    width: auto;
                }
            }

            &__subtotal-discount-surcharge {
                padding-top: 0.25rem;
                width: 100%;
                color: $gray-300;

                @include cartLineItemOnDesktop {
                    display: block;
                    font-size: 1rem;
                }

                &-wrapper {
                    display: none;
                    width: 100%;

                    @include cartLineItemOnDesktop {
                        display: block;
                    }
                }

                &-wrapper-mobile {
                    padding: 1rem 1.25rem 1.25rem;

                    @include media-breakpoint-up(sm) {
                        margin-left: auto;
                    }

                    @include cartLineItemOnDesktop {
                        display: none;
                    }
                }

                &__label {
                    @include media-breakpoint-up(sm) {
                        margin-left: auto;
                        text-align: right;
                    }
                }

                &__remove {
                    vertical-align: middle;
                    border: none;
                    background: none;
                    padding: 0;
                }

                &__price {
                    margin-top: auto;
                    padding-left: 1.5rem;
                    text-align: right;

                    @include media-breakpoint-up(sm) {
                        min-width: calc(#{$price-min-width} + 1px);
                    }
                }
            }

            &__subtotal-values {
                @include cartLineItemOnDesktop {
                    align-items: flex-end;
                }
            }

            &__buttons {
                display: flex;

                &__proceed {
                    margin-left: 0.75rem;
                }
            }
        }
    }

    &__voucher {
        margin-bottom: 8.125rem;
    }
}
