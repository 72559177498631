.znd-software-license-modal {
    &__info {
        display: flex;
        margin-bottom: 1.5rem;

        &-icon {
            background-color: $secondary;
        }

        &-text {
            font-weight: $font-weight-bold;
            font-size: 1rem;
            line-height: 1.375rem;
        }
    }

    &__text {
        a {
            color: $gray-500;
            font-weight: $font-weight-bold;
            text-decoration: underline;
        }
    }

    &__checkbox-label {
        cursor: pointer;
        margin-bottom: 0;
        padding-top: 0.75rem;

        .custom-control-label {
            padding-left: 0.375rem;

            &::before,
            &::after {
                top: 50%;
                transform: translateY(-50%);
                width: 1.375rem;
                height: 1.375rem;
            }

            &::before {
                border-radius: 0.25rem;
            }

            &::after {
                left: -1.75rem;
            }
        }
    }

    &__guide {
        margin-top: 1.5rem;

        a {
            color: $gray-500;
            font-weight: $font-weight-bold;
            text-decoration: underline;
        }
    }

    &__button {
        padding-right: 2rem;
        padding-left: 2rem;

        &[disabled] {
            background-color: $gray-100;
            color: $gray-300;
        }
    }
}
