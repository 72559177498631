.znd-dashboard-shortcut-item {
    display: flex;
    border-radius: $border-radius;
    background-color: $white;
    padding: 1.25rem;

    & + & {
        margin-top: 0.625rem;
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.25rem;
        border-radius: $border-radius;
        background-color: $gray-100;

        width: 3.125rem;
        height: 3.125rem;
        color: $gray-400;

        font-size: 1.25rem;
    }

    &__text {
        flex: 1;
        align-self: center;
        margin-right: 1.25rem;
        min-width: 0;
    }

    &__text-title {
        color: $secondary;
        font-weight: $font-weight-bold;
    }

    &__text-body {
        margin-top: 0.25rem;
        overflow: hidden;
        color: $gray-500;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__link,
    &__link:hover {
        align-self: center;
        margin-left: auto;
        color: $primary;

        font-size: 1.5rem;
        text-decoration: none;
    }
}
