.znd-download-tile {
    cursor: pointer;
    padding: 0.25rem;

    &__inner {
        display: flex;
        border: 1px solid $gray-200;
        border-radius: 0.375rem;
        padding: 1rem;
        height: 100%;
    }

    &__detail {
        overflow: hidden;
    }

    &__title {
        overflow: hidden;
        color: $secondary;
        line-height: 1.625rem;
        text-overflow: ellipsis;
    }

    &__info {
        margin-top: 0.25rem;
        color: $gray-400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        letter-spacing: 0.08em;
    }

    &__icon {
        display: flex;
        align-items: center;
        margin-right: 0.625rem;
        margin-left: auto;
        padding-left: 1rem;
        color: $primary;
        font-size: 1.5rem;

        &--external-link {
            font-size: 1.375rem;
        }
    }
}
