.znd-loading-spinner {
    $component: &;
    width: 4rem;

    height: 4rem;

    &--position-absolute {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);

        path {
            stroke: currentColor;
        }
    }

    &--small {
        width: 1rem;
        height: 1rem;

        path {
            stroke-width: 6px;
        }
    }

    &__path {
        stroke-width: 2px;
        stroke: $primary;
        stroke-dasharray: 150, 391;
        fill: none;
        animation: dash 1s linear infinite;
    }

    &--thick &__path {
        stroke-width: 4px;
    }

    &--center {
        position: absolute;
        top: calc(50% - 2rem);
        left: calc(50% - 2rem);

        &#{$component}--small {
            top: calc(50% - 0.5rem);
            left: calc(50% - 0.5rem);
        }
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 541;
        }
    }
}
