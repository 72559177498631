// Disable stylelint as classes are defined by adyen.
// stylelint-disable
.adyen-checkout__spinner.adyen-checkout__spinner {
    border-color: $primary;
    border-top-color: transparent;
}

.adyen-checkout__label.adyen-checkout__label {
    .adyen-checkout__label__text {
        color: $body-color;
        font-weight: $font-weight-base;
        font-size: $font-size-base;
        line-height: $line-height-base;
    }
    &--focused,
    &--focused {
        .adyen-checkout__label__text {
            color: $body-color;
            font-weight: $font-weight-base;
            font-size: $font-size-base;
            line-height: $line-height-base;
        }
    }
}

.adyen-checkout__input-wrapper.adyen-checkout__input-wrapper {
    border: 0 none transparent;
    &,
    &:hover,
    &:active,
    &:active:hover,
    &:focus,
    &:focus:hover {
        border: 0 none transparent;
    }
}

.adyen-checkout__input.adyen-checkout__input {
    border-color: $input-border-color;
    background-color: $input-bg;
    height: calc(1.5em + 1.5rem + 2px);
    color: $input-color;
    caret-color: $input-color;

    &--focus,
    &:hover,
    &:active,
    &:active:hover,
    &:focus,
    &:focus:hover {
        box-shadow: none;
        border-color: $input-focus-border-color;
        color: $input-color;
        caret-color: $input-color;
    }
}

.adyen-checkout__image.adyen-checkout__image {
    vertical-align: top;
}

.adyen-checkout__input-wrapper.adyen-checkout__input-wrapper img {
    vertical-align: top;
}

.adyen-checkout__link.adyen-checkout__link {
    @extend .btn;
}
// stylelint-enable
