.znd-app {
    display: flex;
    flex-direction: column;
    padding-top: $mobileNavbarHeight;
    min-height: 100vh;

    @include navbarOnDesktop {
        padding-top: 0;
        padding-left: $navbarWidth;
    }
}
