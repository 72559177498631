.znd-user-button {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    &__name {
        padding-left: 0.625rem;

        @include navbarOnDesktop() {
            display: none;
        }
    }

    &__dropdown {
        position: absolute;
        bottom: 100%;
        left: 0;

        transform: translateY(-1.25rem);
        z-index: $zindex-dropdown;

        background-color: $white;
        min-width: 15rem;
        @include elevate(200);

        @include navbarOnDesktop {
            @supports (filter: drop-shadow($elevation-shadow-200)) {
                filter: drop-shadow($elevation-shadow-200);
                box-shadow: none;
            }
        }
        border-radius: $border-radius;

        padding: 0.75rem 0;

        &::before {
            display: block;

            position: absolute;
            top: 100%;
            left: (3.125rem / 2);

            transform: translateX(-50%) translateY(-2px);
            border: 0.625rem solid transparent;
            border-top: 0.75rem solid $white;

            width: 0;
            content: '';

            @include navbarOnDesktop {
                top: auto;
                right: (3.125rem / 2);
                bottom: 100%;
                left: auto;

                transform: translateX(50%) translateY(2px);

                border: 0.625rem solid transparent;
                border-bottom: 0.75rem solid $white;
            }
        }

        @include navbarOnDesktop {
            top: 100%;
            right: 0;
            bottom: auto;
            left: auto;

            transform: translateY(1.25rem);
        }

        &__title {
            border-bottom: 1px solid $gray-100;
            padding: 0.75rem 1.25rem;
        }
    }

    &__dropdown-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 0.75rem 1.25rem;

        @include t-body;

        &,
        &:hover,
        &:focus,
        &:active {
            color: $gray-500;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: rgba($gray-100, 0.4);
        }

        i {
            margin-right: 0.5rem;
            color: $gray-300;
            font-size: 1.25rem;
        }
    }
}
