.znd-tooltip {
    $component: &;

    position: relative;

    &__label {
        display: none;
        position: absolute;
        z-index: $zindex-tooltip;

        min-width: 100px;
        max-width: 300px;

        // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
        // So reset our font and text properties to avoid inheriting weird values.
        @include reset-text();
        @include font-size($tooltip-font-size);
        // Allow breaking very long words so they don't overflow the tooltip's bounds.
        color: $white;
        line-height: 1.25rem;
        text-align: center;
        word-wrap: break-word;
        visibility: hidden;

        opacity: 0;
        transition:
            opacity 0.2s ease-out,
            transform 0.2s ease-out,
            visibility 0.2s step-end;
        transition-delay: 0.1s;
        border-radius: $border-radius;

        background-color: $gray-500;

        padding: 0.375rem;

        &::before {
            display: block;

            position: absolute;

            background-image: $tooltip-arrow-svg;
            background-position: center center;
            background-size: 100% auto;
            background-repeat: no-repeat;
            width: $tooltip-arrow-width;
            height: $tooltip-arrow-height;
            content: '';
        }

        @each $breakpoint in map-keys($grid-breakpoints) {
            @include media-breakpoint-up($breakpoint) {
                $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
                #{$component}--visible#{$infix} & {
                    display: block;
                }
            }
        }
    }
    &--top-center {
        #{$component}__label {
            bottom: 100%;
            left: 50%;

            transform: translateX(-50%) scale(0.9);
            transform-origin: center bottom;
            margin-bottom: $tooltip-arrow-height - 0.25rem;

            &::before {
                top: 100%;
                left: 50%;
                transform: translateX(-50%) translateY(-0.375rem);
            }
        }

        &:hover #{$component}__label {
            transform: translateX(-50%) scale(1);
        }
    }

    &--top-left {
        #{$component}__label {
            bottom: 100%;
            left: 0;

            transform: scale(0.9);
            transform-origin: left bottom;
            margin-bottom: $tooltip-arrow-height - 0.25rem;

            &::before {
                top: 100%;
                left: 0.375rem;
                transform: translateY(-0.375rem);
            }
        }

        &:hover #{$component}__label {
            transform: scale(1);
        }
    }

    &--top-right {
        #{$component}__label {
            right: 0;
            bottom: 100%;

            transform: scale(0.9);
            transform-origin: right bottom;
            margin-bottom: $tooltip-arrow-height - 0.25rem;

            &::before {
                top: 100%;
                right: 0.375rem;
                transform: translateY(-0.375rem);
            }
        }

        &:hover #{$component}__label {
            transform: scale(1);
        }
    }

    &--bottom-center {
        #{$component}__label {
            top: 100%;
            left: 50%;

            transform: translateX(-50%) scale(0.9);
            transform-origin: center top;
            margin-top: $tooltip-arrow-height - 0.25rem;

            &::before {
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%) translateY(0.375rem) rotate(180deg);
            }
        }

        &:hover #{$component}__label {
            transform: translateX(-50%) scale(1);
        }
    }

    &--bottom-left {
        #{$component}__label {
            top: 100%;
            left: 0;

            transform: scale(0.9);
            transform-origin: left top;
            margin-top: $tooltip-arrow-height - 0.25rem;

            &::before {
                bottom: 100%;
                left: 0.375rem;
                transform: translateY(0.375rem) rotate(180deg);
            }
        }

        &:hover #{$component}__label {
            transform: scale(1);
        }
    }

    &--bottom-right {
        #{$component}__label {
            top: 100%;
            right: 0;

            transform: scale(0.9);
            transform-origin: right top;
            margin-top: $tooltip-arrow-height - 0.25rem;

            &::before {
                right: 0.375rem;
                bottom: 100%;
                transform: translateY(0.375rem) rotate(180deg);
            }
        }

        &:hover #{$component}__label {
            transform: scale(1);
        }
    }

    &--left {
        #{$component}__label {
            top: 50%;
            right: 100%;

            transform: translateY(-50%) scale(0.9);
            transform-origin: right center;
            margin-right: $tooltip-arrow-height - 0.25rem;

            &::before {
                top: 50%;
                left: 100%;
                transform: translateY(-50%) translateX(-0.75rem) rotate(-90deg);
            }
        }

        &:hover #{$component}__label {
            transform: translateY(-50%) scale(1);
        }
    }

    &--right {
        #{$component}__label {
            top: 50%;
            left: 100%;

            transform: translateY(-50%) scale(0.9);
            transform-origin: left center;
            margin-left: $tooltip-arrow-height - 0.25rem;

            &::before {
                top: 50%;
                right: 100%;
                transform: translateY(-50%) translateX(0.75rem) rotate(90deg);
            }
        }

        &:hover #{$component}__label {
            transform: translateY(-50%) scale(1);
        }
    }

    &:hover &__label {
        visibility: visible;
        opacity: 1;
        transition:
            opacity 0.1s ease-out,
            transform 0.1s ease-out,
            visibility 0.1s step-start;
        transition-delay: 0s;
    }
}
