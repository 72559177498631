// Paths need to be relative to the resulting `app.css` of the FE workflow build.

@font-face {
    font-style: normal;
    font-weight: $font-weight-normal;
    src: local('AkkuratPro-Regular'), url(../fonts/akkurat/AkkRg_Pro.otf);
    font-family: 'Akkurat Pro';
}
@font-face {
    font-style: normal;
    font-weight: $font-weight-bold;
    src: local('AkkuratPro-Bold'), url(../fonts/akkurat/AkkBd_Pro.otf);
    font-family: 'Akkurat Pro';
}
@font-face {
    font-style: italic;
    font-weight: $font-weight-normal;
    src: local('AkkuratPro-Italic'), url(../fonts/akkurat/AkkIt_Pro.otf);
    font-family: 'Akkurat Pro';
}
@font-face {
    font-style: italic;
    font-weight: $font-weight-bold;
    src: local('AkkuratPro-BoldItalic'), url(../fonts/akkurat/AkkBdIt_Pro.otf);
    font-family: 'Akkurat Pro';
}
