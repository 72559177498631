.znd-block {
    background-position: center;
    background-repeat: no-repeat;

    &__grid {
        display: grid;
        gap: 2.5rem;
        padding: 20px;

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__headline {
        padding: 70px 20px 20px;
    }

    &__text-on-image__text {
        display: grid;
        align-items: center;
        padding: 30px;
        min-height: 240px;
    }

    &__text-column {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 2.5rem;
    }

    p {
        margin-bottom: 0;
    }
}
