.znd-user-profile-page {
    margin-top: 1.25rem;

    &__sticky-header {
        position: sticky;
        top: $mobileNavbarHeight;
        z-index: $zindex-sticky;
        background-color: $gray-100;

        @include media-breakpoint-up(xl) {
            top: 0;
        }
    }

    &__content {
        margin-left: 0;
        padding: 0 1.25rem;
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-left: -0.625rem;
            padding: 0.625rem 2.5rem 0;
            width: 100%;
        }
    }

    &__content-data-title {
        margin: 1.875rem 0 1.25rem;

        @include media-breakpoint-up(md) {
            margin: 1.875rem 0.625rem 0.625rem;
        }
    }

    &__content-data-group {
        display: block;
        flex-wrap: nowrap;
        margin-bottom: 1.25rem;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__content-data-item {
        position: relative;
        margin: 1.25rem 0 1.25rem;
        border-radius: 0.375rem;
        background: $white;
        padding: 1.25rem;
        width: 100%;
        word-break: break-word;

        @include media-breakpoint-up(md) {
            margin: 0.625rem;
            width: calc(50% - 1.25rem);
        }

        @include media-breakpoint-up(lg) {
            padding: 2.5rem;
        }

        @include media-breakpoint-up(xl) {
            width: calc(33.333% - 1.25rem);
        }

        @include media-breakpoint-up(hg) {
            width: calc(25% - 1.25rem);
        }
    }

    &__table {
        width: 100%;
        overflow-y: scroll;
    }

    &__reorder-button {
        margin: 0.75rem 0;
    }

    // Overwrite custom button style of table.
    &#{&} &__reorder-button:hover {
        background-color: $gray-200;
    }

    &__placeholder-button {
        margin: 0.75rem 0;
        height: 2.625rem;
    }

    &__header {
        display: block;

        position: sticky;
        top: $mobileNavbarHeight + 3rem;
        z-index: subtract($zindex-sticky, 1);
        margin: 0;
        background-color: $gray-100;
        padding: 2.125rem 0 1.5rem;

        @include media-breakpoint-up(sm) {
            display: flex;
        }

        @include media-breakpoint-up(md) {
            margin: 0 0.625rem;
        }

        @include media-breakpoint-up(xl) {
            top: 3rem;
        }
    }

    &__subtitle {
        color: $gray-500;
    }

    &__add-button {
        margin-right: 0.875rem;
        margin-left: auto;

        @include media-breakpoint-down(xs) {
            margin-top: 0.875rem;
            width: 100%;
        }
    }

    &__administration {
        &-search {
            @include media-breakpoint-down(xs) {
                margin-top: 0.875rem;
            }
        }
    }

    &__bullet {
        display: block;
        border-radius: 50%;
        background-color: $primary;
        width: 1rem;
        height: 1rem;
    }

    &__empty,
    &__check {
        font-size: 1.25rem;
    }

    &__empty {
        color: $gray-300;
    }

    &__check {
        color: $primary;
    }

    &__button {
        font-size: 0.875rem;
    }
}
