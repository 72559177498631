.znd-dashboard-cutter-info-text {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.375rem;
    background-color: $white;
    padding: 1.25rem;
    height: 100%;

    &__text {
        color: $gray-500;
        font-size: 1.125rem;
    }
}
