@import 'variables';

@font-face {
    font-style: normal;
    font-weight: 400;
    src:
        url('../../fonts/zupicons/zupicons.ttf?le6qgo') format('truetype'),
        url('../../fonts/zupicons/zupicons.woff?le6qgo') format('woff');
    font-family: 'zupicons';
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: 'zupicons' !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-question {
    &:before {
        content: $icon-question;
    }
}
.icon-arrows-vertical {
    &:before {
        content: $icon-arrows-vertical;
    }
}
.icon-serial-number {
    &:before {
        content: $icon-serial-number;
    }
}
.icon-union {
    &:before {
        content: $icon-union;
    }
}
.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}
.icon-flickr {
    &:before {
        content: $icon-flickr;
    }
}
.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}
.icon-pinterest {
    &:before {
        content: $icon-pinterest;
    }
}
.icon-apple-pay {
    &:before {
        content: $icon-apple-pay;
    }
}
.icon-cc-amazon-pay {
    &:before {
        content: $icon-cc-amazon-pay;
    }
}
.icon-cc-amex {
    &:before {
        content: $icon-cc-amex;
    }
}
.icon-cc-apple-pay {
    &:before {
        content: $icon-cc-apple-pay;
    }
}
.icon-cc-dc {
    &:before {
        content: $icon-cc-dc;
    }
}
.icon-cc-discover {
    &:before {
        content: $icon-cc-discover;
    }
}
.icon-cc-jbc {
    &:before {
        content: $icon-cc-jbc;
    }
}
.icon-cc-mastercard {
    &:before {
        content: $icon-cc-mastercard;
    }
}
.icon-cc-paypal {
    &:before {
        content: $icon-cc-paypal;
    }
}
.icon-cc-stripe {
    &:before {
        content: $icon-cc-stripe;
    }
}
.icon-cc-visa {
    &:before {
        content: $icon-cc-visa;
    }
}
.icon-dollar {
    &:before {
        content: $icon-dollar;
    }
}
.icon-euro {
    &:before {
        content: $icon-euro;
    }
}
.icon-lira {
    &:before {
        content: $icon-lira;
    }
}
.icon-rupee {
    &:before {
        content: $icon-rupee;
    }
}
.icon-yen {
    &:before {
        content: $icon-yen;
    }
}
.icon-figma {
    &:before {
        content: $icon-figma;
    }
}
.icon-yahoo {
    &:before {
        content: $icon-yahoo;
    }
}
.icon-amazon {
    &:before {
        content: $icon-amazon;
    }
}
.icon-app-store {
    &:before {
        content: $icon-app-store;
    }
}
.icon-apple {
    &:before {
        content: $icon-apple;
    }
}
.icon-chrome {
    &:before {
        content: $icon-chrome;
    }
}
.icon-google-drive {
    &:before {
        content: $icon-google-drive;
    }
}
.icon-google-play {
    &:before {
        content: $icon-google-play;
    }
}
.icon-google {
    &:before {
        content: $icon-google;
    }
}
.icon-itunes {
    &:before {
        content: $icon-itunes;
    }
}
.icon-microsoft {
    &:before {
        content: $icon-microsoft;
    }
}
.icon-paypal {
    &:before {
        content: $icon-paypal;
    }
}
.icon-windows {
    &:before {
        content: $icon-windows;
    }
}
.icon-google-plus-alt {
    &:before {
        content: $icon-google-plus-alt;
    }
}
.icon-blog {
    &:before {
        content: $icon-blog;
    }
}
.icon-facebook-messenger {
    &:before {
        content: $icon-facebook-messenger;
    }
}
.icon-facebook-square {
    &:before {
        content: $icon-facebook-square;
    }
}
.icon-facebook-circle {
    &:before {
        content: $icon-facebook-circle;
    }
}
.icon-google-plus {
    &:before {
        content: $icon-google-plus;
    }
}
.icon-instagram {
    &:before {
        content: $icon-instagram;
    }
}
.icon-pinterest-circle {
    &:before {
        content: $icon-pinterest-circle;
    }
}
.icon-sms {
    &:before {
        content: $icon-sms;
    }
}
.icon-snapchat {
    &:before {
        content: $icon-snapchat;
    }
}
.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}
.icon-vimeo {
    &:before {
        content: $icon-vimeo;
    }
}
.icon-whatsapp {
    &:before {
        content: $icon-whatsapp;
    }
}
.icon-youtube {
    &:before {
        content: $icon-youtube;
    }
}
.icon-user-alt-slash {
    &:before {
        content: $icon-user-alt-slash;
    }
}
.icon-user-alt {
    &:before {
        content: $icon-user-alt;
    }
}
.icon-user-edit {
    &:before {
        content: $icon-user-edit;
    }
}
.icon-user-plus {
    &:before {
        content: $icon-user-plus;
    }
}
.icon-user {
    &:before {
        content: $icon-user;
    }
}
.icon-copy-alt {
    &:before {
        content: $icon-copy-alt;
    }
}
.icon-copy {
    &:before {
        content: $icon-copy;
    }
}
.icon-image {
    &:before {
        content: $icon-image;
    }
}
.icon-images {
    &:before {
        content: $icon-images;
    }
}
.icon-js {
    &:before {
        content: $icon-js;
    }
}
.icon-folder-download {
    &:before {
        content: $icon-folder-download;
    }
}
.icon-folder-minus {
    &:before {
        content: $icon-folder-minus;
    }
}
.icon-folder-open {
    &:before {
        content: $icon-folder-open;
    }
}
.icon-folder-plus {
    &:before {
        content: $icon-folder-plus;
    }
}
.icon-folder-upload {
    &:before {
        content: $icon-folder-upload;
    }
}
.icon-folder {
    &:before {
        content: $icon-folder;
    }
}
.icon-folders {
    &:before {
        content: $icon-folders;
    }
}
.icon-file-alt {
    &:before {
        content: $icon-file-alt;
    }
}
.icon-file-archive {
    &:before {
        content: $icon-file-archive;
    }
}
.icon-file-code {
    &:before {
        content: $icon-file-code;
    }
}
.icon-file-contracts {
    &:before {
        content: $icon-file-contracts;
    }
}
.icon-file-download {
    &:before {
        content: $icon-file-download;
    }
}
.icon-file-edit {
    &:before {
        content: $icon-file-edit;
    }
}
.icon-file-export {
    &:before {
        content: $icon-file-export;
    }
}
.icon-file-image {
    &:before {
        content: $icon-file-image;
    }
}
.icon-file-import {
    &:before {
        content: $icon-file-import;
    }
}
.icon-file-minus {
    &:before {
        content: $icon-file-minus;
    }
}
.icon-file-music {
    &:before {
        content: $icon-file-music;
    }
}
.icon-file-pdf {
    &:before {
        content: $icon-file-pdf;
    }
}
.icon-file-plus {
    &:before {
        content: $icon-file-plus;
    }
}
.icon-file-powerpoint {
    &:before {
        content: $icon-file-powerpoint;
    }
}
.icon-file-search {
    &:before {
        content: $icon-file-search;
    }
}
.icon-file-upload {
    &:before {
        content: $icon-file-upload;
    }
}
.icon-file-user {
    &:before {
        content: $icon-file-user;
    }
}
.icon-file-video {
    &:before {
        content: $icon-file-video;
    }
}
.icon-file-word {
    &:before {
        content: $icon-file-word;
    }
}
.icon-file {
    &:before {
        content: $icon-file;
    }
}
.icon-angle-double-down {
    &:before {
        content: $icon-angle-double-down;
    }
}
.icon-angle-double-left {
    &:before {
        content: $icon-angle-double-left;
    }
}
.icon-angle-double-right {
    &:before {
        content: $icon-angle-double-right;
    }
}
.icon-angle-double-up {
    &:before {
        content: $icon-angle-double-up;
    }
}
.icon-angle-down {
    &:before {
        content: $icon-angle-down;
    }
}
.icon-angle-left {
    &:before {
        content: $icon-angle-left;
    }
}
.icon-angle-right {
    &:before {
        content: $icon-angle-right;
    }
}
.icon-angle-up {
    &:before {
        content: $icon-angle-up;
    }
}
.icon-arrow-alt-circle-down {
    &:before {
        content: $icon-arrow-alt-circle-down;
    }
}
.icon-arrow-alt-circle-left {
    &:before {
        content: $icon-arrow-alt-circle-left;
    }
}
.icon-arrow-alt-circle-right {
    &:before {
        content: $icon-arrow-alt-circle-right;
    }
}
.icon-arrow-alt-circle-up {
    &:before {
        content: $icon-arrow-alt-circle-up;
    }
}
.icon-arrow-alt-down {
    &:before {
        content: $icon-arrow-alt-down;
    }
}
.icon-arrow-alt-left {
    &:before {
        content: $icon-arrow-alt-left;
    }
}
.icon-arrow-alt-right {
    &:before {
        content: $icon-arrow-alt-right;
    }
}
.icon-arrow-alt-up {
    &:before {
        content: $icon-arrow-alt-up;
    }
}
.icon-arrow-alt-from-top {
    &:before {
        content: $icon-arrow-alt-from-top;
    }
}
.icon-arrow-alt-from-right {
    &:before {
        content: $icon-arrow-alt-from-right;
    }
}
.icon-arrow-alt-from-left {
    &:before {
        content: $icon-arrow-alt-from-left;
    }
}
.icon-arrow-alt-from-bottom {
    &:before {
        content: $icon-arrow-alt-from-bottom;
    }
}
.icon-arrow-alt-square-down {
    &:before {
        content: $icon-arrow-alt-square-down;
    }
}
.icon-arrow-alt-square-left {
    &:before {
        content: $icon-arrow-alt-square-left;
    }
}
.icon-arrow-alt-square-right {
    &:before {
        content: $icon-arrow-alt-square-right;
    }
}
.icon-arrow-alt-square-top {
    &:before {
        content: $icon-arrow-alt-square-top;
    }
}
.icon-arrow-circle-down {
    &:before {
        content: $icon-arrow-circle-down;
    }
}
.icon-arrow-circle-left {
    &:before {
        content: $icon-arrow-circle-left;
    }
}
.icon-arrow-circle-right {
    &:before {
        content: $icon-arrow-circle-right;
    }
}
.icon-arrow-circle-top {
    &:before {
        content: $icon-arrow-circle-top;
    }
}
.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}
.icon-arrow-down-left {
    &:before {
        content: $icon-arrow-down-left;
    }
}
.icon-arrow-down-right {
    &:before {
        content: $icon-arrow-down-right;
    }
}
.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}
.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}
.icon-arrow-up-left {
    &:before {
        content: $icon-arrow-up-left;
    }
}
.icon-arrow-up-right {
    &:before {
        content: $icon-arrow-up-right;
    }
}
.icon-arrow-up {
    &:before {
        content: $icon-arrow-up;
    }
}
.icon-caret-circle-alt-down {
    &:before {
        content: $icon-caret-circle-alt-down;
    }
}
.icon-caret-circle-alt-left {
    &:before {
        content: $icon-caret-circle-alt-left;
    }
}
.icon-caret-circle-alt-right {
    &:before {
        content: $icon-caret-circle-alt-right;
    }
}
.icon-caret-circle-alt-up {
    &:before {
        content: $icon-caret-circle-alt-up;
    }
}
.icon-caret-circle-down {
    &:before {
        content: $icon-caret-circle-down;
    }
}
.icon-caret-circle-left {
    &:before {
        content: $icon-caret-circle-left;
    }
}
.icon-caret-circle-right {
    &:before {
        content: $icon-caret-circle-right;
    }
}
.icon-caret-circle-up {
    &:before {
        content: $icon-caret-circle-up;
    }
}
.icon-caret-down {
    &:before {
        content: $icon-caret-down;
    }
}
.icon-caret-left {
    &:before {
        content: $icon-caret-left;
    }
}
.icon-caret-right {
    &:before {
        content: $icon-caret-right;
    }
}
.icon-caret-up {
    &:before {
        content: $icon-caret-up;
    }
}
.icon-chevron-circle-alt-down {
    &:before {
        content: $icon-chevron-circle-alt-down;
    }
}
.icon-chevron-circle-alt-left {
    &:before {
        content: $icon-chevron-circle-alt-left;
    }
}
.icon-chevron-circle-alt-right {
    &:before {
        content: $icon-chevron-circle-alt-right;
    }
}
.icon-chevron-circle-alt-up {
    &:before {
        content: $icon-chevron-circle-alt-up;
    }
}
.icon-chevron-circle-down {
    &:before {
        content: $icon-chevron-circle-down;
    }
}
.icon-chevron-circle-left {
    &:before {
        content: $icon-chevron-circle-left;
    }
}
.icon-chevron-circle-right {
    &:before {
        content: $icon-chevron-circle-right;
    }
}
.icon-chevron-circle-up {
    &:before {
        content: $icon-chevron-circle-up;
    }
}
.icon-basketball {
    &:before {
        content: $icon-basketball;
    }
}
.icon-accessible {
    &:before {
        content: $icon-accessible;
    }
}
.icon-adjust {
    &:before {
        content: $icon-adjust;
    }
}
.icon-adress-book {
    &:before {
        content: $icon-adress-book;
    }
}
.icon-adress-card {
    &:before {
        content: $icon-adress-card;
    }
}
.icon-alarm-clock {
    &:before {
        content: $icon-alarm-clock;
    }
}
.icon-alarm-plus {
    &:before {
        content: $icon-alarm-plus;
    }
}
.icon-album {
    &:before {
        content: $icon-album;
    }
}
.icon-alien {
    &:before {
        content: $icon-alien;
    }
}
.icon-align-center {
    &:before {
        content: $icon-align-center;
    }
}
.icon-align-justify {
    &:before {
        content: $icon-align-justify;
    }
}
.icon-align-left {
    &:before {
        content: $icon-align-left;
    }
}
.icon-align-right {
    &:before {
        content: $icon-align-right;
    }
}
.icon-allergies {
    &:before {
        content: $icon-allergies;
    }
}
.icon-ambulance {
    &:before {
        content: $icon-ambulance;
    }
}
.icon-analytics-primary {
    &:before {
        content: $icon-analytics-primary;
    }
}
.icon-analytics {
    &:before {
        content: $icon-analytics;
    }
}
.icon-anchor {
    &:before {
        content: $icon-anchor;
    }
}
.icon-angry {
    &:before {
        content: $icon-angry;
    }
}
.icon-archive {
    &:before {
        content: $icon-archive;
    }
}
.icon-arrows-primary {
    &:before {
        content: $icon-arrows-primary;
    }
}
.icon-arrows {
    &:before {
        content: $icon-arrows;
    }
}
.icon-at-sign {
    &:before {
        content: $icon-at-sign;
    }
}
.icon-atlas {
    &:before {
        content: $icon-atlas;
    }
}
.icon-atom {
    &:before {
        content: $icon-atom;
    }
}
.icon-award {
    &:before {
        content: $icon-award;
    }
}
.icon-axe {
    &:before {
        content: $icon-axe;
    }
}
.icon-baby {
    &:before {
        content: $icon-baby;
    }
}
.icon-backpack {
    &:before {
        content: $icon-backpack;
    }
}
.icon-backward {
    &:before {
        content: $icon-backward;
    }
}
.icon-badge-dollar {
    &:before {
        content: $icon-badge-dollar;
    }
}
.icon-badge-percent {
    &:before {
        content: $icon-badge-percent;
    }
}
.icon-bag-shopping {
    &:before {
        content: $icon-bag-shopping;
    }
}
.icon-ballot {
    &:before {
        content: $icon-ballot;
    }
}
.icon-ban {
    &:before {
        content: $icon-ban;
    }
}
.icon-barcode {
    &:before {
        content: $icon-barcode;
    }
}
.icon-bars {
    &:before {
        content: $icon-bars;
    }
}
.icon-bat {
    &:before {
        content: $icon-bat;
    }
}
.icon-battery-empty {
    &:before {
        content: $icon-battery-empty;
    }
}
.icon-battery-full {
    &:before {
        content: $icon-battery-full;
    }
}
.icon-battery-half {
    &:before {
        content: $icon-battery-half;
    }
}
.icon-battery-slash {
    &:before {
        content: $icon-battery-slash;
    }
}
.icon-battery-three-quarters {
    &:before {
        content: $icon-battery-three-quarters;
    }
}
.icon-bed {
    &:before {
        content: $icon-bed;
    }
}
.icon-beer {
    &:before {
        content: $icon-beer;
    }
}
.icon-bell {
    &:before {
        content: $icon-bell;
    }
}
.icon-biohazard {
    &:before {
        content: $icon-biohazard;
    }
}
.icon-blanket {
    &:before {
        content: $icon-blanket;
    }
}
.icon-bluetooth {
    &:before {
        content: $icon-bluetooth;
    }
}
.icon-bolt {
    &:before {
        content: $icon-bolt;
    }
}
.icon-book-alt {
    &:before {
        content: $icon-book-alt;
    }
}
.icon-book-open {
    &:before {
        content: $icon-book-open;
    }
}
.icon-book {
    &:before {
        content: $icon-book;
    }
}
.icon-bookmark {
    &:before {
        content: $icon-bookmark;
    }
}
.icon-box-fragile {
    &:before {
        content: $icon-box-fragile;
    }
}
.icon-box-open {
    &:before {
        content: $icon-box-open;
    }
}
.icon-box-up {
    &:before {
        content: $icon-box-up;
    }
}
.icon-box {
    &:before {
        content: $icon-box;
    }
}
.icon-brackets-curly {
    &:before {
        content: $icon-brackets-curly;
    }
}
.icon-brackets {
    &:before {
        content: $icon-brackets;
    }
}
.icon-brain {
    &:before {
        content: $icon-brain;
    }
}
.icon-briefcase-medical {
    &:before {
        content: $icon-briefcase-medical;
    }
}
.icon-briefcase {
    &:before {
        content: $icon-briefcase;
    }
}
.icon-bring-forward {
    &:before {
        content: $icon-bring-forward;
    }
}
.icon-broadcast-tower {
    &:before {
        content: $icon-broadcast-tower;
    }
}
.icon-browser {
    &:before {
        content: $icon-browser;
    }
}
.icon-brush {
    &:before {
        content: $icon-brush;
    }
}
.icon-buffer {
    &:before {
        content: $icon-buffer;
    }
}
.icon-building {
    &:before {
        content: $icon-building;
    }
}
.icon-bullhorn {
    &:before {
        content: $icon-bullhorn;
    }
}
.icon-bullseye {
    &:before {
        content: $icon-bullseye;
    }
}
.icon-burn {
    &:before {
        content: $icon-burn;
    }
}
.icon-bus-alt {
    &:before {
        content: $icon-bus-alt;
    }
}
.icon-bus {
    &:before {
        content: $icon-bus;
    }
}
.icon-bycicle {
    &:before {
        content: $icon-bycicle;
    }
}
.icon-calculator {
    &:before {
        content: $icon-calculator;
    }
}
.icon-calendar-alt {
    &:before {
        content: $icon-calendar-alt;
    }
}
.icon-calendar-check {
    &:before {
        content: $icon-calendar-check;
    }
}
.icon-calendar-day {
    &:before {
        content: $icon-calendar-day;
    }
}
.icon-calendar-edit {
    &:before {
        content: $icon-calendar-edit;
    }
}
.icon-calendar-minus {
    &:before {
        content: $icon-calendar-minus;
    }
}
.icon-calendar-plus {
    &:before {
        content: $icon-calendar-plus;
    }
}
.icon-calendar-star {
    &:before {
        content: $icon-calendar-star;
    }
}
.icon-calendar-times {
    &:before {
        content: $icon-calendar-times;
    }
}
.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}
.icon-camcorder {
    &:before {
        content: $icon-camcorder;
    }
}
.icon-camera-movie {
    &:before {
        content: $icon-camera-movie;
    }
}
.icon-camera {
    &:before {
        content: $icon-camera;
    }
}
.icon-campfire {
    &:before {
        content: $icon-campfire;
    }
}
.icon-campground {
    &:before {
        content: $icon-campground;
    }
}
.icon-capsules {
    &:before {
        content: $icon-capsules;
    }
}
.icon-car-alt {
    &:before {
        content: $icon-car-alt;
    }
}
.icon-car-battery {
    &:before {
        content: $icon-car-battery;
    }
}
.icon-car-garage {
    &:before {
        content: $icon-car-garage;
    }
}
.icon-car-mechanics {
    &:before {
        content: $icon-car-mechanics;
    }
}
.icon-car-side {
    &:before {
        content: $icon-car-side;
    }
}
.icon-car-wash {
    &:before {
        content: $icon-car-wash;
    }
}
.icon-car {
    &:before {
        content: $icon-car;
    }
}
.icon-caravan {
    &:before {
        content: $icon-caravan;
    }
}
.icon-cars {
    &:before {
        content: $icon-cars;
    }
}
.icon-cart-arrow-down {
    &:before {
        content: $icon-cart-arrow-down;
    }
}
.icon-cart-plus {
    &:before {
        content: $icon-cart-plus;
    }
}
.icon-chair {
    &:before {
        content: $icon-chair;
    }
}
.icon-charging-station {
    &:before {
        content: $icon-charging-station;
    }
}
.icon-dashboard {
    &:before {
        content: $icon-dashboard;
    }
}
.icon-chart-area {
    &:before {
        content: $icon-chart-area;
    }
}
.icon-chart-bar {
    &:before {
        content: $icon-chart-bar;
    }
}
.icon-chart-line-down {
    &:before {
        content: $icon-chart-line-down;
    }
}
.icon-chart-line {
    &:before {
        content: $icon-chart-line;
    }
}
.icon-chart-network {
    &:before {
        content: $icon-chart-network;
    }
}
.icon-chart-pie-alt {
    &:before {
        content: $icon-chart-pie-alt;
    }
}
.icon-chart-pie {
    &:before {
        content: $icon-chart-pie;
    }
}
.icon-chart-scatter {
    &:before {
        content: $icon-chart-scatter;
    }
}
.icon-check-circle {
    &:before {
        content: $icon-check-circle;
    }
}
.icon-check-double {
    &:before {
        content: $icon-check-double;
    }
}
.icon-check-square-alt {
    &:before {
        content: $icon-check-square-alt;
    }
}
.icon-check-square {
    &:before {
        content: $icon-check-square;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-chromecast {
    &:before {
        content: $icon-chromecast;
    }
}
.icon-circle {
    &:before {
        content: $icon-circle;
    }
}
.icon-clinic-medical {
    &:before {
        content: $icon-clinic-medical;
    }
}
.icon-clipboard-check {
    &:before {
        content: $icon-clipboard-check;
    }
}
.icon-clipboard-list {
    &:before {
        content: $icon-clipboard-list;
    }
}
.icon-clipboard {
    &:before {
        content: $icon-clipboard;
    }
}
.icon-clock {
    &:before {
        content: $icon-clock;
    }
}
.icon-clone {
    &:before {
        content: $icon-clone;
    }
}
.icon-close {
    &:before {
        content: $icon-close;
    }
}
.icon-close-alt {
    &:before {
        content: $icon-close-alt;
    }
}
.icon-cloud-drizzle {
    &:before {
        content: $icon-cloud-drizzle;
    }
}
.icon-cloud-shower {
    &:before {
        content: $icon-cloud-shower;
    }
}
.icon-cloud-snow {
    &:before {
        content: $icon-cloud-snow;
    }
}
.icon-cloud {
    &:before {
        content: $icon-cloud;
    }
}
.icon-code {
    &:before {
        content: $icon-code;
    }
}
.icon-coffee {
    &:before {
        content: $icon-coffee;
    }
}
.icon-cog-alt {
    &:before {
        content: $icon-cog-alt;
    }
}
.icon-cog {
    &:before {
        content: $icon-cog;
    }
}
.icon-coins {
    &:before {
        content: $icon-coins;
    }
}
.icon-comment-alt {
    &:before {
        content: $icon-comment-alt;
    }
}
.icon-comment-s-alt {
    &:before {
        content: $icon-comment-s-alt;
    }
}
.icon-comment-s {
    &:before {
        content: $icon-comment-s;
    }
}
.icon-comment {
    &:before {
        content: $icon-comment;
    }
}
.icon-comments-alt {
    &:before {
        content: $icon-comments-alt;
    }
}
.icon-comments-s-alt {
    &:before {
        content: $icon-comments-s-alt;
    }
}
.icon-comments-s {
    &:before {
        content: $icon-comments-s;
    }
}
.icon-comments {
    &:before {
        content: $icon-comments;
    }
}
.icon-compress {
    &:before {
        content: $icon-compress;
    }
}
.icon-cookie {
    &:before {
        content: $icon-cookie;
    }
}
.icon-copyright {
    &:before {
        content: $icon-copyright;
    }
}
.icon-creative-commons {
    &:before {
        content: $icon-creative-commons;
    }
}
.icon-credit-card-alt {
    &:before {
        content: $icon-credit-card-alt;
    }
}
.icon-credit-card {
    &:before {
        content: $icon-credit-card;
    }
}
.icon-crop {
    &:before {
        content: $icon-crop;
    }
}
.icon-crosshairs {
    &:before {
        content: $icon-crosshairs;
    }
}
.icon-database-alt {
    &:before {
        content: $icon-database-alt;
    }
}
.icon-database {
    &:before {
        content: $icon-database;
    }
}
.icon-dolly {
    &:before {
        content: $icon-dolly;
    }
}
.icon-download-alt {
    &:before {
        content: $icon-download-alt;
    }
}
.icon-download-cloud {
    &:before {
        content: $icon-download-cloud;
    }
}
.icon-download {
    &:before {
        content: $icon-download;
    }
}
.icon-edit-alt {
    &:before {
        content: $icon-edit-alt;
    }
}
.icon-edit {
    &:before {
        content: $icon-edit;
    }
}
.icon-envelope-open {
    &:before {
        content: $icon-envelope-open;
    }
}
.icon-envelope {
    &:before {
        content: $icon-envelope;
    }
}
.icon-eraser {
    &:before {
        content: $icon-eraser;
    }
}
.icon-exchange {
    &:before {
        content: $icon-exchange;
    }
}
.icon-exclamation-triangle-alt {
    &:before {
        content: $icon-exclamation-triangle-alt;
    }
}
.icon-exclamation-triangle {
    &:before {
        content: $icon-exclamation-triangle;
    }
}
.icon-expand-arrows {
    &:before {
        content: $icon-expand-arrows;
    }
}
.icon-expand {
    &:before {
        content: $icon-expand;
    }
}
.icon-external-link {
    &:before {
        content: $icon-external-link;
    }
}
.icon-eye-slash {
    &:before {
        content: $icon-eye-slash;
    }
}
.icon-eye {
    &:before {
        content: $icon-eye;
    }
}
.icon-fast-backward {
    &:before {
        content: $icon-fast-backward;
    }
}
.icon-fast-forward {
    &:before {
        content: $icon-fast-forward;
    }
}
.icon-fax {
    &:before {
        content: $icon-fax;
    }
}
.icon-female {
    &:before {
        content: $icon-female;
    }
}
.icon-film {
    &:before {
        content: $icon-film;
    }
}
.icon-filter {
    &:before {
        content: $icon-filter;
    }
}
.icon-fingerprint {
    &:before {
        content: $icon-fingerprint;
    }
}
.icon-first-aid {
    &:before {
        content: $icon-first-aid;
    }
}
.icon-flag {
    &:before {
        content: $icon-flag;
    }
}
.icon-font-case {
    &:before {
        content: $icon-font-case;
    }
}
.icon-fonts {
    &:before {
        content: $icon-fonts;
    }
}
.icon-forward-1 {
    &:before {
        content: $icon-forward-1;
    }
}
.icon-forward {
    &:before {
        content: $icon-forward;
    }
}
.icon-gas-pump-slash {
    &:before {
        content: $icon-gas-pump-slash;
    }
}
.icon-gas-pump {
    &:before {
        content: $icon-gas-pump;
    }
}
.icon-glass-alt {
    &:before {
        content: $icon-glass-alt;
    }
}
.icon-glass {
    &:before {
        content: $icon-glass;
    }
}
.icon-globe {
    &:before {
        content: $icon-globe;
    }
}
.icon-graduation {
    &:before {
        content: $icon-graduation;
    }
}
.icon-greater-than {
    &:before {
        content: $icon-greater-than;
    }
}
.icon-hand-paper-alt {
    &:before {
        content: $icon-hand-paper-alt;
    }
}
.icon-hand-paper {
    &:before {
        content: $icon-hand-paper;
    }
}
.icon-hand-point-alt {
    &:before {
        content: $icon-hand-point-alt;
    }
}
.icon-hand-point {
    &:before {
        content: $icon-hand-point;
    }
}
.icon-hand-pointer {
    &:before {
        content: $icon-hand-pointer;
    }
}
.icon-head-side {
    &:before {
        content: $icon-head-side;
    }
}
.icon-heart-rate {
    &:before {
        content: $icon-heart-rate;
    }
}
.icon-heart {
    &:before {
        content: $icon-heart;
    }
}
.icon-heat {
    &:before {
        content: $icon-heat;
    }
}
.icon-history {
    &:before {
        content: $icon-history;
    }
}
.icon-holding-support {
    &:before {
        content: $icon-holding-support;
    }
}
.icon-home-alt {
    &:before {
        content: $icon-home-alt;
    }
}
.icon-home-damage {
    &:before {
        content: $icon-home-damage;
    }
}
.icon-home-filled {
    &:before {
        content: $icon-home-filled;
    }
}
.icon-home {
    &:before {
        content: $icon-home;
    }
}
.icon-hospital-symbol {
    &:before {
        content: $icon-hospital-symbol;
    }
}
.icon-hourglass {
    &:before {
        content: $icon-hourglass;
    }
}
.icon-id-card-alt {
    &:before {
        content: $icon-id-card-alt;
    }
}
.icon-id-card {
    &:before {
        content: $icon-id-card;
    }
}
.icon-image-alt {
    &:before {
        content: $icon-image-alt;
    }
}
.icon-inbox-in {
    &:before {
        content: $icon-inbox-in;
    }
}
.icon-inbox-out {
    &:before {
        content: $icon-inbox-out;
    }
}
.icon-inbox {
    &:before {
        content: $icon-inbox;
    }
}
.icon-industry-alt {
    &:before {
        content: $icon-industry-alt;
    }
}
.icon-industry {
    &:before {
        content: $icon-industry;
    }
}
.icon-info-1 {
    &:before {
        content: $icon-info-1;
    }
}
.icon-info-alt {
    &:before {
        content: $icon-info-alt;
    }
}
.icon-info {
    &:before {
        content: $icon-info;
    }
}
.icon-joystick {
    &:before {
        content: $icon-joystick;
    }
}
.icon-jug {
    &:before {
        content: $icon-jug;
    }
}
.icon-key-alt {
    &:before {
        content: $icon-key-alt;
    }
}
.icon-key {
    &:before {
        content: $icon-key;
    }
}
.icon-keyboard {
    &:before {
        content: $icon-keyboard;
    }
}
.icon-keynote {
    &:before {
        content: $icon-keynote;
    }
}
.icon-knife {
    &:before {
        content: $icon-knife;
    }
}
.icon-landmark {
    &:before {
        content: $icon-landmark;
    }
}
.icon-language-alt {
    &:before {
        content: $icon-language-alt;
    }
}
.icon-language {
    &:before {
        content: $icon-language;
    }
}
.icon-laptop {
    &:before {
        content: $icon-laptop;
    }
}
.icon-layer-group {
    &:before {
        content: $icon-layer-group;
    }
}
.icon-layer-minus {
    &:before {
        content: $icon-layer-minus;
    }
}
.icon-leaf {
    &:before {
        content: $icon-leaf;
    }
}
.icon-less-than {
    &:before {
        content: $icon-less-than;
    }
}
.icon-lightbulb-exclamation {
    &:before {
        content: $icon-lightbulb-exclamation;
    }
}
.icon-lightbulb {
    &:before {
        content: $icon-lightbulb;
    }
}
.icon-line-columns {
    &:before {
        content: $icon-line-columns;
    }
}
.icon-line-height {
    &:before {
        content: $icon-line-height;
    }
}
.icon-link {
    &:before {
        content: $icon-link;
    }
}
.icon-list-music {
    &:before {
        content: $icon-list-music;
    }
}
.icon-list-ol {
    &:before {
        content: $icon-list-ol;
    }
}
.icon-list {
    &:before {
        content: $icon-list;
    }
}
.icon-location-arrow {
    &:before {
        content: $icon-location-arrow;
    }
}
.icon-location-circle {
    &:before {
        content: $icon-location-circle;
    }
}
.icon-location {
    &:before {
        content: $icon-location;
    }
}
.icon-lock-alt {
    &:before {
        content: $icon-lock-alt;
    }
}
.icon-lock-open-alt {
    &:before {
        content: $icon-lock-open-alt;
    }
}
.icon-lock-open {
    &:before {
        content: $icon-lock-open;
    }
}
.icon-lock {
    &:before {
        content: $icon-lock;
    }
}
.icon-luggage-cart {
    &:before {
        content: $icon-luggage-cart;
    }
}
.icon-magnet {
    &:before {
        content: $icon-magnet;
    }
}
.icon-mailbox {
    &:before {
        content: $icon-mailbox;
    }
}
.icon-male {
    &:before {
        content: $icon-male;
    }
}
.icon-map-marker-minus {
    &:before {
        content: $icon-map-marker-minus;
    }
}
.icon-map-marker-plus {
    &:before {
        content: $icon-map-marker-plus;
    }
}
.icon-map-marker {
    &:before {
        content: $icon-map-marker;
    }
}
.icon-map-pin {
    &:before {
        content: $icon-map-pin;
    }
}
.icon-map-signs {
    &:before {
        content: $icon-map-signs;
    }
}
.icon-map {
    &:before {
        content: $icon-map;
    }
}
.icon-medal {
    &:before {
        content: $icon-medal;
    }
}
.icon-megaphone {
    &:before {
        content: $icon-megaphone;
    }
}
.icon-microphone-alt-slash {
    &:before {
        content: $icon-microphone-alt-slash;
    }
}
.icon-microphone-alt {
    &:before {
        content: $icon-microphone-alt;
    }
}
.icon-microphone-slash {
    &:before {
        content: $icon-microphone-slash;
    }
}
.icon-microphone {
    &:before {
        content: $icon-microphone;
    }
}
.icon-microwave {
    &:before {
        content: $icon-microwave;
    }
}
.icon-minus-circle {
    &:before {
        content: $icon-minus-circle;
    }
}
.icon-minus {
    &:before {
        content: $icon-minus;
    }
}
.icon-mobile-alt {
    &:before {
        content: $icon-mobile-alt;
    }
}
.icon-mobile {
    &:before {
        content: $icon-mobile;
    }
}
.icon-money-check {
    &:before {
        content: $icon-money-check;
    }
}
.icon-money {
    &:before {
        content: $icon-money;
    }
}
.icon-moon {
    &:before {
        content: $icon-moon;
    }
}
.icon-mountain {
    &:before {
        content: $icon-mountain;
    }
}
.icon-mouse-alt {
    &:before {
        content: $icon-mouse-alt;
    }
}
.icon-mouse-pointer-alt {
    &:before {
        content: $icon-mouse-pointer-alt;
    }
}
.icon-mouse-pointer {
    &:before {
        content: $icon-mouse-pointer;
    }
}
.icon-mouse {
    &:before {
        content: $icon-mouse;
    }
}
.icon-mug {
    &:before {
        content: $icon-mug;
    }
}
.icon-music {
    &:before {
        content: $icon-music;
    }
}
.icon-neuter {
    &:before {
        content: $icon-neuter;
    }
}
.icon-newspaper {
    &:before {
        content: $icon-newspaper;
    }
}
.icon-not-equal {
    &:before {
        content: $icon-not-equal;
    }
}
.icon-notes-medical {
    &:before {
        content: $icon-notes-medical;
    }
}
.icon-oil-can {
    &:before {
        content: $icon-oil-can;
    }
}
.icon-oil-temp {
    &:before {
        content: $icon-oil-temp;
    }
}
.icon-omega {
    &:before {
        content: $icon-omega;
    }
}
.icon-outdent {
    &:before {
        content: $icon-outdent;
    }
}
.icon-outlet {
    &:before {
        content: $icon-outlet;
    }
}
.icon-overline {
    &:before {
        content: $icon-overline;
    }
}
.icon-page-break {
    &:before {
        content: $icon-page-break;
    }
}
.icon-paint-brush {
    &:before {
        content: $icon-paint-brush;
    }
}
.icon-palette {
    &:before {
        content: $icon-palette;
    }
}
.icon-paperclip {
    &:before {
        content: $icon-paperclip;
    }
}
.icon-paperplane {
    &:before {
        content: $icon-paperplane;
    }
}
.icon-paragraph {
    &:before {
        content: $icon-paragraph;
    }
}
.icon-parking {
    &:before {
        content: $icon-parking;
    }
}
.icon-passport {
    &:before {
        content: $icon-passport;
    }
}
.icon-paste {
    &:before {
        content: $icon-paste;
    }
}
.icon-pause-circle {
    &:before {
        content: $icon-pause-circle;
    }
}
.icon-pause {
    &:before {
        content: $icon-pause;
    }
}
.icon-peace {
    &:before {
        content: $icon-peace;
    }
}
.icon-pen {
    &:before {
        content: $icon-pen;
    }
}
.icon-pencil {
    &:before {
        content: $icon-pencil;
    }
}
.icon-percent {
    &:before {
        content: $icon-percent;
    }
}
.icon-phone-plus {
    &:before {
        content: $icon-phone-plus;
    }
}
.icon-phone-rotary {
    &:before {
        content: $icon-phone-rotary;
    }
}
.icon-phone-slash {
    &:before {
        content: $icon-phone-slash;
    }
}
.icon-phone-square {
    &:before {
        content: $icon-phone-square;
    }
}
.icon-phone {
    &:before {
        content: $icon-phone;
    }
}
.icon-pi {
    &:before {
        content: $icon-pi;
    }
}
.icon-pills {
    &:before {
        content: $icon-pills;
    }
}
.icon-plane-arrival {
    &:before {
        content: $icon-plane-arrival;
    }
}
.icon-plane-departure {
    &:before {
        content: $icon-plane-departure;
    }
}
.icon-plane {
    &:before {
        content: $icon-plane;
    }
}
.icon-play-alt {
    &:before {
        content: $icon-play-alt;
    }
}
.icon-play-circle {
    &:before {
        content: $icon-play-circle;
    }
}
.icon-play {
    &:before {
        content: $icon-play;
    }
}
.icon-plug {
    &:before {
        content: $icon-plug;
    }
}
.icon-plus-1 {
    &:before {
        content: $icon-plus-1;
    }
}
.icon-plus-circle {
    &:before {
        content: $icon-plus-circle;
    }
}
.icon-plus {
    &:before {
        content: $icon-plus;
    }
}
.icon-podcast {
    &:before {
        content: $icon-podcast;
    }
}
.icon-portrait {
    &:before {
        content: $icon-portrait;
    }
}
.icon-pound {
    &:before {
        content: $icon-pound;
    }
}
.icon-power-off {
    &:before {
        content: $icon-power-off;
    }
}
.icon-presentation {
    &:before {
        content: $icon-presentation;
    }
}
.icon-print-1 {
    &:before {
        content: $icon-print-1;
    }
}
.icon-print {
    &:before {
        content: $icon-print;
    }
}
.icon-puzzle-piece {
    &:before {
        content: $icon-puzzle-piece;
    }
}
.icon-qr-code {
    &:before {
        content: $icon-qr-code;
    }
}
.icon-question-circle-1 {
    &:before {
        content: $icon-question-circle-1;
    }
}
.icon-question-circle {
    &:before {
        content: $icon-question-circle;
    }
}
.icon-quote-left {
    &:before {
        content: $icon-quote-left;
    }
}
.icon-quote-right {
    &:before {
        content: $icon-quote-right;
    }
}
.icon-radiation-alt {
    &:before {
        content: $icon-radiation-alt;
    }
}
.icon-radiation {
    &:before {
        content: $icon-radiation;
    }
}
.icon-radio {
    &:before {
        content: $icon-radio;
    }
}
.icon-random {
    &:before {
        content: $icon-random;
    }
}
.icon-react {
    &:before {
        content: $icon-react;
    }
}
.icon-recycle {
    &:before {
        content: $icon-recycle;
    }
}
.icon-redo {
    &:before {
        content: $icon-redo;
    }
}
.icon-repeat {
    &:before {
        content: $icon-repeat;
    }
}
.icon-reply-all {
    &:before {
        content: $icon-reply-all;
    }
}
.icon-reply {
    &:before {
        content: $icon-reply;
    }
}
.icon-restroom {
    &:before {
        content: $icon-restroom;
    }
}
.icon-retweet {
    &:before {
        content: $icon-retweet;
    }
}
.icon-router {
    &:before {
        content: $icon-router;
    }
}
.icon-rss {
    &:before {
        content: $icon-rss;
    }
}
.icon-ruble {
    &:before {
        content: $icon-ruble;
    }
}
.icon-running {
    &:before {
        content: $icon-running;
    }
}
.icon-sack-dollar {
    &:before {
        content: $icon-sack-dollar;
    }
}
.icon-satellite-dish {
    &:before {
        content: $icon-satellite-dish;
    }
}
.icon-save-1 {
    &:before {
        content: $icon-save-1;
    }
}
.icon-save {
    &:before {
        content: $icon-save;
    }
}
.icon-scalpel {
    &:before {
        content: $icon-scalpel;
    }
}
.icon-scanner-image {
    &:before {
        content: $icon-scanner-image;
    }
}
.icon-scroll {
    &:before {
        content: $icon-scroll;
    }
}
.icon-sd-card {
    &:before {
        content: $icon-sd-card;
    }
}
.icon-search-minus {
    &:before {
        content: $icon-search-minus;
    }
}
.icon-search-plus {
    &:before {
        content: $icon-search-plus;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-sensor-alert {
    &:before {
        content: $icon-sensor-alert;
    }
}
.icon-sensor {
    &:before {
        content: $icon-sensor;
    }
}
.icon-server {
    &:before {
        content: $icon-server;
    }
}
.icon-share-1 {
    &:before {
        content: $icon-share-1;
    }
}
.icon-share-all-1 {
    &:before {
        content: $icon-share-all-1;
    }
}
.icon-share-all {
    &:before {
        content: $icon-share-all;
    }
}
.icon-share-alt-1 {
    &:before {
        content: $icon-share-alt-1;
    }
}
.icon-share-alt {
    &:before {
        content: $icon-share-alt;
    }
}
.icon-share {
    &:before {
        content: $icon-share;
    }
}
.icon-shield-alt {
    &:before {
        content: $icon-shield-alt;
    }
}
.icon-shield-check {
    &:before {
        content: $icon-shield-check;
    }
}
.icon-shield {
    &:before {
        content: $icon-shield;
    }
}
.icon-shopping-bag {
    &:before {
        content: $icon-shopping-bag;
    }
}
.icon-shopping-basket {
    &:before {
        content: $icon-shopping-basket;
    }
}
.icon-shopping-cart {
    &:before {
        content: $icon-shopping-cart;
    }
}
.icon-showel {
    &:before {
        content: $icon-showel;
    }
}
.icon-shredder {
    &:before {
        content: $icon-shredder;
    }
}
.icon-sigma {
    &:before {
        content: $icon-sigma;
    }
}
.icon-sign-in-1 {
    &:before {
        content: $icon-sign-in-1;
    }
}
.icon-sign-in {
    &:before {
        content: $icon-sign-in;
    }
}
.icon-sign-out-1 {
    &:before {
        content: $icon-sign-out-1;
    }
}
.icon-sign-out {
    &:before {
        content: $icon-sign-out;
    }
}
.icon-signal-1 {
    &:before {
        content: $icon-signal-1;
    }
}
.icon-signal-slash {
    &:before {
        content: $icon-signal-slash;
    }
}
.icon-signal-stream {
    &:before {
        content: $icon-signal-stream;
    }
}
.icon-signal {
    &:before {
        content: $icon-signal;
    }
}
.icon-sim-card {
    &:before {
        content: $icon-sim-card;
    }
}
.icon-siren {
    &:before {
        content: $icon-siren;
    }
}
.icon-sitemap-1 {
    &:before {
        content: $icon-sitemap-1;
    }
}
.icon-sitemap {
    &:before {
        content: $icon-sitemap;
    }
}
.icon-ski-jump {
    &:before {
        content: $icon-ski-jump;
    }
}
.icon-skull {
    &:before {
        content: $icon-skull;
    }
}
.icon-sliders-h {
    &:before {
        content: $icon-sliders-h;
    }
}
.icon-sliders-v {
    &:before {
        content: $icon-sliders-v;
    }
}
.icon-smile {
    &:before {
        content: $icon-smile;
    }
}
.icon-smoking {
    &:before {
        content: $icon-smoking;
    }
}
.icon-snooze {
    &:before {
        content: $icon-snooze;
    }
}
.icon-snowflake {
    &:before {
        content: $icon-snowflake;
    }
}
.icon-solarpanel {
    &:before {
        content: $icon-solarpanel;
    }
}
.icon-sort-alpha {
    &:before {
        content: $icon-sort-alpha;
    }
}
.icon-sort-alt {
    &:before {
        content: $icon-sort-alt;
    }
}
.icon-sort-amount-down {
    &:before {
        content: $icon-sort-amount-down;
    }
}
.icon-sort-amount-up {
    &:before {
        content: $icon-sort-amount-up;
    }
}
.icon-sort-aplha-up {
    &:before {
        content: $icon-sort-aplha-up;
    }
}
.icon-sort {
    &:before {
        content: $icon-sort;
    }
}
.icon-spell-check {
    &:before {
        content: $icon-spell-check;
    }
}
.icon-star-filled {
    &:before {
        content: $icon-star-filled;
    }
}
.icon-star {
    &:before {
        content: $icon-star;
    }
}
.icon-step-backwards {
    &:before {
        content: $icon-step-backwards;
    }
}
.icon-step-forwards {
    &:before {
        content: $icon-step-forwards;
    }
}
.icon-sticky-note {
    &:before {
        content: $icon-sticky-note;
    }
}
.icon-stop {
    &:before {
        content: $icon-stop;
    }
}
.icon-store {
    &:before {
        content: $icon-store;
    }
}
.icon-stream {
    &:before {
        content: $icon-stream;
    }
}
.icon-subway-1 {
    &:before {
        content: $icon-subway-1;
    }
}
.icon-subway {
    &:before {
        content: $icon-subway;
    }
}
.icon-suitcase-1 {
    &:before {
        content: $icon-suitcase-1;
    }
}
.icon-suitcase {
    &:before {
        content: $icon-suitcase;
    }
}
.icon-sun {
    &:before {
        content: $icon-sun;
    }
}
.icon-sunglasses {
    &:before {
        content: $icon-sunglasses;
    }
}
.icon-swimmer {
    &:before {
        content: $icon-swimmer;
    }
}
.icon-sync {
    &:before {
        content: $icon-sync;
    }
}
.icon-table {
    &:before {
        content: $icon-table;
    }
}
.icon-tablet-alt {
    &:before {
        content: $icon-tablet-alt;
    }
}
.icon-tablet {
    &:before {
        content: $icon-tablet;
    }
}
.icon-tachometer {
    &:before {
        content: $icon-tachometer;
    }
}
.icon-tag {
    &:before {
        content: $icon-tag;
    }
}
.icon-tags {
    &:before {
        content: $icon-tags;
    }
}
.icon-tally {
    &:before {
        content: $icon-tally;
    }
}
.icon-tasks {
    &:before {
        content: $icon-tasks;
    }
}
.icon-taxi {
    &:before {
        content: $icon-taxi;
    }
}
.icon-temperature {
    &:before {
        content: $icon-temperature;
    }
}
.icon-terminal {
    &:before {
        content: $icon-terminal;
    }
}
.icon-thermometer {
    &:before {
        content: $icon-thermometer;
    }
}
.icon-thumbs-down {
    &:before {
        content: $icon-thumbs-down;
    }
}
.icon-thumbs-up {
    &:before {
        content: $icon-thumbs-up;
    }
}
.icon-thumbtack {
    &:before {
        content: $icon-thumbtack;
    }
}
.icon-thunderstorm-1 {
    &:before {
        content: $icon-thunderstorm-1;
    }
}
.icon-thunderstorm {
    &:before {
        content: $icon-thunderstorm;
    }
}
.icon-ticket {
    &:before {
        content: $icon-ticket;
    }
}
.icon-trash-alt {
    &:before {
        content: $icon-trash-alt;
    }
}
.icon-trash-restore {
    &:before {
        content: $icon-trash-restore;
    }
}
.icon-trash {
    &:before {
        content: $icon-trash;
    }
}
.icon-truck {
    &:before {
        content: $icon-truck;
    }
}
.icon-tv {
    &:before {
        content: $icon-tv;
    }
}
.icon-typewriter {
    &:before {
        content: $icon-typewriter;
    }
}
.icon-umbrella {
    &:before {
        content: $icon-umbrella;
    }
}
.icon-undo {
    &:before {
        content: $icon-undo;
    }
}
.icon-university {
    &:before {
        content: $icon-university;
    }
}
.icon-unlink {
    &:before {
        content: $icon-unlink;
    }
}
.icon-unlock-1 {
    &:before {
        content: $icon-unlock-1;
    }
}
.icon-unlock {
    &:before {
        content: $icon-unlock;
    }
}
.icon-upload-1 {
    &:before {
        content: $icon-upload-1;
    }
}
.icon-upload-cloud {
    &:before {
        content: $icon-upload-cloud;
    }
}
.icon-upload {
    &:before {
        content: $icon-upload;
    }
}
.icon-usb-drive {
    &:before {
        content: $icon-usb-drive;
    }
}
.icon-usb {
    &:before {
        content: $icon-usb;
    }
}
.icon-utensiles {
    &:before {
        content: $icon-utensiles;
    }
}
.icon-video-slash {
    &:before {
        content: $icon-video-slash;
    }
}
.icon-video {
    &:before {
        content: $icon-video;
    }
}
.icon-voice-slash {
    &:before {
        content: $icon-voice-slash;
    }
}
.icon-voice {
    &:before {
        content: $icon-voice;
    }
}
.icon-voicemail {
    &:before {
        content: $icon-voicemail;
    }
}
.icon-volume-mute {
    &:before {
        content: $icon-volume-mute;
    }
}
.icon-walking {
    &:before {
        content: $icon-walking;
    }
}
.icon-wallet {
    &:before {
        content: $icon-wallet;
    }
}
.icon-warehouse {
    &:before {
        content: $icon-warehouse;
    }
}
.icon-waveform {
    &:before {
        content: $icon-waveform;
    }
}
.icon-webcam {
    &:before {
        content: $icon-webcam;
    }
}
.icon-weight {
    &:before {
        content: $icon-weight;
    }
}
.icon-wheelchair {
    &:before {
        content: $icon-wheelchair;
    }
}
.icon-wifi {
    &:before {
        content: $icon-wifi;
    }
}
.icon-wind {
    &:before {
        content: $icon-wind;
    }
}
.icon-window {
    &:before {
        content: $icon-window;
    }
}
.icon-wrench {
    &:before {
        content: $icon-wrench;
    }
}
.icon-yin-yang {
    &:before {
        content: $icon-yin-yang;
    }
}
