.znd-software-downloads-tabs {
    $componentSoftDownTabs: &;

    #{$componentSoftDownTabs}__wrapper {
        display: flex;
        justify-content: flex-start;
        margin: 0 auto;
    }

    #{$componentSoftDownTabs}__item {
        padding-bottom: 1.125rem;

        &::before {
            right: auto;
            left: 50%;
            width: 3.125rem;
            height: 0.25rem;
            content: '';
        }
    }

    #{$componentSoftDownTabs}__item#{$componentSoftDownTabs}__item--active {
        &::before {
            transform: translateY(-100%) translateX(-50%);
        }
    }
}
